import * as customQueries from '../customQueries';

export const init = (API) => ({
  get: async (id) => {
    const { data } = await API.graphql({
      query: customQueries.getParticipation,
      variables: {
        id,
      },
    });

    return data.getParticipation;
  },

  getByCluster: async (id, cluster) => {
    const { data } = await API.graphql({
      query: customQueries.listEventParticipations,
      variables: {
        id,
        cluster,
      },
    });

    return data.getEvent.participations.items;
  },
  getAll: async (id) => {
    const { data } = await API.graphql({
      query: customQueries.listAllEventParticipations,
      variables: {
        id,
      },
    });

    const filteredParticipations = data.getEvent.participations.items.filter(
      (p) => {
        if (p?.conflictRequest?.items?.length) {
          return (
            p.conflictRequest.items.filter(
              (cr) => cr?.participationConflict?.requestStatus === 'pending'
            ).length === 0
          );
        } else {
          return true;
        }
      }
    );

    return filteredParticipations;
  },
});
