const getGoogleMapsCountriesByInput = async (input, skipFilter) => {
  var service = new window.google.maps.places.PlacesService(
    document.getElementById('google-div')
  );
  return new Promise((resolve, reject) => {
    service.textSearch(
      { query: input, type: skipFilter ? [] : ['country'] },
      (googleCountries) => {
        resolve(
          googleCountries?.map((c) => ({
            label: c.name,
            value: c.name,
            id: c.place_id,
          }))
        );
      },
      (error) => {
        console.error(error);
        reject();
      }
    );
  });
};

const getGoogleMapsCitiesByInput = async (input, skipFilter) => {
  var service = new window.google.maps.places.PlacesService(
    document.getElementById('google-div')
  );
  return new Promise((resolve, reject) => {
    service.textSearch(
      {
        query: input,
        type: skipFilter ? [] : ['administrative_area_level_3'],
      },
      (googleCities) => {
        resolve(
          googleCities.map((c) => ({
            label: c.name,
            value: c.name,
            id: c.place_id,
          }))
        );
      },
      (error) => {
        console.error(error);
        reject();
      }
    );
  });
};

const getGoogleMapsAddressesByInput = async (input) => {
  var service = new window.google.maps.places.PlacesService(
    document.getElementById('google-div')
  );
  return new Promise((resolve, reject) => {
    service.textSearch(
      {
        query: input,
      },
      (googleAddresses) => {
        resolve(
          googleAddresses?.map((c) => ({
            label: c.formatted_address,
            value: c.formatted_address,
            placeId: c.place_id,
          }))
        );
      },
      (error) => {
        console.error(error);
        reject();
      }
    );
  });
};

const getGoogleMapsPlaceByPlaceId = (placeId) => {
  if (!placeId) return null;
  const request = {
    placeId,
  };

  var service = new window.google.maps.places.PlacesService(
    document.getElementById('google-div')
  );

  return new Promise((resolve, reject) => {
    service.getDetails(
      request,
      (res) => {
        resolve(res);
      },
      (error) => {
        console.error(error);
        reject();
      }
    );
  });
};

export default {
  getGoogleMapsCountriesByInput,
  getGoogleMapsPlaceByPlaceId,
  getGoogleMapsCitiesByInput,
  getGoogleMapsAddressesByInput,
};
