/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const participantResolver = /* GraphQL */ `
  mutation ParticipantResolver(
    $mutation: String!
    $eventId: ID!
    $input: AWSJSON
  ) {
    participantResolver(mutation: $mutation, eventId: $eventId, input: $input)
  }
`;
export const customCreateUser = /* GraphQL */ `
  mutation CustomCreateUser(
    $username: String!
    $type: String!
    $temporaryPassword: String
    $status: String
  ) {
    customCreateUser(
      username: $username
      type: $type
      temporaryPassword: $temporaryPassword
      status: $status
    )
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      civicNumber
      street
      city
      province
      region
      country
      postalCode
      createdAt
      updatedAt
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      civicNumber
      street
      city
      province
      region
      country
      postalCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      civicNumber
      street
      city
      province
      region
      country
      postalCode
      createdAt
      updatedAt
    }
  }
`;
export const createLanguage = /* GraphQL */ `
  mutation CreateLanguage(
    $input: CreateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    createLanguage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateLanguage = /* GraphQL */ `
  mutation UpdateLanguage(
    $input: UpdateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    updateLanguage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteLanguage = /* GraphQL */ `
  mutation DeleteLanguage(
    $input: DeleteLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    deleteLanguage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      givenName
      familyName
      role
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      givenName
      familyName
      role
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      givenName
      familyName
      role
      email
      createdAt
      updatedAt
    }
  }
`;
export const createAuthRead = /* GraphQL */ `
  mutation CreateAuthRead(
    $input: CreateAuthReadInput!
    $condition: ModelAuthReadConditionInput
  ) {
    createAuthRead(input: $input, condition: $condition) {
      id
      event
      participation
      abstractService
      createdAt
      updatedAt
    }
  }
`;
export const updateAuthRead = /* GraphQL */ `
  mutation UpdateAuthRead(
    $input: UpdateAuthReadInput!
    $condition: ModelAuthReadConditionInput
  ) {
    updateAuthRead(input: $input, condition: $condition) {
      id
      event
      participation
      abstractService
      createdAt
      updatedAt
    }
  }
`;
export const deleteAuthRead = /* GraphQL */ `
  mutation DeleteAuthRead(
    $input: DeleteAuthReadInput!
    $condition: ModelAuthReadConditionInput
  ) {
    deleteAuthRead(input: $input, condition: $condition) {
      id
      event
      participation
      abstractService
      createdAt
      updatedAt
    }
  }
`;
export const createAuthWrite = /* GraphQL */ `
  mutation CreateAuthWrite(
    $input: CreateAuthWriteInput!
    $condition: ModelAuthWriteConditionInput
  ) {
    createAuthWrite(input: $input, condition: $condition) {
      id
      event
      participation
      abstractService
      createdAt
      updatedAt
    }
  }
`;
export const updateAuthWrite = /* GraphQL */ `
  mutation UpdateAuthWrite(
    $input: UpdateAuthWriteInput!
    $condition: ModelAuthWriteConditionInput
  ) {
    updateAuthWrite(input: $input, condition: $condition) {
      id
      event
      participation
      abstractService
      createdAt
      updatedAt
    }
  }
`;
export const deleteAuthWrite = /* GraphQL */ `
  mutation DeleteAuthWrite(
    $input: DeleteAuthWriteInput!
    $condition: ModelAuthWriteConditionInput
  ) {
    deleteAuthWrite(input: $input, condition: $condition) {
      id
      event
      participation
      abstractService
      createdAt
      updatedAt
    }
  }
`;
export const createEventService = /* GraphQL */ `
  mutation CreateEventService(
    $input: CreateEventServiceInput!
    $condition: ModelEventServiceConditionInput
  ) {
    createEventService(input: $input, condition: $condition) {
      id
      serviceName
      createdAt
      updatedAt
      customFields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateEventService = /* GraphQL */ `
  mutation UpdateEventService(
    $input: UpdateEventServiceInput!
    $condition: ModelEventServiceConditionInput
  ) {
    updateEventService(input: $input, condition: $condition) {
      id
      serviceName
      createdAt
      updatedAt
      customFields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteEventService = /* GraphQL */ `
  mutation DeleteEventService(
    $input: DeleteEventServiceInput!
    $condition: ModelEventServiceConditionInput
  ) {
    deleteEventService(input: $input, condition: $condition) {
      id
      serviceName
      createdAt
      updatedAt
      customFields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createWebcastSpeech = /* GraphQL */ `
  mutation CreateWebcastSpeech(
    $input: CreateWebcastSpeechInput!
    $condition: ModelWebcastSpeechConditionInput
  ) {
    createWebcastSpeech(input: $input, condition: $condition) {
      id
      speakerType
      createdAt
      updatedAt
      webcast {
        id
        webcastEventId
        title
        subtitle
        description
        image
        tags
        topic
        link
        videoId
        videoProvider
        clusters
        views
        webcastSpeeches {
          nextToken
        }
        isDeleted
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const updateWebcastSpeech = /* GraphQL */ `
  mutation UpdateWebcastSpeech(
    $input: UpdateWebcastSpeechInput!
    $condition: ModelWebcastSpeechConditionInput
  ) {
    updateWebcastSpeech(input: $input, condition: $condition) {
      id
      speakerType
      createdAt
      updatedAt
      webcast {
        id
        webcastEventId
        title
        subtitle
        description
        image
        tags
        topic
        link
        videoId
        videoProvider
        clusters
        views
        webcastSpeeches {
          nextToken
        }
        isDeleted
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const deleteWebcastSpeech = /* GraphQL */ `
  mutation DeleteWebcastSpeech(
    $input: DeleteWebcastSpeechInput!
    $condition: ModelWebcastSpeechConditionInput
  ) {
    deleteWebcastSpeech(input: $input, condition: $condition) {
      id
      speakerType
      createdAt
      updatedAt
      webcast {
        id
        webcastEventId
        title
        subtitle
        description
        image
        tags
        topic
        link
        videoId
        videoProvider
        clusters
        views
        webcastSpeeches {
          nextToken
        }
        isDeleted
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const createUserSettings = /* GraphQL */ `
  mutation CreateUserSettings(
    $input: CreateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    createUserSettings(input: $input, condition: $condition) {
      id
      participationId
      isPublic
      showMyAgenda
      showBiography
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const updateUserSettings = /* GraphQL */ `
  mutation UpdateUserSettings(
    $input: UpdateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    updateUserSettings(input: $input, condition: $condition) {
      id
      participationId
      isPublic
      showMyAgenda
      showBiography
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserSettings = /* GraphQL */ `
  mutation DeleteUserSettings(
    $input: DeleteUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    deleteUserSettings(input: $input, condition: $condition) {
      id
      participationId
      isPublic
      showMyAgenda
      showBiography
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const createFavouriteAgendaSession = /* GraphQL */ `
  mutation CreateFavouriteAgendaSession(
    $input: CreateFavouriteAgendaSessionInput!
    $condition: ModelFavouriteAgendaSessionConditionInput
  ) {
    createFavouriteAgendaSession(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateFavouriteAgendaSession = /* GraphQL */ `
  mutation UpdateFavouriteAgendaSession(
    $input: UpdateFavouriteAgendaSessionInput!
    $condition: ModelFavouriteAgendaSessionConditionInput
  ) {
    updateFavouriteAgendaSession(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteFavouriteAgendaSession = /* GraphQL */ `
  mutation DeleteFavouriteAgendaSession(
    $input: DeleteFavouriteAgendaSessionInput!
    $condition: ModelFavouriteAgendaSessionConditionInput
  ) {
    deleteFavouriteAgendaSession(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createAbstractDocument = /* GraphQL */ `
  mutation CreateAbstractDocument(
    $input: CreateAbstractDocumentInput!
    $condition: ModelAbstractDocumentConditionInput
  ) {
    createAbstractDocument(input: $input, condition: $condition) {
      id
      title
      status
      cod0
      cod1
      cod2
      url
      int
      disc
      preferredExposure
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      proposedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      acceptedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      processingStatus
      tags
      keywords
      reviews {
        items {
          id
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      isReadyToReview
      affiliations {
        items {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
          createdAt
          updatedAt
        }
        nextToken
      }
      authors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      presenter {
        id
        affiliation {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        fieldValues {
          nextToken
        }
      }
      images
      isDeleted
      isOwnerAPresentingAuthor
      appearances {
        items {
          id
          name
          start
          end
          instruction
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      abstractSections {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      attachments {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationFile {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAbstractDocument = /* GraphQL */ `
  mutation UpdateAbstractDocument(
    $input: UpdateAbstractDocumentInput!
    $condition: ModelAbstractDocumentConditionInput
  ) {
    updateAbstractDocument(input: $input, condition: $condition) {
      id
      title
      status
      cod0
      cod1
      cod2
      url
      int
      disc
      preferredExposure
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      proposedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      acceptedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      processingStatus
      tags
      keywords
      reviews {
        items {
          id
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      isReadyToReview
      affiliations {
        items {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
          createdAt
          updatedAt
        }
        nextToken
      }
      authors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      presenter {
        id
        affiliation {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        fieldValues {
          nextToken
        }
      }
      images
      isDeleted
      isOwnerAPresentingAuthor
      appearances {
        items {
          id
          name
          start
          end
          instruction
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      abstractSections {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      attachments {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationFile {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAbstractDocument = /* GraphQL */ `
  mutation DeleteAbstractDocument(
    $input: DeleteAbstractDocumentInput!
    $condition: ModelAbstractDocumentConditionInput
  ) {
    deleteAbstractDocument(input: $input, condition: $condition) {
      id
      title
      status
      cod0
      cod1
      cod2
      url
      int
      disc
      preferredExposure
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      proposedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      acceptedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      processingStatus
      tags
      keywords
      reviews {
        items {
          id
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      isReadyToReview
      affiliations {
        items {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
          createdAt
          updatedAt
        }
        nextToken
      }
      authors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      presenter {
        id
        affiliation {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        fieldValues {
          nextToken
        }
      }
      images
      isDeleted
      isOwnerAPresentingAuthor
      appearances {
        items {
          id
          name
          start
          end
          instruction
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      abstractSections {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      attachments {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationFile {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAbstractAuthor = /* GraphQL */ `
  mutation CreateAbstractAuthor(
    $input: CreateAbstractAuthorInput!
    $condition: ModelAbstractAuthorConditionInput
  ) {
    createAbstractAuthor(input: $input, condition: $condition) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAbstractAuthor = /* GraphQL */ `
  mutation UpdateAbstractAuthor(
    $input: UpdateAbstractAuthorInput!
    $condition: ModelAbstractAuthorConditionInput
  ) {
    updateAbstractAuthor(input: $input, condition: $condition) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAbstractAuthor = /* GraphQL */ `
  mutation DeleteAbstractAuthor(
    $input: DeleteAbstractAuthorInput!
    $condition: ModelAbstractAuthorConditionInput
  ) {
    deleteAbstractAuthor(input: $input, condition: $condition) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAbstractPresenter = /* GraphQL */ `
  mutation CreateAbstractPresenter(
    $input: CreateAbstractPresenterInput!
    $condition: ModelAbstractPresenterConditionInput
  ) {
    createAbstractPresenter(input: $input, condition: $condition) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAbstractPresenter = /* GraphQL */ `
  mutation UpdateAbstractPresenter(
    $input: UpdateAbstractPresenterInput!
    $condition: ModelAbstractPresenterConditionInput
  ) {
    updateAbstractPresenter(input: $input, condition: $condition) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAbstractPresenter = /* GraphQL */ `
  mutation DeleteAbstractPresenter(
    $input: DeleteAbstractPresenterInput!
    $condition: ModelAbstractPresenterConditionInput
  ) {
    deleteAbstractPresenter(input: $input, condition: $condition) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAbstractAffiliation = /* GraphQL */ `
  mutation CreateAbstractAffiliation(
    $input: CreateAbstractAffiliationInput!
    $condition: ModelAbstractAffiliationConditionInput
  ) {
    createAbstractAffiliation(input: $input, condition: $condition) {
      id
      affiliationNumber
      institution
      city
      country
      fiscalCode
      createdAt
      updatedAt
    }
  }
`;
export const updateAbstractAffiliation = /* GraphQL */ `
  mutation UpdateAbstractAffiliation(
    $input: UpdateAbstractAffiliationInput!
    $condition: ModelAbstractAffiliationConditionInput
  ) {
    updateAbstractAffiliation(input: $input, condition: $condition) {
      id
      affiliationNumber
      institution
      city
      country
      fiscalCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteAbstractAffiliation = /* GraphQL */ `
  mutation DeleteAbstractAffiliation(
    $input: DeleteAbstractAffiliationInput!
    $condition: ModelAbstractAffiliationConditionInput
  ) {
    deleteAbstractAffiliation(input: $input, condition: $condition) {
      id
      affiliationNumber
      institution
      city
      country
      fiscalCode
      createdAt
      updatedAt
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      status
      scores {
        items {
          id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewer {
        id
        favoriteCategory {
          id
          name
          createdAt
          updatedAt
        }
        favoriteSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        reviews {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      abstractDocument {
        id
        title
        status
        cod0
        cod1
        cod2
        url
        int
        disc
        preferredExposure
        category {
          id
          name
          createdAt
          updatedAt
        }
        subCategory {
          id
          name
          createdAt
          updatedAt
        }
        proposedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        acceptedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        processingStatus
        tags
        keywords
        reviews {
          nextToken
        }
        isReadyToReview
        affiliations {
          nextToken
        }
        authors {
          nextToken
        }
        presenter {
          id
          createdAt
          updatedAt
        }
        images
        isDeleted
        isOwnerAPresentingAuthor
        appearances {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        abstractSections {
          nextToken
        }
        attachments {
          nextToken
        }
        presentationFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      status
      scores {
        items {
          id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewer {
        id
        favoriteCategory {
          id
          name
          createdAt
          updatedAt
        }
        favoriteSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        reviews {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      abstractDocument {
        id
        title
        status
        cod0
        cod1
        cod2
        url
        int
        disc
        preferredExposure
        category {
          id
          name
          createdAt
          updatedAt
        }
        subCategory {
          id
          name
          createdAt
          updatedAt
        }
        proposedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        acceptedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        processingStatus
        tags
        keywords
        reviews {
          nextToken
        }
        isReadyToReview
        affiliations {
          nextToken
        }
        authors {
          nextToken
        }
        presenter {
          id
          createdAt
          updatedAt
        }
        images
        isDeleted
        isOwnerAPresentingAuthor
        appearances {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        abstractSections {
          nextToken
        }
        attachments {
          nextToken
        }
        presentationFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      status
      scores {
        items {
          id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewer {
        id
        favoriteCategory {
          id
          name
          createdAt
          updatedAt
        }
        favoriteSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        reviews {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      abstractDocument {
        id
        title
        status
        cod0
        cod1
        cod2
        url
        int
        disc
        preferredExposure
        category {
          id
          name
          createdAt
          updatedAt
        }
        subCategory {
          id
          name
          createdAt
          updatedAt
        }
        proposedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        acceptedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        processingStatus
        tags
        keywords
        reviews {
          nextToken
        }
        isReadyToReview
        affiliations {
          nextToken
        }
        authors {
          nextToken
        }
        presenter {
          id
          createdAt
          updatedAt
        }
        images
        isDeleted
        isOwnerAPresentingAuthor
        appearances {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        abstractSections {
          nextToken
        }
        attachments {
          nextToken
        }
        presentationFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReviewScorePerCriteria = /* GraphQL */ `
  mutation CreateReviewScorePerCriteria(
    $input: CreateReviewScorePerCriteriaInput!
    $condition: ModelReviewScorePerCriteriaConditionInput
  ) {
    createReviewScorePerCriteria(input: $input, condition: $condition) {
      id
      criteria {
        id
        name
        scores {
          nextToken
        }
        createdAt
        updatedAt
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      score {
        id
        label
        value
        createdAt
        updatedAt
      }
      note
      createdAt
      updatedAt
    }
  }
`;
export const updateReviewScorePerCriteria = /* GraphQL */ `
  mutation UpdateReviewScorePerCriteria(
    $input: UpdateReviewScorePerCriteriaInput!
    $condition: ModelReviewScorePerCriteriaConditionInput
  ) {
    updateReviewScorePerCriteria(input: $input, condition: $condition) {
      id
      criteria {
        id
        name
        scores {
          nextToken
        }
        createdAt
        updatedAt
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      score {
        id
        label
        value
        createdAt
        updatedAt
      }
      note
      createdAt
      updatedAt
    }
  }
`;
export const deleteReviewScorePerCriteria = /* GraphQL */ `
  mutation DeleteReviewScorePerCriteria(
    $input: DeleteReviewScorePerCriteriaInput!
    $condition: ModelReviewScorePerCriteriaConditionInput
  ) {
    deleteReviewScorePerCriteria(input: $input, condition: $condition) {
      id
      criteria {
        id
        name
        scores {
          nextToken
        }
        createdAt
        updatedAt
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      score {
        id
        label
        value
        createdAt
        updatedAt
      }
      note
      createdAt
      updatedAt
    }
  }
`;
export const createReviewer = /* GraphQL */ `
  mutation CreateReviewer(
    $input: CreateReviewerInput!
    $condition: ModelReviewerConditionInput
  ) {
    createReviewer(input: $input, condition: $condition) {
      id
      favoriteCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      favoriteSubCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      reviews {
        items {
          id
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateReviewer = /* GraphQL */ `
  mutation UpdateReviewer(
    $input: UpdateReviewerInput!
    $condition: ModelReviewerConditionInput
  ) {
    updateReviewer(input: $input, condition: $condition) {
      id
      favoriteCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      favoriteSubCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      reviews {
        items {
          id
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReviewer = /* GraphQL */ `
  mutation DeleteReviewer(
    $input: DeleteReviewerInput!
    $condition: ModelReviewerConditionInput
  ) {
    deleteReviewer(input: $input, condition: $condition) {
      id
      favoriteCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      favoriteSubCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      reviews {
        items {
          id
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      subCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      subCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      subCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPresentationTypology = /* GraphQL */ `
  mutation CreatePresentationTypology(
    $input: CreatePresentationTypologyInput!
    $condition: ModelPresentationTypologyConditionInput
  ) {
    createPresentationTypology(input: $input, condition: $condition) {
      id
      name
      description
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationMode
      presentationRequired
      createdAt
      updatedAt
    }
  }
`;
export const updatePresentationTypology = /* GraphQL */ `
  mutation UpdatePresentationTypology(
    $input: UpdatePresentationTypologyInput!
    $condition: ModelPresentationTypologyConditionInput
  ) {
    updatePresentationTypology(input: $input, condition: $condition) {
      id
      name
      description
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationMode
      presentationRequired
      createdAt
      updatedAt
    }
  }
`;
export const deletePresentationTypology = /* GraphQL */ `
  mutation DeletePresentationTypology(
    $input: DeletePresentationTypologyInput!
    $condition: ModelPresentationTypologyConditionInput
  ) {
    deletePresentationTypology(input: $input, condition: $condition) {
      id
      name
      description
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationMode
      presentationRequired
      createdAt
      updatedAt
    }
  }
`;
export const createPresentationTypologyCategory = /* GraphQL */ `
  mutation CreatePresentationTypologyCategory(
    $input: CreatePresentationTypologyCategoryInput!
    $condition: ModelPresentationTypologyCategoryConditionInput
  ) {
    createPresentationTypologyCategory(input: $input, condition: $condition) {
      id
      presentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePresentationTypologyCategory = /* GraphQL */ `
  mutation UpdatePresentationTypologyCategory(
    $input: UpdatePresentationTypologyCategoryInput!
    $condition: ModelPresentationTypologyCategoryConditionInput
  ) {
    updatePresentationTypologyCategory(input: $input, condition: $condition) {
      id
      presentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePresentationTypologyCategory = /* GraphQL */ `
  mutation DeletePresentationTypologyCategory(
    $input: DeletePresentationTypologyCategoryInput!
    $condition: ModelPresentationTypologyCategoryConditionInput
  ) {
    deletePresentationTypologyCategory(input: $input, condition: $condition) {
      id
      presentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReviewCriteria = /* GraphQL */ `
  mutation CreateReviewCriteria(
    $input: CreateReviewCriteriaInput!
    $condition: ModelReviewCriteriaConditionInput
  ) {
    createReviewCriteria(input: $input, condition: $condition) {
      id
      name
      scores {
        items {
          id
          label
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateReviewCriteria = /* GraphQL */ `
  mutation UpdateReviewCriteria(
    $input: UpdateReviewCriteriaInput!
    $condition: ModelReviewCriteriaConditionInput
  ) {
    updateReviewCriteria(input: $input, condition: $condition) {
      id
      name
      scores {
        items {
          id
          label
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReviewCriteria = /* GraphQL */ `
  mutation DeleteReviewCriteria(
    $input: DeleteReviewCriteriaInput!
    $condition: ModelReviewCriteriaConditionInput
  ) {
    deleteReviewCriteria(input: $input, condition: $condition) {
      id
      name
      scores {
        items {
          id
          label
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createScore = /* GraphQL */ `
  mutation CreateScore(
    $input: CreateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    createScore(input: $input, condition: $condition) {
      id
      label
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateScore = /* GraphQL */ `
  mutation UpdateScore(
    $input: UpdateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    updateScore(input: $input, condition: $condition) {
      id
      label
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteScore = /* GraphQL */ `
  mutation DeleteScore(
    $input: DeleteScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    deleteScore(input: $input, condition: $condition) {
      id
      label
      value
      createdAt
      updatedAt
    }
  }
`;
export const createAlgorithm = /* GraphQL */ `
  mutation CreateAlgorithm(
    $input: CreateAlgorithmInput!
    $condition: ModelAlgorithmConditionInput
  ) {
    createAlgorithm(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateAlgorithm = /* GraphQL */ `
  mutation UpdateAlgorithm(
    $input: UpdateAlgorithmInput!
    $condition: ModelAlgorithmConditionInput
  ) {
    updateAlgorithm(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlgorithm = /* GraphQL */ `
  mutation DeleteAlgorithm(
    $input: DeleteAlgorithmInput!
    $condition: ModelAlgorithmConditionInput
  ) {
    deleteAlgorithm(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createSurveyInvitation = /* GraphQL */ `
  mutation CreateSurveyInvitation(
    $input: CreateSurveyInvitationInput!
    $condition: ModelSurveyInvitationConditionInput
  ) {
    createSurveyInvitation(input: $input, condition: $condition) {
      id
      isCompleted
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      survey {
        id
        title
        status
        sentAt
        model
        invitations {
          nextToken
        }
        responses {
          nextToken
        }
        surveyEventId
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateSurveyInvitation = /* GraphQL */ `
  mutation UpdateSurveyInvitation(
    $input: UpdateSurveyInvitationInput!
    $condition: ModelSurveyInvitationConditionInput
  ) {
    updateSurveyInvitation(input: $input, condition: $condition) {
      id
      isCompleted
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      survey {
        id
        title
        status
        sentAt
        model
        invitations {
          nextToken
        }
        responses {
          nextToken
        }
        surveyEventId
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteSurveyInvitation = /* GraphQL */ `
  mutation DeleteSurveyInvitation(
    $input: DeleteSurveyInvitationInput!
    $condition: ModelSurveyInvitationConditionInput
  ) {
    deleteSurveyInvitation(input: $input, condition: $condition) {
      id
      isCompleted
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      survey {
        id
        title
        status
        sentAt
        model
        invitations {
          nextToken
        }
        responses {
          nextToken
        }
        surveyEventId
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createSurveyResponse = /* GraphQL */ `
  mutation CreateSurveyResponse(
    $input: CreateSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    createSurveyResponse(input: $input, condition: $condition) {
      id
      result
      createdAt
      updatedAt
      survey {
        id
        title
        status
        sentAt
        model
        invitations {
          nextToken
        }
        responses {
          nextToken
        }
        surveyEventId
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateSurveyResponse = /* GraphQL */ `
  mutation UpdateSurveyResponse(
    $input: UpdateSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    updateSurveyResponse(input: $input, condition: $condition) {
      id
      result
      createdAt
      updatedAt
      survey {
        id
        title
        status
        sentAt
        model
        invitations {
          nextToken
        }
        responses {
          nextToken
        }
        surveyEventId
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteSurveyResponse = /* GraphQL */ `
  mutation DeleteSurveyResponse(
    $input: DeleteSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    deleteSurveyResponse(input: $input, condition: $condition) {
      id
      result
      createdAt
      updatedAt
      survey {
        id
        title
        status
        sentAt
        model
        invitations {
          nextToken
        }
        responses {
          nextToken
        }
        surveyEventId
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createDateRange = /* GraphQL */ `
  mutation CreateDateRange(
    $input: CreateDateRangeInput!
    $condition: ModelDateRangeConditionInput
  ) {
    createDateRange(input: $input, condition: $condition) {
      id
      start
      end
      brackets {
        items {
          id
          name
          start
          end
          priceOnAir
          priceOnSite
          discountCode
          discountedPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDateRange = /* GraphQL */ `
  mutation UpdateDateRange(
    $input: UpdateDateRangeInput!
    $condition: ModelDateRangeConditionInput
  ) {
    updateDateRange(input: $input, condition: $condition) {
      id
      start
      end
      brackets {
        items {
          id
          name
          start
          end
          priceOnAir
          priceOnSite
          discountCode
          discountedPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDateRange = /* GraphQL */ `
  mutation DeleteDateRange(
    $input: DeleteDateRangeInput!
    $condition: ModelDateRangeConditionInput
  ) {
    deleteDateRange(input: $input, condition: $condition) {
      id
      start
      end
      brackets {
        items {
          id
          name
          start
          end
          priceOnAir
          priceOnSite
          discountCode
          discountedPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBracket = /* GraphQL */ `
  mutation CreateBracket(
    $input: CreateBracketInput!
    $condition: ModelBracketConditionInput
  ) {
    createBracket(input: $input, condition: $condition) {
      id
      name
      start
      end
      priceOnAir
      priceOnSite
      discountCode
      discountedPrice
      createdAt
      updatedAt
    }
  }
`;
export const updateBracket = /* GraphQL */ `
  mutation UpdateBracket(
    $input: UpdateBracketInput!
    $condition: ModelBracketConditionInput
  ) {
    updateBracket(input: $input, condition: $condition) {
      id
      name
      start
      end
      priceOnAir
      priceOnSite
      discountCode
      discountedPrice
      createdAt
      updatedAt
    }
  }
`;
export const deleteBracket = /* GraphQL */ `
  mutation DeleteBracket(
    $input: DeleteBracketInput!
    $condition: ModelBracketConditionInput
  ) {
    deleteBracket(input: $input, condition: $condition) {
      id
      name
      start
      end
      priceOnAir
      priceOnSite
      discountCode
      discountedPrice
      createdAt
      updatedAt
    }
  }
`;
export const createIssue = /* GraphQL */ `
  mutation CreateIssue(
    $input: CreateIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    createIssue(input: $input, condition: $condition) {
      id
      issueName
      status
      rows {
        items {
          id
          date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      operator {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
    }
  }
`;
export const updateIssue = /* GraphQL */ `
  mutation UpdateIssue(
    $input: UpdateIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    updateIssue(input: $input, condition: $condition) {
      id
      issueName
      status
      rows {
        items {
          id
          date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      operator {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
    }
  }
`;
export const deleteIssue = /* GraphQL */ `
  mutation DeleteIssue(
    $input: DeleteIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    deleteIssue(input: $input, condition: $condition) {
      id
      issueName
      status
      rows {
        items {
          id
          date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      operator {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
    }
  }
`;
export const createIssueRow = /* GraphQL */ `
  mutation CreateIssueRow(
    $input: CreateIssueRowInput!
    $condition: ModelIssueRowConditionInput
  ) {
    createIssueRow(input: $input, condition: $condition) {
      id
      date
      description
      createdAt
      updatedAt
      operator {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
    }
  }
`;
export const updateIssueRow = /* GraphQL */ `
  mutation UpdateIssueRow(
    $input: UpdateIssueRowInput!
    $condition: ModelIssueRowConditionInput
  ) {
    updateIssueRow(input: $input, condition: $condition) {
      id
      date
      description
      createdAt
      updatedAt
      operator {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
    }
  }
`;
export const deleteIssueRow = /* GraphQL */ `
  mutation DeleteIssueRow(
    $input: DeleteIssueRowInput!
    $condition: ModelIssueRowConditionInput
  ) {
    deleteIssueRow(input: $input, condition: $condition) {
      id
      date
      description
      createdAt
      updatedAt
      operator {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
    }
  }
`;
export const createFieldContext = /* GraphQL */ `
  mutation CreateFieldContext(
    $input: CreateFieldContextInput!
    $condition: ModelFieldContextConditionInput
  ) {
    createFieldContext(input: $input, condition: $condition) {
      id
      position
      contextName
      isRequired
      isHidden
      createdAt
      updatedAt
    }
  }
`;
export const updateFieldContext = /* GraphQL */ `
  mutation UpdateFieldContext(
    $input: UpdateFieldContextInput!
    $condition: ModelFieldContextConditionInput
  ) {
    updateFieldContext(input: $input, condition: $condition) {
      id
      position
      contextName
      isRequired
      isHidden
      createdAt
      updatedAt
    }
  }
`;
export const deleteFieldContext = /* GraphQL */ `
  mutation DeleteFieldContext(
    $input: DeleteFieldContextInput!
    $condition: ModelFieldContextConditionInput
  ) {
    deleteFieldContext(input: $input, condition: $condition) {
      id
      position
      contextName
      isRequired
      isHidden
      createdAt
      updatedAt
    }
  }
`;
export const createSponsorPriceRange = /* GraphQL */ `
  mutation CreateSponsorPriceRange(
    $input: CreateSponsorPriceRangeInput!
    $condition: ModelSponsorPriceRangeConditionInput
  ) {
    createSponsorPriceRange(input: $input, condition: $condition) {
      id
      name
      description
      price
      start
      freeStaffMembers
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsors {
        items {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSponsorPriceRange = /* GraphQL */ `
  mutation UpdateSponsorPriceRange(
    $input: UpdateSponsorPriceRangeInput!
    $condition: ModelSponsorPriceRangeConditionInput
  ) {
    updateSponsorPriceRange(input: $input, condition: $condition) {
      id
      name
      description
      price
      start
      freeStaffMembers
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsors {
        items {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSponsorPriceRange = /* GraphQL */ `
  mutation DeleteSponsorPriceRange(
    $input: DeleteSponsorPriceRangeInput!
    $condition: ModelSponsorPriceRangeConditionInput
  ) {
    deleteSponsorPriceRange(input: $input, condition: $condition) {
      id
      name
      description
      price
      start
      freeStaffMembers
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsors {
        items {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBannerService = /* GraphQL */ `
  mutation CreateBannerService(
    $input: CreateBannerServiceInput!
    $condition: ModelBannerServiceConditionInput
  ) {
    createBannerService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      jsonDefinition
      bannerPages {
        items {
          id
          name
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBannerService = /* GraphQL */ `
  mutation UpdateBannerService(
    $input: UpdateBannerServiceInput!
    $condition: ModelBannerServiceConditionInput
  ) {
    updateBannerService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      jsonDefinition
      bannerPages {
        items {
          id
          name
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBannerService = /* GraphQL */ `
  mutation DeleteBannerService(
    $input: DeleteBannerServiceInput!
    $condition: ModelBannerServiceConditionInput
  ) {
    deleteBannerService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      jsonDefinition
      bannerPages {
        items {
          id
          name
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBannerPage = /* GraphQL */ `
  mutation CreateBannerPage(
    $input: CreateBannerPageInput!
    $condition: ModelBannerPageConditionInput
  ) {
    createBannerPage(input: $input, condition: $condition) {
      id
      name
      image
      bannerService {
        id
        name
        description
        vatRate
        jsonDefinition
        bannerPages {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      bannerAreas {
        items {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBannerPage = /* GraphQL */ `
  mutation UpdateBannerPage(
    $input: UpdateBannerPageInput!
    $condition: ModelBannerPageConditionInput
  ) {
    updateBannerPage(input: $input, condition: $condition) {
      id
      name
      image
      bannerService {
        id
        name
        description
        vatRate
        jsonDefinition
        bannerPages {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      bannerAreas {
        items {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBannerPage = /* GraphQL */ `
  mutation DeleteBannerPage(
    $input: DeleteBannerPageInput!
    $condition: ModelBannerPageConditionInput
  ) {
    deleteBannerPage(input: $input, condition: $condition) {
      id
      name
      image
      bannerService {
        id
        name
        description
        vatRate
        jsonDefinition
        bannerPages {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      bannerAreas {
        items {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSymposiumService = /* GraphQL */ `
  mutation CreateSymposiumService(
    $input: CreateSymposiumServiceInput!
    $condition: ModelSymposiumServiceConditionInput
  ) {
    createSymposiumService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      deadline
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      symposiums {
        items {
          id
          price
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSymposiumService = /* GraphQL */ `
  mutation UpdateSymposiumService(
    $input: UpdateSymposiumServiceInput!
    $condition: ModelSymposiumServiceConditionInput
  ) {
    updateSymposiumService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      deadline
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      symposiums {
        items {
          id
          price
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSymposiumService = /* GraphQL */ `
  mutation DeleteSymposiumService(
    $input: DeleteSymposiumServiceInput!
    $condition: ModelSymposiumServiceConditionInput
  ) {
    deleteSymposiumService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      deadline
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      symposiums {
        items {
          id
          price
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAgenda = /* GraphQL */ `
  mutation CreateAgenda(
    $input: CreateAgendaInput!
    $condition: ModelAgendaConditionInput
  ) {
    createAgenda(input: $input, condition: $condition) {
      id
      tags
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sessions {
        items {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAgenda = /* GraphQL */ `
  mutation UpdateAgenda(
    $input: UpdateAgendaInput!
    $condition: ModelAgendaConditionInput
  ) {
    updateAgenda(input: $input, condition: $condition) {
      id
      tags
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sessions {
        items {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAgenda = /* GraphQL */ `
  mutation DeleteAgenda(
    $input: DeleteAgendaInput!
    $condition: ModelAgendaConditionInput
  ) {
    deleteAgenda(input: $input, condition: $condition) {
      id
      tags
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sessions {
        items {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAgendaSessionProvocateur = /* GraphQL */ `
  mutation CreateAgendaSessionProvocateur(
    $input: CreateAgendaSessionProvocateurInput!
    $condition: ModelAgendaSessionProvocateurConditionInput
  ) {
    createAgendaSessionProvocateur(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateAgendaSessionProvocateur = /* GraphQL */ `
  mutation UpdateAgendaSessionProvocateur(
    $input: UpdateAgendaSessionProvocateurInput!
    $condition: ModelAgendaSessionProvocateurConditionInput
  ) {
    updateAgendaSessionProvocateur(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteAgendaSessionProvocateur = /* GraphQL */ `
  mutation DeleteAgendaSessionProvocateur(
    $input: DeleteAgendaSessionProvocateurInput!
    $condition: ModelAgendaSessionProvocateurConditionInput
  ) {
    deleteAgendaSessionProvocateur(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createAgendaSessionModerator = /* GraphQL */ `
  mutation CreateAgendaSessionModerator(
    $input: CreateAgendaSessionModeratorInput!
    $condition: ModelAgendaSessionModeratorConditionInput
  ) {
    createAgendaSessionModerator(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateAgendaSessionModerator = /* GraphQL */ `
  mutation UpdateAgendaSessionModerator(
    $input: UpdateAgendaSessionModeratorInput!
    $condition: ModelAgendaSessionModeratorConditionInput
  ) {
    updateAgendaSessionModerator(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteAgendaSessionModerator = /* GraphQL */ `
  mutation DeleteAgendaSessionModerator(
    $input: DeleteAgendaSessionModeratorInput!
    $condition: ModelAgendaSessionModeratorConditionInput
  ) {
    deleteAgendaSessionModerator(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createIntervention = /* GraphQL */ `
  mutation CreateIntervention(
    $input: CreateInterventionInput!
    $condition: ModelInterventionConditionInput
  ) {
    createIntervention(input: $input, condition: $condition) {
      id
      name
      start
      end
      instruction
      abstract {
        id
        title
        status
        cod0
        cod1
        cod2
        url
        int
        disc
        preferredExposure
        category {
          id
          name
          createdAt
          updatedAt
        }
        subCategory {
          id
          name
          createdAt
          updatedAt
        }
        proposedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        acceptedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        processingStatus
        tags
        keywords
        reviews {
          nextToken
        }
        isReadyToReview
        affiliations {
          nextToken
        }
        authors {
          nextToken
        }
        presenter {
          id
          createdAt
          updatedAt
        }
        images
        isDeleted
        isOwnerAPresentingAuthor
        appearances {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        abstractSections {
          nextToken
        }
        attachments {
          nextToken
        }
        presentationFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      speaker {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateIntervention = /* GraphQL */ `
  mutation UpdateIntervention(
    $input: UpdateInterventionInput!
    $condition: ModelInterventionConditionInput
  ) {
    updateIntervention(input: $input, condition: $condition) {
      id
      name
      start
      end
      instruction
      abstract {
        id
        title
        status
        cod0
        cod1
        cod2
        url
        int
        disc
        preferredExposure
        category {
          id
          name
          createdAt
          updatedAt
        }
        subCategory {
          id
          name
          createdAt
          updatedAt
        }
        proposedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        acceptedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        processingStatus
        tags
        keywords
        reviews {
          nextToken
        }
        isReadyToReview
        affiliations {
          nextToken
        }
        authors {
          nextToken
        }
        presenter {
          id
          createdAt
          updatedAt
        }
        images
        isDeleted
        isOwnerAPresentingAuthor
        appearances {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        abstractSections {
          nextToken
        }
        attachments {
          nextToken
        }
        presentationFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      speaker {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteIntervention = /* GraphQL */ `
  mutation DeleteIntervention(
    $input: DeleteInterventionInput!
    $condition: ModelInterventionConditionInput
  ) {
    deleteIntervention(input: $input, condition: $condition) {
      id
      name
      start
      end
      instruction
      abstract {
        id
        title
        status
        cod0
        cod1
        cod2
        url
        int
        disc
        preferredExposure
        category {
          id
          name
          createdAt
          updatedAt
        }
        subCategory {
          id
          name
          createdAt
          updatedAt
        }
        proposedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        acceptedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        processingStatus
        tags
        keywords
        reviews {
          nextToken
        }
        isReadyToReview
        affiliations {
          nextToken
        }
        authors {
          nextToken
        }
        presenter {
          id
          createdAt
          updatedAt
        }
        images
        isDeleted
        isOwnerAPresentingAuthor
        appearances {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        abstractSections {
          nextToken
        }
        attachments {
          nextToken
        }
        presentationFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      speaker {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      name
      type
      capacity
      delay
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      agendaSessions {
        items {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      name
      type
      capacity
      delay
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      agendaSessions {
        items {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      name
      type
      capacity
      delay
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      agendaSessions {
        items {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSponsorWelcomePage = /* GraphQL */ `
  mutation CreateSponsorWelcomePage(
    $input: CreateSponsorWelcomePageInput!
    $condition: ModelSponsorWelcomePageConditionInput
  ) {
    createSponsorWelcomePage(input: $input, condition: $condition) {
      id
      title
      subtitle
      content
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      cover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      gallery {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      document {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSponsorWelcomePage = /* GraphQL */ `
  mutation UpdateSponsorWelcomePage(
    $input: UpdateSponsorWelcomePageInput!
    $condition: ModelSponsorWelcomePageConditionInput
  ) {
    updateSponsorWelcomePage(input: $input, condition: $condition) {
      id
      title
      subtitle
      content
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      cover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      gallery {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      document {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSponsorWelcomePage = /* GraphQL */ `
  mutation DeleteSponsorWelcomePage(
    $input: DeleteSponsorWelcomePageInput!
    $condition: ModelSponsorWelcomePageConditionInput
  ) {
    deleteSponsorWelcomePage(input: $input, condition: $condition) {
      id
      title
      subtitle
      content
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      cover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      gallery {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      document {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSponsorContact = /* GraphQL */ `
  mutation CreateSponsorContact(
    $input: CreateSponsorContactInput!
    $condition: ModelSponsorContactConditionInput
  ) {
    createSponsorContact(input: $input, condition: $condition) {
      id
      name
      surname
      email
      phone
      facebook
      twitter
      linkedin
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
    }
  }
`;
export const updateSponsorContact = /* GraphQL */ `
  mutation UpdateSponsorContact(
    $input: UpdateSponsorContactInput!
    $condition: ModelSponsorContactConditionInput
  ) {
    updateSponsorContact(input: $input, condition: $condition) {
      id
      name
      surname
      email
      phone
      facebook
      twitter
      linkedin
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
    }
  }
`;
export const deleteSponsorContact = /* GraphQL */ `
  mutation DeleteSponsorContact(
    $input: DeleteSponsorContactInput!
    $condition: ModelSponsorContactConditionInput
  ) {
    deleteSponsorContact(input: $input, condition: $condition) {
      id
      name
      surname
      email
      phone
      facebook
      twitter
      linkedin
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
    }
  }
`;
export const createPublicPageService = /* GraphQL */ `
  mutation CreatePublicPageService(
    $input: CreatePublicPageServiceInput!
    $condition: ModelPublicPageServiceConditionInput
  ) {
    createPublicPageService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      netPrice
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      publicPages {
        items {
          id
          isPublic
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePublicPageService = /* GraphQL */ `
  mutation UpdatePublicPageService(
    $input: UpdatePublicPageServiceInput!
    $condition: ModelPublicPageServiceConditionInput
  ) {
    updatePublicPageService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      netPrice
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      publicPages {
        items {
          id
          isPublic
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePublicPageService = /* GraphQL */ `
  mutation DeletePublicPageService(
    $input: DeletePublicPageServiceInput!
    $condition: ModelPublicPageServiceConditionInput
  ) {
    deletePublicPageService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      netPrice
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      publicPages {
        items {
          id
          isPublic
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAgendaSessionReaction = /* GraphQL */ `
  mutation CreateAgendaSessionReaction(
    $input: CreateAgendaSessionReactionInput!
    $condition: ModelAgendaSessionReactionConditionInput
  ) {
    createAgendaSessionReaction(input: $input, condition: $condition) {
      id
      participationId
      agendaSessionId
      reaction
      timestamp
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      agendaSession {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateAgendaSessionReaction = /* GraphQL */ `
  mutation UpdateAgendaSessionReaction(
    $input: UpdateAgendaSessionReactionInput!
    $condition: ModelAgendaSessionReactionConditionInput
  ) {
    updateAgendaSessionReaction(input: $input, condition: $condition) {
      id
      participationId
      agendaSessionId
      reaction
      timestamp
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      agendaSession {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteAgendaSessionReaction = /* GraphQL */ `
  mutation DeleteAgendaSessionReaction(
    $input: DeleteAgendaSessionReactionInput!
    $condition: ModelAgendaSessionReactionConditionInput
  ) {
    deleteAgendaSessionReaction(input: $input, condition: $condition) {
      id
      participationId
      agendaSessionId
      reaction
      timestamp
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      agendaSession {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createBreakoutRoomService = /* GraphQL */ `
  mutation CreateBreakoutRoomService(
    $input: CreateBreakoutRoomServiceInput!
    $condition: ModelBreakoutRoomServiceConditionInput
  ) {
    createBreakoutRoomService(input: $input, condition: $condition) {
      id
      name
      description
      price
      vatRate
      isSponsorEditable
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      maxAvailableHours
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      breakoutRooms {
        items {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBreakoutRoomService = /* GraphQL */ `
  mutation UpdateBreakoutRoomService(
    $input: UpdateBreakoutRoomServiceInput!
    $condition: ModelBreakoutRoomServiceConditionInput
  ) {
    updateBreakoutRoomService(input: $input, condition: $condition) {
      id
      name
      description
      price
      vatRate
      isSponsorEditable
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      maxAvailableHours
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      breakoutRooms {
        items {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBreakoutRoomService = /* GraphQL */ `
  mutation DeleteBreakoutRoomService(
    $input: DeleteBreakoutRoomServiceInput!
    $condition: ModelBreakoutRoomServiceConditionInput
  ) {
    deleteBreakoutRoomService(input: $input, condition: $condition) {
      id
      name
      description
      price
      vatRate
      isSponsorEditable
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      maxAvailableHours
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      breakoutRooms {
        items {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPhysicalStandService = /* GraphQL */ `
  mutation CreatePhysicalStandService(
    $input: CreatePhysicalStandServiceInput!
    $condition: ModelPhysicalStandServiceConditionInput
  ) {
    createPhysicalStandService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      extraPriceDate
      extraPriceRate
      floors {
        items {
          id
          code
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePhysicalStandService = /* GraphQL */ `
  mutation UpdatePhysicalStandService(
    $input: UpdatePhysicalStandServiceInput!
    $condition: ModelPhysicalStandServiceConditionInput
  ) {
    updatePhysicalStandService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      extraPriceDate
      extraPriceRate
      floors {
        items {
          id
          code
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePhysicalStandService = /* GraphQL */ `
  mutation DeletePhysicalStandService(
    $input: DeletePhysicalStandServiceInput!
    $condition: ModelPhysicalStandServiceConditionInput
  ) {
    deletePhysicalStandService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      extraPriceDate
      extraPriceRate
      floors {
        items {
          id
          code
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPhysicalStandFloor = /* GraphQL */ `
  mutation CreatePhysicalStandFloor(
    $input: CreatePhysicalStandFloorInput!
    $condition: ModelPhysicalStandFloorConditionInput
  ) {
    createPhysicalStandFloor(input: $input, condition: $condition) {
      id
      code
      name
      spaces {
        items {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandService {
        id
        name
        description
        vatRate
        extraPriceDate
        extraPriceRate
        floors {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePhysicalStandFloor = /* GraphQL */ `
  mutation UpdatePhysicalStandFloor(
    $input: UpdatePhysicalStandFloorInput!
    $condition: ModelPhysicalStandFloorConditionInput
  ) {
    updatePhysicalStandFloor(input: $input, condition: $condition) {
      id
      code
      name
      spaces {
        items {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandService {
        id
        name
        description
        vatRate
        extraPriceDate
        extraPriceRate
        floors {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePhysicalStandFloor = /* GraphQL */ `
  mutation DeletePhysicalStandFloor(
    $input: DeletePhysicalStandFloorInput!
    $condition: ModelPhysicalStandFloorConditionInput
  ) {
    deletePhysicalStandFloor(input: $input, condition: $condition) {
      id
      code
      name
      spaces {
        items {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandService {
        id
        name
        description
        vatRate
        extraPriceDate
        extraPriceRate
        floors {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPhysicalStandSpace = /* GraphQL */ `
  mutation CreatePhysicalStandSpace(
    $input: CreatePhysicalStandSpaceInput!
    $condition: ModelPhysicalStandSpaceConditionInput
  ) {
    createPhysicalStandSpace(input: $input, condition: $condition) {
      id
      netPrice
      name
      code
      noVat
      extraPriceDisabled
      note
      spaceLength
      spaceHeight
      surface
      floor {
        id
        code
        name
        spaces {
          nextToken
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      physicalStandSpacesItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      plantImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePhysicalStandSpace = /* GraphQL */ `
  mutation UpdatePhysicalStandSpace(
    $input: UpdatePhysicalStandSpaceInput!
    $condition: ModelPhysicalStandSpaceConditionInput
  ) {
    updatePhysicalStandSpace(input: $input, condition: $condition) {
      id
      netPrice
      name
      code
      noVat
      extraPriceDisabled
      note
      spaceLength
      spaceHeight
      surface
      floor {
        id
        code
        name
        spaces {
          nextToken
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      physicalStandSpacesItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      plantImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePhysicalStandSpace = /* GraphQL */ `
  mutation DeletePhysicalStandSpace(
    $input: DeletePhysicalStandSpaceInput!
    $condition: ModelPhysicalStandSpaceConditionInput
  ) {
    deletePhysicalStandSpace(input: $input, condition: $condition) {
      id
      netPrice
      name
      code
      noVat
      extraPriceDisabled
      note
      spaceLength
      spaceHeight
      surface
      floor {
        id
        code
        name
        spaces {
          nextToken
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      physicalStandSpacesItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      plantImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createOtherStandServicesService = /* GraphQL */ `
  mutation CreateOtherStandServicesService(
    $input: CreateOtherStandServicesServiceInput!
    $condition: ModelOtherStandServicesServiceConditionInput
  ) {
    createOtherStandServicesService(input: $input, condition: $condition) {
      id
      name
      description
      itemCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOtherStandServicesService = /* GraphQL */ `
  mutation UpdateOtherStandServicesService(
    $input: UpdateOtherStandServicesServiceInput!
    $condition: ModelOtherStandServicesServiceConditionInput
  ) {
    updateOtherStandServicesService(input: $input, condition: $condition) {
      id
      name
      description
      itemCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOtherStandServicesService = /* GraphQL */ `
  mutation DeleteOtherStandServicesService(
    $input: DeleteOtherStandServicesServiceInput!
    $condition: ModelOtherStandServicesServiceConditionInput
  ) {
    deleteOtherStandServicesService(input: $input, condition: $condition) {
      id
      name
      description
      itemCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createItemCategory = /* GraphQL */ `
  mutation CreateItemCategory(
    $input: CreateItemCategoryInput!
    $condition: ModelItemCategoryConditionInput
  ) {
    createItemCategory(input: $input, condition: $condition) {
      id
      name
      otherStandServicesService {
        id
        name
        description
        itemCategories {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      itemSubcategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateItemCategory = /* GraphQL */ `
  mutation UpdateItemCategory(
    $input: UpdateItemCategoryInput!
    $condition: ModelItemCategoryConditionInput
  ) {
    updateItemCategory(input: $input, condition: $condition) {
      id
      name
      otherStandServicesService {
        id
        name
        description
        itemCategories {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      itemSubcategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteItemCategory = /* GraphQL */ `
  mutation DeleteItemCategory(
    $input: DeleteItemCategoryInput!
    $condition: ModelItemCategoryConditionInput
  ) {
    deleteItemCategory(input: $input, condition: $condition) {
      id
      name
      otherStandServicesService {
        id
        name
        description
        itemCategories {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      itemSubcategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createItemSubcategory = /* GraphQL */ `
  mutation CreateItemSubcategory(
    $input: CreateItemSubcategoryInput!
    $condition: ModelItemSubcategoryConditionInput
  ) {
    createItemSubcategory(input: $input, condition: $condition) {
      id
      name
      itemCategory {
        id
        name
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        itemSubcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      childItems {
        items {
          id
          name
          code
          netPrice
          vatRate
          type
          amountPerQuantityLabel
          note
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateItemSubcategory = /* GraphQL */ `
  mutation UpdateItemSubcategory(
    $input: UpdateItemSubcategoryInput!
    $condition: ModelItemSubcategoryConditionInput
  ) {
    updateItemSubcategory(input: $input, condition: $condition) {
      id
      name
      itemCategory {
        id
        name
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        itemSubcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      childItems {
        items {
          id
          name
          code
          netPrice
          vatRate
          type
          amountPerQuantityLabel
          note
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteItemSubcategory = /* GraphQL */ `
  mutation DeleteItemSubcategory(
    $input: DeleteItemSubcategoryInput!
    $condition: ModelItemSubcategoryConditionInput
  ) {
    deleteItemSubcategory(input: $input, condition: $condition) {
      id
      name
      itemCategory {
        id
        name
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        itemSubcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      childItems {
        items {
          id
          name
          code
          netPrice
          vatRate
          type
          amountPerQuantityLabel
          note
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      name
      code
      netPrice
      vatRate
      type
      amountPerQuantityLabel
      note
      description
      itemSubCategory {
        id
        name
        itemCategory {
          id
          name
          createdAt
          updatedAt
        }
        childItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      physicalStandSpaceItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      name
      code
      netPrice
      vatRate
      type
      amountPerQuantityLabel
      note
      description
      itemSubCategory {
        id
        name
        itemCategory {
          id
          name
          createdAt
          updatedAt
        }
        childItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      physicalStandSpaceItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      name
      code
      netPrice
      vatRate
      type
      amountPerQuantityLabel
      note
      description
      itemSubCategory {
        id
        name
        itemCategory {
          id
          name
          createdAt
          updatedAt
        }
        childItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      physicalStandSpaceItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPhysicalStandSpaceItems = /* GraphQL */ `
  mutation CreatePhysicalStandSpaceItems(
    $input: CreatePhysicalStandSpaceItemsInput!
    $condition: ModelPhysicalStandSpaceItemsConditionInput
  ) {
    createPhysicalStandSpaceItems(input: $input, condition: $condition) {
      id
      quantity
      amountPerQuantity
      item {
        id
        name
        code
        netPrice
        vatRate
        type
        amountPerQuantityLabel
        note
        description
        itemSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      physicalStandSpace {
        id
        netPrice
        name
        code
        noVat
        extraPriceDisabled
        note
        spaceLength
        spaceHeight
        surface
        floor {
          id
          code
          name
          createdAt
          updatedAt
        }
        physicalStandSpacesItems {
          nextToken
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        plantImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePhysicalStandSpaceItems = /* GraphQL */ `
  mutation UpdatePhysicalStandSpaceItems(
    $input: UpdatePhysicalStandSpaceItemsInput!
    $condition: ModelPhysicalStandSpaceItemsConditionInput
  ) {
    updatePhysicalStandSpaceItems(input: $input, condition: $condition) {
      id
      quantity
      amountPerQuantity
      item {
        id
        name
        code
        netPrice
        vatRate
        type
        amountPerQuantityLabel
        note
        description
        itemSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      physicalStandSpace {
        id
        netPrice
        name
        code
        noVat
        extraPriceDisabled
        note
        spaceLength
        spaceHeight
        surface
        floor {
          id
          code
          name
          createdAt
          updatedAt
        }
        physicalStandSpacesItems {
          nextToken
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        plantImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePhysicalStandSpaceItems = /* GraphQL */ `
  mutation DeletePhysicalStandSpaceItems(
    $input: DeletePhysicalStandSpaceItemsInput!
    $condition: ModelPhysicalStandSpaceItemsConditionInput
  ) {
    deletePhysicalStandSpaceItems(input: $input, condition: $condition) {
      id
      quantity
      amountPerQuantity
      item {
        id
        name
        code
        netPrice
        vatRate
        type
        amountPerQuantityLabel
        note
        description
        itemSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      physicalStandSpace {
        id
        netPrice
        name
        code
        noVat
        extraPriceDisabled
        note
        spaceLength
        spaceHeight
        surface
        floor {
          id
          code
          name
          createdAt
          updatedAt
        }
        physicalStandSpacesItems {
          nextToken
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        plantImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createVirtualStandService = /* GraphQL */ `
  mutation CreateVirtualStandService(
    $input: CreateVirtualStandServiceInput!
    $condition: ModelVirtualStandServiceConditionInput
  ) {
    createVirtualStandService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      virtualStands {
        items {
          id
          name
          isEnabled
          ranking
          netPrice
          specificationPost
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateVirtualStandService = /* GraphQL */ `
  mutation UpdateVirtualStandService(
    $input: UpdateVirtualStandServiceInput!
    $condition: ModelVirtualStandServiceConditionInput
  ) {
    updateVirtualStandService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      virtualStands {
        items {
          id
          name
          isEnabled
          ranking
          netPrice
          specificationPost
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteVirtualStandService = /* GraphQL */ `
  mutation DeleteVirtualStandService(
    $input: DeleteVirtualStandServiceInput!
    $condition: ModelVirtualStandServiceConditionInput
  ) {
    deleteVirtualStandService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      virtualStands {
        items {
          id
          name
          isEnabled
          ranking
          netPrice
          specificationPost
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createVirtualStand = /* GraphQL */ `
  mutation CreateVirtualStand(
    $input: CreateVirtualStandInput!
    $condition: ModelVirtualStandConditionInput
  ) {
    createVirtualStand(input: $input, condition: $condition) {
      id
      name
      virtualStandService {
        id
        name
        description
        vatRate
        virtualStands {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      isEnabled
      sponsorVirtualStand {
        items {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        nextToken
      }
      ranking
      netPrice
      specificationPost
      createdAt
      updatedAt
    }
  }
`;
export const updateVirtualStand = /* GraphQL */ `
  mutation UpdateVirtualStand(
    $input: UpdateVirtualStandInput!
    $condition: ModelVirtualStandConditionInput
  ) {
    updateVirtualStand(input: $input, condition: $condition) {
      id
      name
      virtualStandService {
        id
        name
        description
        vatRate
        virtualStands {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      isEnabled
      sponsorVirtualStand {
        items {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        nextToken
      }
      ranking
      netPrice
      specificationPost
      createdAt
      updatedAt
    }
  }
`;
export const deleteVirtualStand = /* GraphQL */ `
  mutation DeleteVirtualStand(
    $input: DeleteVirtualStandInput!
    $condition: ModelVirtualStandConditionInput
  ) {
    deleteVirtualStand(input: $input, condition: $condition) {
      id
      name
      virtualStandService {
        id
        name
        description
        vatRate
        virtualStands {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      isEnabled
      sponsorVirtualStand {
        items {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        nextToken
      }
      ranking
      netPrice
      specificationPost
      createdAt
      updatedAt
    }
  }
`;
export const createSponsorVirtualStand = /* GraphQL */ `
  mutation CreateSponsorVirtualStand(
    $input: CreateSponsorVirtualStandInput!
    $condition: ModelSponsorVirtualStandConditionInput
  ) {
    createSponsorVirtualStand(input: $input, condition: $condition) {
      id
      virtualRoomLink
      virtualStand {
        id
        name
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        isEnabled
        sponsorVirtualStand {
          nextToken
        }
        ranking
        netPrice
        specificationPost
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateSponsorVirtualStand = /* GraphQL */ `
  mutation UpdateSponsorVirtualStand(
    $input: UpdateSponsorVirtualStandInput!
    $condition: ModelSponsorVirtualStandConditionInput
  ) {
    updateSponsorVirtualStand(input: $input, condition: $condition) {
      id
      virtualRoomLink
      virtualStand {
        id
        name
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        isEnabled
        sponsorVirtualStand {
          nextToken
        }
        ranking
        netPrice
        specificationPost
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteSponsorVirtualStand = /* GraphQL */ `
  mutation DeleteSponsorVirtualStand(
    $input: DeleteSponsorVirtualStandInput!
    $condition: ModelSponsorVirtualStandConditionInput
  ) {
    deleteSponsorVirtualStand(input: $input, condition: $condition) {
      id
      virtualRoomLink
      virtualStand {
        id
        name
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        isEnabled
        sponsorVirtualStand {
          nextToken
        }
        ranking
        netPrice
        specificationPost
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createSponsorStaffService = /* GraphQL */ `
  mutation CreateSponsorStaffService(
    $input: CreateSponsorStaffServiceInput!
    $condition: ModelSponsorStaffServiceConditionInput
  ) {
    createSponsorStaffService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsorStaffs {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSponsorStaffService = /* GraphQL */ `
  mutation UpdateSponsorStaffService(
    $input: UpdateSponsorStaffServiceInput!
    $condition: ModelSponsorStaffServiceConditionInput
  ) {
    updateSponsorStaffService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsorStaffs {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSponsorStaffService = /* GraphQL */ `
  mutation DeleteSponsorStaffService(
    $input: DeleteSponsorStaffServiceInput!
    $condition: ModelSponsorStaffServiceConditionInput
  ) {
    deleteSponsorStaffService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsorStaffs {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSponsorListService = /* GraphQL */ `
  mutation CreateSponsorListService(
    $input: CreateSponsorListServiceInput!
    $condition: ModelSponsorListServiceConditionInput
  ) {
    createSponsorListService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsorLists {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSponsorListService = /* GraphQL */ `
  mutation UpdateSponsorListService(
    $input: UpdateSponsorListServiceInput!
    $condition: ModelSponsorListServiceConditionInput
  ) {
    updateSponsorListService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsorLists {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSponsorListService = /* GraphQL */ `
  mutation DeleteSponsorListService(
    $input: DeleteSponsorListServiceInput!
    $condition: ModelSponsorListServiceConditionInput
  ) {
    deleteSponsorListService(input: $input, condition: $condition) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsorLists {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createProfileCategory = /* GraphQL */ `
  mutation CreateProfileCategory(
    $input: CreateProfileCategoryInput!
    $condition: ModelProfileCategoryConditionInput
  ) {
    createProfileCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      profiles {
        items {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateProfileCategory = /* GraphQL */ `
  mutation UpdateProfileCategory(
    $input: UpdateProfileCategoryInput!
    $condition: ModelProfileCategoryConditionInput
  ) {
    updateProfileCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      profiles {
        items {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteProfileCategory = /* GraphQL */ `
  mutation DeleteProfileCategory(
    $input: DeleteProfileCategoryInput!
    $condition: ModelProfileCategoryConditionInput
  ) {
    deleteProfileCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      profiles {
        items {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createFee = /* GraphQL */ `
  mutation CreateFee(
    $input: CreateFeeInput!
    $condition: ModelFeeConditionInput
  ) {
    createFee(input: $input, condition: $condition) {
      id
      feeEventId
      vatRate
      currency
      isPublished
      feeBracketModels {
        items {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      feeDateRanges {
        items {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateFee = /* GraphQL */ `
  mutation UpdateFee(
    $input: UpdateFeeInput!
    $condition: ModelFeeConditionInput
  ) {
    updateFee(input: $input, condition: $condition) {
      id
      feeEventId
      vatRate
      currency
      isPublished
      feeBracketModels {
        items {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      feeDateRanges {
        items {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteFee = /* GraphQL */ `
  mutation DeleteFee(
    $input: DeleteFeeInput!
    $condition: ModelFeeConditionInput
  ) {
    deleteFee(input: $input, condition: $condition) {
      id
      feeEventId
      vatRate
      currency
      isPublished
      feeBracketModels {
        items {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      feeDateRanges {
        items {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createFeeBracket = /* GraphQL */ `
  mutation CreateFeeBracket(
    $input: CreateFeeBracketInput!
    $condition: ModelFeeBracketConditionInput
  ) {
    createFeeBracket(input: $input, condition: $condition) {
      id
      feeBracketModel {
        id
        start
        end
        name
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBracket {
          nextToken
        }
        createdAt
        updatedAt
      }
      profileFeeBrackets {
        items {
          id
          priceOnAir
          priceOnSite
          discountPrice
          discountCode
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFeeBracket = /* GraphQL */ `
  mutation UpdateFeeBracket(
    $input: UpdateFeeBracketInput!
    $condition: ModelFeeBracketConditionInput
  ) {
    updateFeeBracket(input: $input, condition: $condition) {
      id
      feeBracketModel {
        id
        start
        end
        name
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBracket {
          nextToken
        }
        createdAt
        updatedAt
      }
      profileFeeBrackets {
        items {
          id
          priceOnAir
          priceOnSite
          discountPrice
          discountCode
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFeeBracket = /* GraphQL */ `
  mutation DeleteFeeBracket(
    $input: DeleteFeeBracketInput!
    $condition: ModelFeeBracketConditionInput
  ) {
    deleteFeeBracket(input: $input, condition: $condition) {
      id
      feeBracketModel {
        id
        start
        end
        name
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBracket {
          nextToken
        }
        createdAt
        updatedAt
      }
      profileFeeBrackets {
        items {
          id
          priceOnAir
          priceOnSite
          discountPrice
          discountCode
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFeeBracketModel = /* GraphQL */ `
  mutation CreateFeeBracketModel(
    $input: CreateFeeBracketModelInput!
    $condition: ModelFeeBracketModelConditionInput
  ) {
    createFeeBracketModel(input: $input, condition: $condition) {
      id
      start
      end
      name
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      feeBracket {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFeeBracketModel = /* GraphQL */ `
  mutation UpdateFeeBracketModel(
    $input: UpdateFeeBracketModelInput!
    $condition: ModelFeeBracketModelConditionInput
  ) {
    updateFeeBracketModel(input: $input, condition: $condition) {
      id
      start
      end
      name
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      feeBracket {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeeBracketModel = /* GraphQL */ `
  mutation DeleteFeeBracketModel(
    $input: DeleteFeeBracketModelInput!
    $condition: ModelFeeBracketModelConditionInput
  ) {
    deleteFeeBracketModel(input: $input, condition: $condition) {
      id
      start
      end
      name
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      feeBracket {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProfileFeeBracket = /* GraphQL */ `
  mutation CreateProfileFeeBracket(
    $input: CreateProfileFeeBracketInput!
    $condition: ModelProfileFeeBracketConditionInput
  ) {
    createProfileFeeBracket(input: $input, condition: $condition) {
      id
      priceOnAir
      priceOnSite
      discountPrice
      discountCode
      isActive
      feeBracket {
        id
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        createdAt
        updatedAt
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
    }
  }
`;
export const updateProfileFeeBracket = /* GraphQL */ `
  mutation UpdateProfileFeeBracket(
    $input: UpdateProfileFeeBracketInput!
    $condition: ModelProfileFeeBracketConditionInput
  ) {
    updateProfileFeeBracket(input: $input, condition: $condition) {
      id
      priceOnAir
      priceOnSite
      discountPrice
      discountCode
      isActive
      feeBracket {
        id
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        createdAt
        updatedAt
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
    }
  }
`;
export const deleteProfileFeeBracket = /* GraphQL */ `
  mutation DeleteProfileFeeBracket(
    $input: DeleteProfileFeeBracketInput!
    $condition: ModelProfileFeeBracketConditionInput
  ) {
    deleteProfileFeeBracket(input: $input, condition: $condition) {
      id
      priceOnAir
      priceOnSite
      discountPrice
      discountCode
      isActive
      feeBracket {
        id
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        createdAt
        updatedAt
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
    }
  }
`;
export const createDeadline = /* GraphQL */ `
  mutation CreateDeadline(
    $input: CreateDeadlineInput!
    $condition: ModelDeadlineConditionInput
  ) {
    createDeadline(input: $input, condition: $condition) {
      type
      externalId
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateDeadline = /* GraphQL */ `
  mutation UpdateDeadline(
    $input: UpdateDeadlineInput!
    $condition: ModelDeadlineConditionInput
  ) {
    updateDeadline(input: $input, condition: $condition) {
      type
      externalId
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeadline = /* GraphQL */ `
  mutation DeleteDeadline(
    $input: DeleteDeadlineInput!
    $condition: ModelDeadlineConditionInput
  ) {
    deleteDeadline(input: $input, condition: $condition) {
      type
      externalId
      value
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      operators
      createdBy
      isOwnedByCongress
      name
      code
      organizer
      start
      end
      isOnAir
      venue
      country
      city
      initials
      invoicingInitials
      order
      division {
        id
        name
        createdAt
        updatedAt
      }
      industry {
        id
        name
        createdAt
        updatedAt
      }
      topic {
        id
        name
        createdAt
        updatedAt
      }
      accountingTypology {
        id
        name
        createdAt
        updatedAt
      }
      language {
        id
        name
        createdAt
        updatedAt
      }
      contacts {
        items {
          id
          givenName
          familyName
          role
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      services {
        items {
          id
          serviceName
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorWelcomePage {
        id
        title
        subtitle
        content
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        cover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        gallery {
          nextToken
        }
        document {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      bannerService {
        id
        name
        description
        vatRate
        jsonDefinition
        bannerPages {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      symposiumService {
        id
        name
        description
        vatRate
        deadline
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        symposiums {
          nextToken
        }
        documents {
          nextToken
        }
      }
      physicalStandService {
        id
        name
        description
        vatRate
        extraPriceDate
        extraPriceRate
        floors {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      publicPageService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        netPrice
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        publicPages {
          nextToken
        }
      }
      breakoutRoomService {
        id
        name
        description
        price
        vatRate
        isSponsorEditable
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        maxAvailableHours
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        breakoutRooms {
          nextToken
        }
      }
      otherStandServicesService {
        id
        name
        description
        itemCategories {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      virtualStandService {
        id
        name
        description
        vatRate
        virtualStands {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      sponsorStaffService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorStaffs {
          nextToken
        }
        documents {
          nextToken
        }
      }
      sponsorListService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorLists {
          nextToken
        }
        documents {
          nextToken
        }
      }
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      sponsorPriceRanges {
        items {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        nextToken
      }
      rooms {
        items {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        nextToken
      }
      agenda {
        id
        tags
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      sponsorServiceBookingDuration
      IBAN
      profileCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      mailUpListId
      createdAt
      updatedAt
      delegations {
        items {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      webcasts {
        items {
          id
          webcastEventId
          title
          subtitle
          description
          image
          tags
          topic
          link
          videoId
          videoProvider
          clusters
          views
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      surveys {
        items {
          id
          title
          status
          sentAt
          model
          surveyEventId
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          quoteEventId
          profileName
          cluster
          currency
          createdAt
          updatedAt
        }
        nextToken
      }
      standardFields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      fields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorLists {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorStaffs {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsors {
        items {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        nextToken
      }
      agencies {
        items {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      agencyService {
        id
        minParticipations
        instructions
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        cover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        gallery {
          nextToken
        }
        policies {
          nextToken
        }
      }
      privacyFile {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      profiles {
        items {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
      participationTypologies {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      operators
      createdBy
      isOwnedByCongress
      name
      code
      organizer
      start
      end
      isOnAir
      venue
      country
      city
      initials
      invoicingInitials
      order
      division {
        id
        name
        createdAt
        updatedAt
      }
      industry {
        id
        name
        createdAt
        updatedAt
      }
      topic {
        id
        name
        createdAt
        updatedAt
      }
      accountingTypology {
        id
        name
        createdAt
        updatedAt
      }
      language {
        id
        name
        createdAt
        updatedAt
      }
      contacts {
        items {
          id
          givenName
          familyName
          role
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      services {
        items {
          id
          serviceName
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorWelcomePage {
        id
        title
        subtitle
        content
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        cover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        gallery {
          nextToken
        }
        document {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      bannerService {
        id
        name
        description
        vatRate
        jsonDefinition
        bannerPages {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      symposiumService {
        id
        name
        description
        vatRate
        deadline
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        symposiums {
          nextToken
        }
        documents {
          nextToken
        }
      }
      physicalStandService {
        id
        name
        description
        vatRate
        extraPriceDate
        extraPriceRate
        floors {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      publicPageService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        netPrice
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        publicPages {
          nextToken
        }
      }
      breakoutRoomService {
        id
        name
        description
        price
        vatRate
        isSponsorEditable
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        maxAvailableHours
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        breakoutRooms {
          nextToken
        }
      }
      otherStandServicesService {
        id
        name
        description
        itemCategories {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      virtualStandService {
        id
        name
        description
        vatRate
        virtualStands {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      sponsorStaffService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorStaffs {
          nextToken
        }
        documents {
          nextToken
        }
      }
      sponsorListService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorLists {
          nextToken
        }
        documents {
          nextToken
        }
      }
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      sponsorPriceRanges {
        items {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        nextToken
      }
      rooms {
        items {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        nextToken
      }
      agenda {
        id
        tags
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      sponsorServiceBookingDuration
      IBAN
      profileCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      mailUpListId
      createdAt
      updatedAt
      delegations {
        items {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      webcasts {
        items {
          id
          webcastEventId
          title
          subtitle
          description
          image
          tags
          topic
          link
          videoId
          videoProvider
          clusters
          views
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      surveys {
        items {
          id
          title
          status
          sentAt
          model
          surveyEventId
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          quoteEventId
          profileName
          cluster
          currency
          createdAt
          updatedAt
        }
        nextToken
      }
      standardFields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      fields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorLists {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorStaffs {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsors {
        items {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        nextToken
      }
      agencies {
        items {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      agencyService {
        id
        minParticipations
        instructions
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        cover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        gallery {
          nextToken
        }
        policies {
          nextToken
        }
      }
      privacyFile {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      profiles {
        items {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
      participationTypologies {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      operators
      createdBy
      isOwnedByCongress
      name
      code
      organizer
      start
      end
      isOnAir
      venue
      country
      city
      initials
      invoicingInitials
      order
      division {
        id
        name
        createdAt
        updatedAt
      }
      industry {
        id
        name
        createdAt
        updatedAt
      }
      topic {
        id
        name
        createdAt
        updatedAt
      }
      accountingTypology {
        id
        name
        createdAt
        updatedAt
      }
      language {
        id
        name
        createdAt
        updatedAt
      }
      contacts {
        items {
          id
          givenName
          familyName
          role
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      services {
        items {
          id
          serviceName
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorWelcomePage {
        id
        title
        subtitle
        content
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        cover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        gallery {
          nextToken
        }
        document {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      bannerService {
        id
        name
        description
        vatRate
        jsonDefinition
        bannerPages {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      symposiumService {
        id
        name
        description
        vatRate
        deadline
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        symposiums {
          nextToken
        }
        documents {
          nextToken
        }
      }
      physicalStandService {
        id
        name
        description
        vatRate
        extraPriceDate
        extraPriceRate
        floors {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      publicPageService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        netPrice
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        publicPages {
          nextToken
        }
      }
      breakoutRoomService {
        id
        name
        description
        price
        vatRate
        isSponsorEditable
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        maxAvailableHours
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        breakoutRooms {
          nextToken
        }
      }
      otherStandServicesService {
        id
        name
        description
        itemCategories {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      virtualStandService {
        id
        name
        description
        vatRate
        virtualStands {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      sponsorStaffService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorStaffs {
          nextToken
        }
        documents {
          nextToken
        }
      }
      sponsorListService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorLists {
          nextToken
        }
        documents {
          nextToken
        }
      }
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      sponsorPriceRanges {
        items {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        nextToken
      }
      rooms {
        items {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        nextToken
      }
      agenda {
        id
        tags
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      sponsorServiceBookingDuration
      IBAN
      profileCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      mailUpListId
      createdAt
      updatedAt
      delegations {
        items {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      webcasts {
        items {
          id
          webcastEventId
          title
          subtitle
          description
          image
          tags
          topic
          link
          videoId
          videoProvider
          clusters
          views
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      surveys {
        items {
          id
          title
          status
          sentAt
          model
          surveyEventId
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          quoteEventId
          profileName
          cluster
          currency
          createdAt
          updatedAt
        }
        nextToken
      }
      standardFields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      fields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorLists {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorStaffs {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsors {
        items {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        nextToken
      }
      agencies {
        items {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      agencyService {
        id
        minParticipations
        instructions
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        cover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        gallery {
          nextToken
        }
        policies {
          nextToken
        }
      }
      privacyFile {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      profiles {
        items {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
      participationTypologies {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createDelegation = /* GraphQL */ `
  mutation CreateDelegation(
    $input: CreateDelegationInput!
    $condition: ModelDelegationConditionInput
  ) {
    createDelegation(input: $input, condition: $condition) {
      id
      name
      customer
      description
      closingDate
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      modificationRequests {
        items {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateDelegation = /* GraphQL */ `
  mutation UpdateDelegation(
    $input: UpdateDelegationInput!
    $condition: ModelDelegationConditionInput
  ) {
    updateDelegation(input: $input, condition: $condition) {
      id
      name
      customer
      description
      closingDate
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      modificationRequests {
        items {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteDelegation = /* GraphQL */ `
  mutation DeleteDelegation(
    $input: DeleteDelegationInput!
    $condition: ModelDelegationConditionInput
  ) {
    deleteDelegation(input: $input, condition: $condition) {
      id
      name
      customer
      description
      closingDate
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      modificationRequests {
        items {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createWebcast = /* GraphQL */ `
  mutation CreateWebcast(
    $input: CreateWebcastInput!
    $condition: ModelWebcastConditionInput
  ) {
    createWebcast(input: $input, condition: $condition) {
      id
      webcastEventId
      title
      subtitle
      description
      image
      tags
      topic
      link
      videoId
      videoProvider
      clusters
      views
      webcastSpeeches {
        items {
          id
          speakerType
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const updateWebcast = /* GraphQL */ `
  mutation UpdateWebcast(
    $input: UpdateWebcastInput!
    $condition: ModelWebcastConditionInput
  ) {
    updateWebcast(input: $input, condition: $condition) {
      id
      webcastEventId
      title
      subtitle
      description
      image
      tags
      topic
      link
      videoId
      videoProvider
      clusters
      views
      webcastSpeeches {
        items {
          id
          speakerType
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const deleteWebcast = /* GraphQL */ `
  mutation DeleteWebcast(
    $input: DeleteWebcastInput!
    $condition: ModelWebcastConditionInput
  ) {
    deleteWebcast(input: $input, condition: $condition) {
      id
      webcastEventId
      title
      subtitle
      description
      image
      tags
      topic
      link
      videoId
      videoProvider
      clusters
      views
      webcastSpeeches {
        items {
          id
          speakerType
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const createParticipation = /* GraphQL */ `
  mutation CreateParticipation(
    $input: CreateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    createParticipation(input: $input, condition: $condition) {
      id
      cognitoUserId
      isDeleted
      email
      givenName
      familyName
      businessName
      owner
      cluster
      isParticipant
      isSpeaker
      isReviewer
      isScientificCommittee
      paymentStatus
      registrationChannel
      isInvited
      status
      feeBracketModel {
        id
        start
        end
        name
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBracket {
          nextToken
        }
        createdAt
        updatedAt
      }
      participationEventId
      reviewer {
        id
        favoriteCategory {
          id
          name
          createdAt
          updatedAt
        }
        favoriteSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        reviews {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      abstractDocuments {
        items {
          id
          title
          status
          cod0
          cod1
          cod2
          url
          int
          disc
          preferredExposure
          processingStatus
          tags
          keywords
          isReadyToReview
          images
          isDeleted
          isOwnerAPresentingAuthor
          createdAt
          updatedAt
        }
        nextToken
      }
      webcastSpeeches {
        items {
          id
          speakerType
          createdAt
          updatedAt
        }
        nextToken
      }
      surveyInvitations {
        items {
          id
          isCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      issues {
        items {
          id
          issueName
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      registrationFee
      myAgenda {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      provocateurRole {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      moderatorRole {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      userSettings {
        id
        participationId
        isPublic
        showMyAgenda
        showBiography
        createdAt
        updatedAt
        owner {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      delegation {
        id
        name
        customer
        description
        closingDate
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        modificationRequests {
          nextToken
        }
      }
      userShowcase {
        id
        participationId
        businessPhone
        companyName
        workJob
        description
        birthDate
        phone
        contactEmail
        nationality
        address
        linkedin
        facebook
        twitter
        instagram
        createdAt
        updatedAt
        owner {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      user {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      publicFieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      sponsorStaff {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      agencyGroup {
        id
        isDeleted
        name
        isPrepaid
        client
        closingDate
        createdAt
        updatedAt
        participations {
          nextToken
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
      }
      agency {
        id
        isDeleted
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        agencyGroups {
          nextToken
        }
      }
      myBreakoutRoom {
        items {
          id
          state
          note
          isSelfProposed
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      typology {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
      participationHistorical {
        items {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      conflictRequest {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateParticipation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
      cognitoUserId
      isDeleted
      email
      givenName
      familyName
      businessName
      owner
      cluster
      isParticipant
      isSpeaker
      isReviewer
      isScientificCommittee
      paymentStatus
      registrationChannel
      isInvited
      status
      feeBracketModel {
        id
        start
        end
        name
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBracket {
          nextToken
        }
        createdAt
        updatedAt
      }
      participationEventId
      reviewer {
        id
        favoriteCategory {
          id
          name
          createdAt
          updatedAt
        }
        favoriteSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        reviews {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      abstractDocuments {
        items {
          id
          title
          status
          cod0
          cod1
          cod2
          url
          int
          disc
          preferredExposure
          processingStatus
          tags
          keywords
          isReadyToReview
          images
          isDeleted
          isOwnerAPresentingAuthor
          createdAt
          updatedAt
        }
        nextToken
      }
      webcastSpeeches {
        items {
          id
          speakerType
          createdAt
          updatedAt
        }
        nextToken
      }
      surveyInvitations {
        items {
          id
          isCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      issues {
        items {
          id
          issueName
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      registrationFee
      myAgenda {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      provocateurRole {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      moderatorRole {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      userSettings {
        id
        participationId
        isPublic
        showMyAgenda
        showBiography
        createdAt
        updatedAt
        owner {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      delegation {
        id
        name
        customer
        description
        closingDate
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        modificationRequests {
          nextToken
        }
      }
      userShowcase {
        id
        participationId
        businessPhone
        companyName
        workJob
        description
        birthDate
        phone
        contactEmail
        nationality
        address
        linkedin
        facebook
        twitter
        instagram
        createdAt
        updatedAt
        owner {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      user {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      publicFieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      sponsorStaff {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      agencyGroup {
        id
        isDeleted
        name
        isPrepaid
        client
        closingDate
        createdAt
        updatedAt
        participations {
          nextToken
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
      }
      agency {
        id
        isDeleted
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        agencyGroups {
          nextToken
        }
      }
      myBreakoutRoom {
        items {
          id
          state
          note
          isSelfProposed
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      typology {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
      participationHistorical {
        items {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      conflictRequest {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUserShowcase = /* GraphQL */ `
  mutation CreateUserShowcase(
    $input: CreateUserShowcaseInput!
    $condition: ModelUserShowcaseConditionInput
  ) {
    createUserShowcase(input: $input, condition: $condition) {
      id
      participationId
      businessPhone
      companyName
      workJob
      description
      birthDate
      phone
      contactEmail
      nationality
      address
      linkedin
      facebook
      twitter
      instagram
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      profileImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      coverImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateUserShowcase = /* GraphQL */ `
  mutation UpdateUserShowcase(
    $input: UpdateUserShowcaseInput!
    $condition: ModelUserShowcaseConditionInput
  ) {
    updateUserShowcase(input: $input, condition: $condition) {
      id
      participationId
      businessPhone
      companyName
      workJob
      description
      birthDate
      phone
      contactEmail
      nationality
      address
      linkedin
      facebook
      twitter
      instagram
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      profileImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      coverImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteUserShowcase = /* GraphQL */ `
  mutation DeleteUserShowcase(
    $input: DeleteUserShowcaseInput!
    $condition: ModelUserShowcaseConditionInput
  ) {
    deleteUserShowcase(input: $input, condition: $condition) {
      id
      participationId
      businessPhone
      companyName
      workJob
      description
      birthDate
      phone
      contactEmail
      nationality
      address
      linkedin
      facebook
      twitter
      instagram
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      profileImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      coverImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoUserId
      email
      givenName
      familyName
      businessName
      isAdmin
      type
      status
      nationality
      language
      birthDate
      fiscalCode
      vatNumber
      zendeskUserId
      createdAt
      updatedAt
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoUserId
      email
      givenName
      familyName
      businessName
      isAdmin
      type
      status
      nationality
      language
      birthDate
      fiscalCode
      vatNumber
      createdAt
      updatedAt
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoUserId
      email
      givenName
      familyName
      businessName
      isAdmin
      type
      status
      nationality
      language
      birthDate
      fiscalCode
      vatNumber
      zendeskUserId
      createdAt
      updatedAt
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAbstractService = /* GraphQL */ `
  mutation CreateAbstractService(
    $input: CreateAbstractServiceInput!
    $condition: ModelAbstractServiceConditionInput
  ) {
    createAbstractService(input: $input, condition: $condition) {
      id
      categories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationTypologies {
        items {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        nextToken
      }
      abstractServiceEventId
      abstractDocuments {
        items {
          id
          title
          status
          cod0
          cod1
          cod2
          url
          int
          disc
          preferredExposure
          processingStatus
          tags
          keywords
          isReadyToReview
          images
          isDeleted
          isOwnerAPresentingAuthor
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewCriteria {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewCriteriaStr
      instructions
      algorithm {
        id
        name
        createdAt
        updatedAt
      }
      tags
      abstractTerm
      firstReminder
      secondReminder
      reviewTerm
      draftReminder
      maxAbstractsPerAuthor
      isCountryConflictDisabled
      isInstitutionConflictDisabled
      reviewersQuantity
      agreement
      reviewers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const updateAbstractService = /* GraphQL */ `
  mutation UpdateAbstractService(
    $input: UpdateAbstractServiceInput!
    $condition: ModelAbstractServiceConditionInput
  ) {
    updateAbstractService(input: $input, condition: $condition) {
      id
      categories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationTypologies {
        items {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        nextToken
      }
      abstractServiceEventId
      abstractDocuments {
        items {
          id
          title
          status
          cod0
          cod1
          cod2
          url
          int
          disc
          preferredExposure
          processingStatus
          tags
          keywords
          isReadyToReview
          images
          isDeleted
          isOwnerAPresentingAuthor
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewCriteria {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewCriteriaStr
      instructions
      algorithm {
        id
        name
        createdAt
        updatedAt
      }
      tags
      abstractTerm
      firstReminder
      secondReminder
      reviewTerm
      draftReminder
      maxAbstractsPerAuthor
      isCountryConflictDisabled
      isInstitutionConflictDisabled
      reviewersQuantity
      agreement
      reviewers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const deleteAbstractService = /* GraphQL */ `
  mutation DeleteAbstractService(
    $input: DeleteAbstractServiceInput!
    $condition: ModelAbstractServiceConditionInput
  ) {
    deleteAbstractService(input: $input, condition: $condition) {
      id
      categories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationTypologies {
        items {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        nextToken
      }
      abstractServiceEventId
      abstractDocuments {
        items {
          id
          title
          status
          cod0
          cod1
          cod2
          url
          int
          disc
          preferredExposure
          processingStatus
          tags
          keywords
          isReadyToReview
          images
          isDeleted
          isOwnerAPresentingAuthor
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewCriteria {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewCriteriaStr
      instructions
      algorithm {
        id
        name
        createdAt
        updatedAt
      }
      tags
      abstractTerm
      firstReminder
      secondReminder
      reviewTerm
      draftReminder
      maxAbstractsPerAuthor
      isCountryConflictDisabled
      isInstitutionConflictDisabled
      reviewersQuantity
      agreement
      reviewers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      title
      status
      sentAt
      model
      invitations {
        items {
          id
          isCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      responses {
        items {
          id
          result
          createdAt
          updatedAt
        }
        nextToken
      }
      surveyEventId
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      title
      status
      sentAt
      model
      invitations {
        items {
          id
          isCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      responses {
        items {
          id
          result
          createdAt
          updatedAt
        }
        nextToken
      }
      surveyEventId
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      title
      status
      sentAt
      model
      invitations {
        items {
          id
          isCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      responses {
        items {
          id
          result
          createdAt
          updatedAt
        }
        nextToken
      }
      surveyEventId
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const createQuote = /* GraphQL */ `
  mutation CreateQuote(
    $input: CreateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    createQuote(input: $input, condition: $condition) {
      id
      quoteEventId
      profileName
      cluster
      dateRanges {
        items {
          id
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      currency
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const updateQuote = /* GraphQL */ `
  mutation UpdateQuote(
    $input: UpdateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    updateQuote(input: $input, condition: $condition) {
      id
      quoteEventId
      profileName
      cluster
      dateRanges {
        items {
          id
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      currency
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const deleteQuote = /* GraphQL */ `
  mutation DeleteQuote(
    $input: DeleteQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    deleteQuote(input: $input, condition: $condition) {
      id
      quoteEventId
      profileName
      cluster
      dateRanges {
        items {
          id
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      currency
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const createFieldDefinition = /* GraphQL */ `
  mutation CreateFieldDefinition(
    $input: CreateFieldDefinitionInput!
    $condition: ModelFieldDefinitionConditionInput
  ) {
    createFieldDefinition(input: $input, condition: $condition) {
      id
      controlType
      label
      placeholder
      options
      isDeleted
      contextsOfUse {
        items {
          id
          position
          contextName
          isRequired
          isHidden
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFieldDefinition = /* GraphQL */ `
  mutation UpdateFieldDefinition(
    $input: UpdateFieldDefinitionInput!
    $condition: ModelFieldDefinitionConditionInput
  ) {
    updateFieldDefinition(input: $input, condition: $condition) {
      id
      controlType
      label
      placeholder
      options
      isDeleted
      contextsOfUse {
        items {
          id
          position
          contextName
          isRequired
          isHidden
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFieldDefinition = /* GraphQL */ `
  mutation DeleteFieldDefinition(
    $input: DeleteFieldDefinitionInput!
    $condition: ModelFieldDefinitionConditionInput
  ) {
    deleteFieldDefinition(input: $input, condition: $condition) {
      id
      controlType
      label
      placeholder
      options
      isDeleted
      contextsOfUse {
        items {
          id
          position
          contextName
          isRequired
          isHidden
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFieldValue = /* GraphQL */ `
  mutation CreateFieldValue(
    $input: CreateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    createFieldValue(input: $input, condition: $condition) {
      id
      value
      fieldValueFieldDefinitionId
      createdAt
      updatedAt
      fieldDefinition {
        id
        controlType
        label
        placeholder
        options
        isDeleted
        contextsOfUse {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFieldValue = /* GraphQL */ `
  mutation UpdateFieldValue(
    $input: UpdateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    updateFieldValue(input: $input, condition: $condition) {
      id
      value
      fieldValueFieldDefinitionId
      createdAt
      updatedAt
      fieldDefinition {
        id
        controlType
        label
        placeholder
        options
        isDeleted
        contextsOfUse {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFieldValue = /* GraphQL */ `
  mutation DeleteFieldValue(
    $input: DeleteFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    deleteFieldValue(input: $input, condition: $condition) {
      id
      value
      fieldValueFieldDefinitionId
      createdAt
      updatedAt
      fieldDefinition {
        id
        controlType
        label
        placeholder
        options
        isDeleted
        contextsOfUse {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSponsorList = /* GraphQL */ `
  mutation CreateSponsorList(
    $input: CreateSponsorListInput!
    $condition: ModelSponsorListConditionInput
  ) {
    createSponsorList(input: $input, condition: $condition) {
      id
      isDeleted
      name
      description
      closingDate
      sponsorListService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorLists {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      modificationRequests {
        items {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSponsorList = /* GraphQL */ `
  mutation UpdateSponsorList(
    $input: UpdateSponsorListInput!
    $condition: ModelSponsorListConditionInput
  ) {
    updateSponsorList(input: $input, condition: $condition) {
      id
      isDeleted
      name
      description
      closingDate
      sponsorListService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorLists {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      modificationRequests {
        items {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSponsorList = /* GraphQL */ `
  mutation DeleteSponsorList(
    $input: DeleteSponsorListInput!
    $condition: ModelSponsorListConditionInput
  ) {
    deleteSponsorList(input: $input, condition: $condition) {
      id
      isDeleted
      name
      description
      closingDate
      sponsorListService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorLists {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      modificationRequests {
        items {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSponsorListPurchase = /* GraphQL */ `
  mutation CreateSponsorListPurchase(
    $input: CreateSponsorListPurchaseInput!
    $condition: ModelSponsorListPurchaseConditionInput
  ) {
    createSponsorListPurchase(input: $input, condition: $condition) {
      id
      isDeleted
      purchasedSubscriptions
      netPrice
      quantity
      createdAt
      updatedAt
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSponsorListPurchase = /* GraphQL */ `
  mutation UpdateSponsorListPurchase(
    $input: UpdateSponsorListPurchaseInput!
    $condition: ModelSponsorListPurchaseConditionInput
  ) {
    updateSponsorListPurchase(input: $input, condition: $condition) {
      id
      isDeleted
      purchasedSubscriptions
      netPrice
      quantity
      createdAt
      updatedAt
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSponsorListPurchase = /* GraphQL */ `
  mutation DeleteSponsorListPurchase(
    $input: DeleteSponsorListPurchaseInput!
    $condition: ModelSponsorListPurchaseConditionInput
  ) {
    deleteSponsorListPurchase(input: $input, condition: $condition) {
      id
      isDeleted
      purchasedSubscriptions
      netPrice
      quantity
      createdAt
      updatedAt
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSponsorStaff = /* GraphQL */ `
  mutation CreateSponsorStaff(
    $input: CreateSponsorStaffInput!
    $condition: ModelSponsorStaffConditionInput
  ) {
    createSponsorStaff(input: $input, condition: $condition) {
      id
      isDeleted
      name
      description
      closingDate
      sponsorStaffService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorStaffs {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
    }
  }
`;
export const updateSponsorStaff = /* GraphQL */ `
  mutation UpdateSponsorStaff(
    $input: UpdateSponsorStaffInput!
    $condition: ModelSponsorStaffConditionInput
  ) {
    updateSponsorStaff(input: $input, condition: $condition) {
      id
      isDeleted
      name
      description
      closingDate
      sponsorStaffService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorStaffs {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
    }
  }
`;
export const deleteSponsorStaff = /* GraphQL */ `
  mutation DeleteSponsorStaff(
    $input: DeleteSponsorStaffInput!
    $condition: ModelSponsorStaffConditionInput
  ) {
    deleteSponsorStaff(input: $input, condition: $condition) {
      id
      isDeleted
      name
      description
      closingDate
      sponsorStaffService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorStaffs {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
    }
  }
`;
export const createSponsorStaffPurchase = /* GraphQL */ `
  mutation CreateSponsorStaffPurchase(
    $input: CreateSponsorStaffPurchaseInput!
    $condition: ModelSponsorStaffPurchaseConditionInput
  ) {
    createSponsorStaffPurchase(input: $input, condition: $condition) {
      id
      isDeleted
      purchasedSubscriptions
      netPrice
      quantity
      createdAt
      updatedAt
      sponsorStaff {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSponsorStaffPurchase = /* GraphQL */ `
  mutation UpdateSponsorStaffPurchase(
    $input: UpdateSponsorStaffPurchaseInput!
    $condition: ModelSponsorStaffPurchaseConditionInput
  ) {
    updateSponsorStaffPurchase(input: $input, condition: $condition) {
      id
      isDeleted
      purchasedSubscriptions
      netPrice
      quantity
      createdAt
      updatedAt
      sponsorStaff {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSponsorStaffPurchase = /* GraphQL */ `
  mutation DeleteSponsorStaffPurchase(
    $input: DeleteSponsorStaffPurchaseInput!
    $condition: ModelSponsorStaffPurchaseConditionInput
  ) {
    deleteSponsorStaffPurchase(input: $input, condition: $condition) {
      id
      isDeleted
      purchasedSubscriptions
      netPrice
      quantity
      createdAt
      updatedAt
      sponsorStaff {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSponsor = /* GraphQL */ `
  mutation CreateSponsor(
    $input: CreateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    createSponsor(input: $input, condition: $condition) {
      id
      isDeleted
      name
      description
      businessName
      therapeuticArea
      priceRange {
        id
        name
        description
        price
        start
        freeStaffMembers
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsors {
          nextToken
        }
      }
      contacts {
        items {
          id
          name
          surname
          email
          phone
          facebook
          twitter
          linkedin
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandSpaces {
        items {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandSpaceItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorVirtualStand {
        items {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      admins {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      sponsorStaff {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      banners {
        items {
          id
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      symposiums {
        items {
          id
          price
          createdAt
          updatedAt
        }
        nextToken
      }
      profileImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      profileCover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
        nextToken
      }
      publicPage {
        id
        isPublic
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      breakoutRooms {
        items {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSponsor = /* GraphQL */ `
  mutation UpdateSponsor(
    $input: UpdateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    updateSponsor(input: $input, condition: $condition) {
      id
      isDeleted
      name
      description
      businessName
      therapeuticArea
      priceRange {
        id
        name
        description
        price
        start
        freeStaffMembers
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsors {
          nextToken
        }
      }
      contacts {
        items {
          id
          name
          surname
          email
          phone
          facebook
          twitter
          linkedin
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandSpaces {
        items {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandSpaceItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorVirtualStand {
        items {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      admins {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      sponsorStaff {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      banners {
        items {
          id
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      symposiums {
        items {
          id
          price
          createdAt
          updatedAt
        }
        nextToken
      }
      profileImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      profileCover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
        nextToken
      }
      publicPage {
        id
        isPublic
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      breakoutRooms {
        items {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSponsor = /* GraphQL */ `
  mutation DeleteSponsor(
    $input: DeleteSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    deleteSponsor(input: $input, condition: $condition) {
      id
      isDeleted
      name
      description
      businessName
      therapeuticArea
      priceRange {
        id
        name
        description
        price
        start
        freeStaffMembers
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsors {
          nextToken
        }
      }
      contacts {
        items {
          id
          name
          surname
          email
          phone
          facebook
          twitter
          linkedin
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandSpaces {
        items {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandSpaceItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorVirtualStand {
        items {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      admins {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      sponsorStaff {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      banners {
        items {
          id
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      symposiums {
        items {
          id
          price
          createdAt
          updatedAt
        }
        nextToken
      }
      profileImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      profileCover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
        nextToken
      }
      publicPage {
        id
        isPublic
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      breakoutRooms {
        items {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBannerArea = /* GraphQL */ `
  mutation CreateBannerArea(
    $input: CreateBannerAreaInput!
    $condition: ModelBannerAreaConditionInput
  ) {
    createBannerArea(input: $input, condition: $condition) {
      id
      name
      price
      isExclusive
      bannerPage {
        id
        name
        image
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        bannerAreas {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsorAreas {
        items {
          id
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBannerArea = /* GraphQL */ `
  mutation UpdateBannerArea(
    $input: UpdateBannerAreaInput!
    $condition: ModelBannerAreaConditionInput
  ) {
    updateBannerArea(input: $input, condition: $condition) {
      id
      name
      price
      isExclusive
      bannerPage {
        id
        name
        image
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        bannerAreas {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsorAreas {
        items {
          id
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBannerArea = /* GraphQL */ `
  mutation DeleteBannerArea(
    $input: DeleteBannerAreaInput!
    $condition: ModelBannerAreaConditionInput
  ) {
    deleteBannerArea(input: $input, condition: $condition) {
      id
      name
      price
      isExclusive
      bannerPage {
        id
        name
        image
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        bannerAreas {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsorAreas {
        items {
          id
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSponsorArea = /* GraphQL */ `
  mutation CreateSponsorArea(
    $input: CreateSponsorAreaInput!
    $condition: ModelSponsorAreaConditionInput
  ) {
    createSponsorArea(input: $input, condition: $condition) {
      id
      image
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      bannerArea {
        id
        name
        price
        isExclusive
        bannerPage {
          id
          name
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorAreas {
          nextToken
        }
        buyOperations {
          nextToken
        }
      }
    }
  }
`;
export const updateSponsorArea = /* GraphQL */ `
  mutation UpdateSponsorArea(
    $input: UpdateSponsorAreaInput!
    $condition: ModelSponsorAreaConditionInput
  ) {
    updateSponsorArea(input: $input, condition: $condition) {
      id
      image
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      bannerArea {
        id
        name
        price
        isExclusive
        bannerPage {
          id
          name
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorAreas {
          nextToken
        }
        buyOperations {
          nextToken
        }
      }
    }
  }
`;
export const deleteSponsorArea = /* GraphQL */ `
  mutation DeleteSponsorArea(
    $input: DeleteSponsorAreaInput!
    $condition: ModelSponsorAreaConditionInput
  ) {
    deleteSponsorArea(input: $input, condition: $condition) {
      id
      image
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      bannerArea {
        id
        name
        price
        isExclusive
        bannerPage {
          id
          name
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorAreas {
          nextToken
        }
        buyOperations {
          nextToken
        }
      }
    }
  }
`;
export const createSymposium = /* GraphQL */ `
  mutation CreateSymposium(
    $input: CreateSymposiumInput!
    $condition: ModelSymposiumConditionInput
  ) {
    createSymposium(input: $input, condition: $condition) {
      id
      price
      symposiumService {
        id
        name
        description
        vatRate
        deadline
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        symposiums {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      agendaSession {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateSymposium = /* GraphQL */ `
  mutation UpdateSymposium(
    $input: UpdateSymposiumInput!
    $condition: ModelSymposiumConditionInput
  ) {
    updateSymposium(input: $input, condition: $condition) {
      id
      price
      symposiumService {
        id
        name
        description
        vatRate
        deadline
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        symposiums {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      agendaSession {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteSymposium = /* GraphQL */ `
  mutation DeleteSymposium(
    $input: DeleteSymposiumInput!
    $condition: ModelSymposiumConditionInput
  ) {
    deleteSymposium(input: $input, condition: $condition) {
      id
      price
      symposiumService {
        id
        name
        description
        vatRate
        deadline
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        symposiums {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      agendaSession {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        visibilities {
          nextToken
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createAgencyGroup = /* GraphQL */ `
  mutation CreateAgencyGroup(
    $input: CreateAgencyGroupInput!
    $condition: ModelAgencyGroupConditionInput
  ) {
    createAgencyGroup(input: $input, condition: $condition) {
      id
      isDeleted
      name
      isPrepaid
      client
      closingDate
      createdAt
      updatedAt
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      agency {
        id
        isDeleted
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        agencyGroups {
          nextToken
        }
      }
    }
  }
`;
export const updateAgencyGroup = /* GraphQL */ `
  mutation UpdateAgencyGroup(
    $input: UpdateAgencyGroupInput!
    $condition: ModelAgencyGroupConditionInput
  ) {
    updateAgencyGroup(input: $input, condition: $condition) {
      id
      isDeleted
      name
      isPrepaid
      client
      closingDate
      createdAt
      updatedAt
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      agency {
        id
        isDeleted
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        agencyGroups {
          nextToken
        }
      }
    }
  }
`;
export const deleteAgencyGroup = /* GraphQL */ `
  mutation DeleteAgencyGroup(
    $input: DeleteAgencyGroupInput!
    $condition: ModelAgencyGroupConditionInput
  ) {
    deleteAgencyGroup(input: $input, condition: $condition) {
      id
      isDeleted
      name
      isPrepaid
      client
      closingDate
      createdAt
      updatedAt
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      agency {
        id
        isDeleted
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        agencyGroups {
          nextToken
        }
      }
    }
  }
`;
export const createAgency = /* GraphQL */ `
  mutation CreateAgency(
    $input: CreateAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    createAgency(input: $input, condition: $condition) {
      id
      isDeleted
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      admins {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      agencyGroups {
        items {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAgency = /* GraphQL */ `
  mutation UpdateAgency(
    $input: UpdateAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    updateAgency(input: $input, condition: $condition) {
      id
      isDeleted
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      admins {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      agencyGroups {
        items {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAgency = /* GraphQL */ `
  mutation DeleteAgency(
    $input: DeleteAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    deleteAgency(input: $input, condition: $condition) {
      id
      isDeleted
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      admins {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      agencyGroups {
        items {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAgencyService = /* GraphQL */ `
  mutation CreateAgencyService(
    $input: CreateAgencyServiceInput!
    $condition: ModelAgencyServiceConditionInput
  ) {
    createAgencyService(input: $input, condition: $condition) {
      id
      minParticipations
      instructions
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      cover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      gallery {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      policies {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAgencyService = /* GraphQL */ `
  mutation UpdateAgencyService(
    $input: UpdateAgencyServiceInput!
    $condition: ModelAgencyServiceConditionInput
  ) {
    updateAgencyService(input: $input, condition: $condition) {
      id
      minParticipations
      instructions
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      cover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      gallery {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      policies {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAgencyService = /* GraphQL */ `
  mutation DeleteAgencyService(
    $input: DeleteAgencyServiceInput!
    $condition: ModelAgencyServiceConditionInput
  ) {
    deleteAgencyService(input: $input, condition: $condition) {
      id
      minParticipations
      instructions
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      cover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      gallery {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      policies {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAgendaSession = /* GraphQL */ `
  mutation CreateAgendaSession(
    $input: CreateAgendaSessionInput!
    $condition: ModelAgendaSessionConditionInput
  ) {
    createAgendaSession(input: $input, condition: $condition) {
      id
      name
      date
      start
      end
      tags
      type
      president
      shortDescription
      description
      linkPax
      linkSpeaker
      context
      provocateurs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      moderators {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      interventions {
        items {
          id
          name
          start
          end
          instruction
          createdAt
          updatedAt
        }
        nextToken
      }
      agenda {
        id
        tags
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      followers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      room {
        id
        name
        type
        capacity
        delay
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        agendaSessions {
          nextToken
        }
      }
      createdAt
      updatedAt
      symposium {
        id
        price
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agendaSession {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      visibilities {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      coverImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAgendaSession = /* GraphQL */ `
  mutation UpdateAgendaSession(
    $input: UpdateAgendaSessionInput!
    $condition: ModelAgendaSessionConditionInput
  ) {
    updateAgendaSession(input: $input, condition: $condition) {
      id
      name
      date
      start
      end
      tags
      type
      president
      shortDescription
      description
      linkPax
      linkSpeaker
      context
      provocateurs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      moderators {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      interventions {
        items {
          id
          name
          start
          end
          instruction
          createdAt
          updatedAt
        }
        nextToken
      }
      agenda {
        id
        tags
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      followers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      room {
        id
        name
        type
        capacity
        delay
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        agendaSessions {
          nextToken
        }
      }
      createdAt
      updatedAt
      symposium {
        id
        price
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agendaSession {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      visibilities {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      coverImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAgendaSession = /* GraphQL */ `
  mutation DeleteAgendaSession(
    $input: DeleteAgendaSessionInput!
    $condition: ModelAgendaSessionConditionInput
  ) {
    deleteAgendaSession(input: $input, condition: $condition) {
      id
      name
      date
      start
      end
      tags
      type
      president
      shortDescription
      description
      linkPax
      linkSpeaker
      context
      provocateurs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      moderators {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      interventions {
        items {
          id
          name
          start
          end
          instruction
          createdAt
          updatedAt
        }
        nextToken
      }
      agenda {
        id
        tags
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      followers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      room {
        id
        name
        type
        capacity
        delay
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        agendaSessions {
          nextToken
        }
      }
      createdAt
      updatedAt
      symposium {
        id
        price
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agendaSession {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      visibilities {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      coverImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      originalName
      size
      extension
      createdAt
      updatedAt
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      originalName
      size
      extension
      createdAt
      updatedAt
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      originalName
      size
      extension
      createdAt
      updatedAt
    }
  }
`;
export const createBuyOperation = /* GraphQL */ `
  mutation CreateBuyOperation(
    $input: CreateBuyOperationInput!
    $condition: ModelBuyOperationConditionInput
  ) {
    createBuyOperation(input: $input, condition: $condition) {
      id
      eventId
      bookingId
      date
      expiringDate
      paymentType
      unitaryVatRate
      unitaryPrice
      physicalStandSpace {
        id
        netPrice
        name
        code
        noVat
        extraPriceDisabled
        note
        spaceLength
        spaceHeight
        surface
        floor {
          id
          code
          name
          createdAt
          updatedAt
        }
        physicalStandSpacesItems {
          nextToken
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        plantImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      physicalStandSpaceItems {
        id
        quantity
        amountPerQuantity
        item {
          id
          name
          code
          netPrice
          vatRate
          type
          amountPerQuantityLabel
          note
          description
          createdAt
          updatedAt
        }
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      sponsorVirtualStand {
        id
        virtualRoomLink
        virtualStand {
          id
          name
          isEnabled
          ranking
          netPrice
          specificationPost
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      sponsorListPurchase {
        id
        isDeleted
        purchasedSubscriptions
        netPrice
        quantity
        createdAt
        updatedAt
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      sponsorStaffPurchase {
        id
        isDeleted
        purchasedSubscriptions
        netPrice
        quantity
        createdAt
        updatedAt
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      bannerArea {
        id
        name
        price
        isExclusive
        bannerPage {
          id
          name
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorAreas {
          nextToken
        }
        buyOperations {
          nextToken
        }
      }
      symposium {
        id
        price
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agendaSession {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      purchase {
        id
        eventId
        paymentType
        date
        purchaseId
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperations {
          nextToken
        }
        payment {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
      }
      publicPage {
        id
        isPublic
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      breakoutRoom {
        id
        date
        start1
        end1
        start2
        end2
        duration
        maxAttendants
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        purchasedHours
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        slots {
          nextToken
        }
      }
    }
  }
`;
export const updateBuyOperation = /* GraphQL */ `
  mutation UpdateBuyOperation(
    $input: UpdateBuyOperationInput!
    $condition: ModelBuyOperationConditionInput
  ) {
    updateBuyOperation(input: $input, condition: $condition) {
      id
      eventId
      bookingId
      date
      expiringDate
      paymentType
      unitaryVatRate
      unitaryPrice
      physicalStandSpace {
        id
        netPrice
        name
        code
        noVat
        extraPriceDisabled
        note
        spaceLength
        spaceHeight
        surface
        floor {
          id
          code
          name
          createdAt
          updatedAt
        }
        physicalStandSpacesItems {
          nextToken
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        plantImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      physicalStandSpaceItems {
        id
        quantity
        amountPerQuantity
        item {
          id
          name
          code
          netPrice
          vatRate
          type
          amountPerQuantityLabel
          note
          description
          createdAt
          updatedAt
        }
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      sponsorVirtualStand {
        id
        virtualRoomLink
        virtualStand {
          id
          name
          isEnabled
          ranking
          netPrice
          specificationPost
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      sponsorListPurchase {
        id
        isDeleted
        purchasedSubscriptions
        netPrice
        quantity
        createdAt
        updatedAt
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      sponsorStaffPurchase {
        id
        isDeleted
        purchasedSubscriptions
        netPrice
        quantity
        createdAt
        updatedAt
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      bannerArea {
        id
        name
        price
        isExclusive
        bannerPage {
          id
          name
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorAreas {
          nextToken
        }
        buyOperations {
          nextToken
        }
      }
      symposium {
        id
        price
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agendaSession {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      purchase {
        id
        eventId
        paymentType
        date
        purchaseId
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperations {
          nextToken
        }
        payment {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
      }
      publicPage {
        id
        isPublic
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      breakoutRoom {
        id
        date
        start1
        end1
        start2
        end2
        duration
        maxAttendants
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        purchasedHours
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        slots {
          nextToken
        }
      }
    }
  }
`;
export const deleteBuyOperation = /* GraphQL */ `
  mutation DeleteBuyOperation(
    $input: DeleteBuyOperationInput!
    $condition: ModelBuyOperationConditionInput
  ) {
    deleteBuyOperation(input: $input, condition: $condition) {
      id
      eventId
      bookingId
      date
      expiringDate
      paymentType
      unitaryVatRate
      unitaryPrice
      physicalStandSpace {
        id
        netPrice
        name
        code
        noVat
        extraPriceDisabled
        note
        spaceLength
        spaceHeight
        surface
        floor {
          id
          code
          name
          createdAt
          updatedAt
        }
        physicalStandSpacesItems {
          nextToken
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        plantImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      physicalStandSpaceItems {
        id
        quantity
        amountPerQuantity
        item {
          id
          name
          code
          netPrice
          vatRate
          type
          amountPerQuantityLabel
          note
          description
          createdAt
          updatedAt
        }
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      sponsorVirtualStand {
        id
        virtualRoomLink
        virtualStand {
          id
          name
          isEnabled
          ranking
          netPrice
          specificationPost
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      sponsorListPurchase {
        id
        isDeleted
        purchasedSubscriptions
        netPrice
        quantity
        createdAt
        updatedAt
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      sponsorStaffPurchase {
        id
        isDeleted
        purchasedSubscriptions
        netPrice
        quantity
        createdAt
        updatedAt
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      bannerArea {
        id
        name
        price
        isExclusive
        bannerPage {
          id
          name
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorAreas {
          nextToken
        }
        buyOperations {
          nextToken
        }
      }
      symposium {
        id
        price
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agendaSession {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      purchase {
        id
        eventId
        paymentType
        date
        purchaseId
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperations {
          nextToken
        }
        payment {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
      }
      publicPage {
        id
        isPublic
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      breakoutRoom {
        id
        date
        start1
        end1
        start2
        end2
        duration
        maxAttendants
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        purchasedHours
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        slots {
          nextToken
        }
      }
    }
  }
`;
export const createPurchase = /* GraphQL */ `
  mutation CreatePurchase(
    $input: CreatePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    createPurchase(input: $input, condition: $condition) {
      id
      eventId
      paymentType
      date
      purchaseId
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      payment {
        id
        paymentId
        paymentIdMonetaOnLine
        securitytokenMonetaOnLine
        paymentIdIngenico
        shasignIngenico
        paymentIdGpWebpay
        digestGpWebpay
        paymentError
        date
        stepName
        stepNote
        paymentType
        amount
        description
        failCleaned
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePurchase = /* GraphQL */ `
  mutation UpdatePurchase(
    $input: UpdatePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    updatePurchase(input: $input, condition: $condition) {
      id
      eventId
      paymentType
      date
      purchaseId
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      payment {
        id
        paymentId
        paymentIdMonetaOnLine
        securitytokenMonetaOnLine
        paymentIdIngenico
        shasignIngenico
        paymentIdGpWebpay
        digestGpWebpay
        paymentError
        date
        stepName
        stepNote
        paymentType
        amount
        description
        failCleaned
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePurchase = /* GraphQL */ `
  mutation DeletePurchase(
    $input: DeletePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    deletePurchase(input: $input, condition: $condition) {
      id
      eventId
      paymentType
      date
      purchaseId
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      payment {
        id
        paymentId
        paymentIdMonetaOnLine
        securitytokenMonetaOnLine
        paymentIdIngenico
        shasignIngenico
        paymentIdGpWebpay
        digestGpWebpay
        paymentError
        date
        stepName
        stepNote
        paymentType
        amount
        description
        failCleaned
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      paymentId
      paymentIdMonetaOnLine
      securitytokenMonetaOnLine
      paymentIdIngenico
      shasignIngenico
      paymentIdGpWebpay
      digestGpWebpay
      paymentError
      date
      stepName
      stepNote
      paymentType
      amount
      description
      failCleaned
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      purchase {
        id
        eventId
        paymentType
        date
        purchaseId
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperations {
          nextToken
        }
        payment {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      paymentId
      paymentIdMonetaOnLine
      securitytokenMonetaOnLine
      paymentIdIngenico
      shasignIngenico
      paymentIdGpWebpay
      digestGpWebpay
      paymentError
      date
      stepName
      stepNote
      paymentType
      amount
      description
      failCleaned
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      purchase {
        id
        eventId
        paymentType
        date
        purchaseId
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperations {
          nextToken
        }
        payment {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      paymentId
      paymentIdMonetaOnLine
      securitytokenMonetaOnLine
      paymentIdIngenico
      shasignIngenico
      paymentIdGpWebpay
      digestGpWebpay
      paymentError
      date
      stepName
      stepNote
      paymentType
      amount
      description
      failCleaned
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      purchase {
        id
        eventId
        paymentType
        date
        purchaseId
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperations {
          nextToken
        }
        payment {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPublicPage = /* GraphQL */ `
  mutation CreatePublicPage(
    $input: CreatePublicPageInput!
    $condition: ModelPublicPageConditionInput
  ) {
    createPublicPage(input: $input, condition: $condition) {
      id
      isPublic
      publicPageService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        netPrice
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        publicPages {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePublicPage = /* GraphQL */ `
  mutation UpdatePublicPage(
    $input: UpdatePublicPageInput!
    $condition: ModelPublicPageConditionInput
  ) {
    updatePublicPage(input: $input, condition: $condition) {
      id
      isPublic
      publicPageService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        netPrice
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        publicPages {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePublicPage = /* GraphQL */ `
  mutation DeletePublicPage(
    $input: DeletePublicPageInput!
    $condition: ModelPublicPageConditionInput
  ) {
    deletePublicPage(input: $input, condition: $condition) {
      id
      isPublic
      publicPageService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        netPrice
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        publicPages {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createBreakoutRoom = /* GraphQL */ `
  mutation CreateBreakoutRoom(
    $input: CreateBreakoutRoomInput!
    $condition: ModelBreakoutRoomConditionInput
  ) {
    createBreakoutRoom(input: $input, condition: $condition) {
      id
      date
      start1
      end1
      start2
      end2
      duration
      maxAttendants
      breakoutRoomService {
        id
        name
        description
        price
        vatRate
        isSponsorEditable
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        maxAvailableHours
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        breakoutRooms {
          nextToken
        }
      }
      purchasedHours
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      slots {
        items {
          id
          title
          start
          end
          note
          isOneToOne
          vonageSessionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBreakoutRoom = /* GraphQL */ `
  mutation UpdateBreakoutRoom(
    $input: UpdateBreakoutRoomInput!
    $condition: ModelBreakoutRoomConditionInput
  ) {
    updateBreakoutRoom(input: $input, condition: $condition) {
      id
      date
      start1
      end1
      start2
      end2
      duration
      maxAttendants
      breakoutRoomService {
        id
        name
        description
        price
        vatRate
        isSponsorEditable
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        maxAvailableHours
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        breakoutRooms {
          nextToken
        }
      }
      purchasedHours
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      slots {
        items {
          id
          title
          start
          end
          note
          isOneToOne
          vonageSessionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBreakoutRoom = /* GraphQL */ `
  mutation DeleteBreakoutRoom(
    $input: DeleteBreakoutRoomInput!
    $condition: ModelBreakoutRoomConditionInput
  ) {
    deleteBreakoutRoom(input: $input, condition: $condition) {
      id
      date
      start1
      end1
      start2
      end2
      duration
      maxAttendants
      breakoutRoomService {
        id
        name
        description
        price
        vatRate
        isSponsorEditable
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        maxAvailableHours
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        breakoutRooms {
          nextToken
        }
      }
      purchasedHours
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      slots {
        items {
          id
          title
          start
          end
          note
          isOneToOne
          vonageSessionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBreakoutRoomSlot = /* GraphQL */ `
  mutation CreateBreakoutRoomSlot(
    $input: CreateBreakoutRoomSlotInput!
    $condition: ModelBreakoutRoomSlotConditionInput
  ) {
    createBreakoutRoomSlot(input: $input, condition: $condition) {
      id
      title
      start
      end
      note
      isOneToOne
      vonageSessionId
      createdAt
      updatedAt
      breakoutRoom {
        id
        date
        start1
        end1
        start2
        end2
        duration
        maxAttendants
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        purchasedHours
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        slots {
          nextToken
        }
      }
      emails {
        items {
          id
          token
          email
          state
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      invitations {
        items {
          id
          state
          note
          isSelfProposed
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBreakoutRoomSlot = /* GraphQL */ `
  mutation UpdateBreakoutRoomSlot(
    $input: UpdateBreakoutRoomSlotInput!
    $condition: ModelBreakoutRoomSlotConditionInput
  ) {
    updateBreakoutRoomSlot(input: $input, condition: $condition) {
      id
      title
      start
      end
      note
      isOneToOne
      vonageSessionId
      createdAt
      updatedAt
      breakoutRoom {
        id
        date
        start1
        end1
        start2
        end2
        duration
        maxAttendants
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        purchasedHours
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        slots {
          nextToken
        }
      }
      emails {
        items {
          id
          token
          email
          state
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      invitations {
        items {
          id
          state
          note
          isSelfProposed
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBreakoutRoomSlot = /* GraphQL */ `
  mutation DeleteBreakoutRoomSlot(
    $input: DeleteBreakoutRoomSlotInput!
    $condition: ModelBreakoutRoomSlotConditionInput
  ) {
    deleteBreakoutRoomSlot(input: $input, condition: $condition) {
      id
      title
      start
      end
      note
      isOneToOne
      vonageSessionId
      createdAt
      updatedAt
      breakoutRoom {
        id
        date
        start1
        end1
        start2
        end2
        duration
        maxAttendants
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        purchasedHours
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        slots {
          nextToken
        }
      }
      emails {
        items {
          id
          token
          email
          state
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      invitations {
        items {
          id
          state
          note
          isSelfProposed
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBreakoutRoomInvitationUnregistered = /* GraphQL */ `
  mutation CreateBreakoutRoomInvitationUnregistered(
    $input: CreateBreakoutRoomInvitationUnregisteredInput!
    $condition: ModelBreakoutRoomInvitationUnregisteredConditionInput
  ) {
    createBreakoutRoomInvitationUnregistered(
      input: $input
      condition: $condition
    ) {
      id
      token
      email
      state
      note
      createdAt
      updatedAt
      slot {
        id
        title
        start
        end
        note
        isOneToOne
        vonageSessionId
        createdAt
        updatedAt
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        emails {
          nextToken
        }
        invitations {
          nextToken
        }
      }
    }
  }
`;
export const updateBreakoutRoomInvitationUnregistered = /* GraphQL */ `
  mutation UpdateBreakoutRoomInvitationUnregistered(
    $input: UpdateBreakoutRoomInvitationUnregisteredInput!
    $condition: ModelBreakoutRoomInvitationUnregisteredConditionInput
  ) {
    updateBreakoutRoomInvitationUnregistered(
      input: $input
      condition: $condition
    ) {
      id
      token
      email
      state
      note
      createdAt
      updatedAt
      slot {
        id
        title
        start
        end
        note
        isOneToOne
        vonageSessionId
        createdAt
        updatedAt
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        emails {
          nextToken
        }
        invitations {
          nextToken
        }
      }
    }
  }
`;
export const deleteBreakoutRoomInvitationUnregistered = /* GraphQL */ `
  mutation DeleteBreakoutRoomInvitationUnregistered(
    $input: DeleteBreakoutRoomInvitationUnregisteredInput!
    $condition: ModelBreakoutRoomInvitationUnregisteredConditionInput
  ) {
    deleteBreakoutRoomInvitationUnregistered(
      input: $input
      condition: $condition
    ) {
      id
      token
      email
      state
      note
      createdAt
      updatedAt
      slot {
        id
        title
        start
        end
        note
        isOneToOne
        vonageSessionId
        createdAt
        updatedAt
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        emails {
          nextToken
        }
        invitations {
          nextToken
        }
      }
    }
  }
`;
export const createBreakoutRoomAttending = /* GraphQL */ `
  mutation CreateBreakoutRoomAttending(
    $input: CreateBreakoutRoomAttendingInput!
    $condition: ModelBreakoutRoomAttendingConditionInput
  ) {
    createBreakoutRoomAttending(input: $input, condition: $condition) {
      id
      state
      note
      isSelfProposed
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      slot {
        id
        title
        start
        end
        note
        isOneToOne
        vonageSessionId
        createdAt
        updatedAt
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        emails {
          nextToken
        }
        invitations {
          nextToken
        }
      }
    }
  }
`;
export const updateBreakoutRoomAttending = /* GraphQL */ `
  mutation UpdateBreakoutRoomAttending(
    $input: UpdateBreakoutRoomAttendingInput!
    $condition: ModelBreakoutRoomAttendingConditionInput
  ) {
    updateBreakoutRoomAttending(input: $input, condition: $condition) {
      id
      state
      note
      isSelfProposed
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      slot {
        id
        title
        start
        end
        note
        isOneToOne
        vonageSessionId
        createdAt
        updatedAt
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        emails {
          nextToken
        }
        invitations {
          nextToken
        }
      }
    }
  }
`;
export const deleteBreakoutRoomAttending = /* GraphQL */ `
  mutation DeleteBreakoutRoomAttending(
    $input: DeleteBreakoutRoomAttendingInput!
    $condition: ModelBreakoutRoomAttendingConditionInput
  ) {
    deleteBreakoutRoomAttending(input: $input, condition: $condition) {
      id
      state
      note
      isSelfProposed
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      slot {
        id
        title
        start
        end
        note
        isOneToOne
        vonageSessionId
        createdAt
        updatedAt
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        emails {
          nextToken
        }
        invitations {
          nextToken
        }
      }
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      name
      cluster
      isPublic
      category {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
      }
      profileFeeBrackets {
        items {
          id
          priceOnAir
          priceOnSite
          discountPrice
          discountCode
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      createdBy
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      name
      cluster
      isPublic
      category {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
      }
      profileFeeBrackets {
        items {
          id
          priceOnAir
          priceOnSite
          discountPrice
          discountCode
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      createdBy
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      name
      cluster
      isPublic
      category {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
      }
      profileFeeBrackets {
        items {
          id
          priceOnAir
          priceOnSite
          discountPrice
          discountCode
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      createdBy
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createParticipationTypology = /* GraphQL */ `
  mutation CreateParticipationTypology(
    $input: CreateParticipationTypologyInput!
    $condition: ModelParticipationTypologyConditionInput
  ) {
    createParticipationTypology(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const updateParticipationTypology = /* GraphQL */ `
  mutation UpdateParticipationTypology(
    $input: UpdateParticipationTypologyInput!
    $condition: ModelParticipationTypologyConditionInput
  ) {
    updateParticipationTypology(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const deleteParticipationTypology = /* GraphQL */ `
  mutation DeleteParticipationTypology(
    $input: DeleteParticipationTypologyInput!
    $condition: ModelParticipationTypologyConditionInput
  ) {
    deleteParticipationTypology(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const createFeeDateRange = /* GraphQL */ `
  mutation CreateFeeDateRange(
    $input: CreateFeeDateRangeInput!
    $condition: ModelFeeDateRangeConditionInput
  ) {
    createFeeDateRange(input: $input, condition: $condition) {
      id
      start
      end
      label
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      feeBrackets {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFeeDateRange = /* GraphQL */ `
  mutation UpdateFeeDateRange(
    $input: UpdateFeeDateRangeInput!
    $condition: ModelFeeDateRangeConditionInput
  ) {
    updateFeeDateRange(input: $input, condition: $condition) {
      id
      start
      end
      label
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      feeBrackets {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeeDateRange = /* GraphQL */ `
  mutation DeleteFeeDateRange(
    $input: DeleteFeeDateRangeInput!
    $condition: ModelFeeDateRangeConditionInput
  ) {
    deleteFeeDateRange(input: $input, condition: $condition) {
      id
      start
      end
      label
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      feeBrackets {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGateway = /* GraphQL */ `
  mutation CreateGateway(
    $input: CreateGatewayInput!
    $condition: ModelGatewayConditionInput
  ) {
    createGateway(input: $input, condition: $condition) {
      id
      service
      paymentType
      createdAt
      updatedAt
      shop {
        id
        name
        gatewayType
        gatewayId
        gatewayPwd
        iban
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateGateway = /* GraphQL */ `
  mutation UpdateGateway(
    $input: UpdateGatewayInput!
    $condition: ModelGatewayConditionInput
  ) {
    updateGateway(input: $input, condition: $condition) {
      id
      service
      paymentType
      createdAt
      updatedAt
      shop {
        id
        name
        gatewayType
        gatewayId
        gatewayPwd
        iban
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteGateway = /* GraphQL */ `
  mutation DeleteGateway(
    $input: DeleteGatewayInput!
    $condition: ModelGatewayConditionInput
  ) {
    deleteGateway(input: $input, condition: $condition) {
      id
      service
      paymentType
      createdAt
      updatedAt
      shop {
        id
        name
        gatewayType
        gatewayId
        gatewayPwd
        iban
        createdAt
        updatedAt
      }
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      id
      name
      gatewayType
      gatewayId
      gatewayPwd
      iban
      createdAt
      updatedAt
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      gatewayType
      gatewayId
      gatewayPwd
      iban
      createdAt
      updatedAt
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      name
      gatewayType
      gatewayId
      gatewayPwd
      iban
      createdAt
      updatedAt
    }
  }
`;
export const createParticipationRequest = /* GraphQL */ `
  mutation CreateParticipationRequest(
    $input: CreateParticipationRequestInput!
    $condition: ModelParticipationRequestConditionInput
  ) {
    createParticipationRequest(input: $input, condition: $condition) {
      id
      eventId
      familyName
      givenName
      email
      status
      isParticipant
      isSpeaker
      isReviewer
      isScientificCommittee
      cluster
      isDeleted
      createdAt
      updatedAt
      delegation {
        id
        name
        customer
        description
        closingDate
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        modificationRequests {
          nextToken
        }
      }
      participationSubject {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      typology {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
      modificationRequest {
        id
        eventId
        requestId
        type
        requestStatus
        paymentId
        participationId
        isDeleted
        createdAt
        updatedAt
        requestedBy {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        participationRequest {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateParticipationRequest = /* GraphQL */ `
  mutation UpdateParticipationRequest(
    $input: UpdateParticipationRequestInput!
    $condition: ModelParticipationRequestConditionInput
  ) {
    updateParticipationRequest(input: $input, condition: $condition) {
      id
      eventId
      familyName
      givenName
      email
      status
      isParticipant
      isSpeaker
      isReviewer
      isScientificCommittee
      cluster
      isDeleted
      createdAt
      updatedAt
      delegation {
        id
        name
        customer
        description
        closingDate
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        modificationRequests {
          nextToken
        }
      }
      participationSubject {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      typology {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
      modificationRequest {
        id
        eventId
        requestId
        type
        requestStatus
        paymentId
        participationId
        isDeleted
        createdAt
        updatedAt
        requestedBy {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        participationRequest {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteParticipationRequest = /* GraphQL */ `
  mutation DeleteParticipationRequest(
    $input: DeleteParticipationRequestInput!
    $condition: ModelParticipationRequestConditionInput
  ) {
    deleteParticipationRequest(input: $input, condition: $condition) {
      id
      eventId
      familyName
      givenName
      email
      status
      isParticipant
      isSpeaker
      isReviewer
      isScientificCommittee
      cluster
      isDeleted
      createdAt
      updatedAt
      delegation {
        id
        name
        customer
        description
        closingDate
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        modificationRequests {
          nextToken
        }
      }
      participationSubject {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      typology {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
      modificationRequest {
        id
        eventId
        requestId
        type
        requestStatus
        paymentId
        participationId
        isDeleted
        createdAt
        updatedAt
        requestedBy {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        participationRequest {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createModificationRequest = /* GraphQL */ `
  mutation CreateModificationRequest(
    $input: CreateModificationRequestInput!
    $condition: ModelModificationRequestConditionInput
  ) {
    createModificationRequest(input: $input, condition: $condition) {
      id
      eventId
      requestId
      type
      requestStatus
      paymentId
      participationId
      isDeleted
      createdAt
      updatedAt
      requestedBy {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
      participationRequest {
        id
        eventId
        familyName
        givenName
        email
        status
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        cluster
        isDeleted
        createdAt
        updatedAt
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        participationSubject {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        modificationRequest {
          id
          eventId
          requestId
          type
          requestStatus
          paymentId
          participationId
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateModificationRequest = /* GraphQL */ `
  mutation UpdateModificationRequest(
    $input: UpdateModificationRequestInput!
    $condition: ModelModificationRequestConditionInput
  ) {
    updateModificationRequest(input: $input, condition: $condition) {
      id
      eventId
      requestId
      type
      requestStatus
      paymentId
      participationId
      isDeleted
      createdAt
      updatedAt
      requestedBy {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
      participationRequest {
        id
        eventId
        familyName
        givenName
        email
        status
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        cluster
        isDeleted
        createdAt
        updatedAt
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        participationSubject {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        modificationRequest {
          id
          eventId
          requestId
          type
          requestStatus
          paymentId
          participationId
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteModificationRequest = /* GraphQL */ `
  mutation DeleteModificationRequest(
    $input: DeleteModificationRequestInput!
    $condition: ModelModificationRequestConditionInput
  ) {
    deleteModificationRequest(input: $input, condition: $condition) {
      id
      eventId
      requestId
      type
      requestStatus
      paymentId
      participationId
      isDeleted
      createdAt
      updatedAt
      requestedBy {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
      participationRequest {
        id
        eventId
        familyName
        givenName
        email
        status
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        cluster
        isDeleted
        createdAt
        updatedAt
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        participationSubject {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        modificationRequest {
          id
          eventId
          requestId
          type
          requestStatus
          paymentId
          participationId
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createParticipationConflictRequest = /* GraphQL */ `
  mutation CreateParticipationConflictRequest(
    $input: CreateParticipationConflictRequestInput!
    $condition: ModelParticipationConflictRequestConditionInput
  ) {
    createParticipationConflictRequest(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      participationSubject {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      participationRequest {
        id
        eventId
        familyName
        givenName
        email
        status
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        cluster
        isDeleted
        createdAt
        updatedAt
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        participationSubject {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        modificationRequest {
          id
          eventId
          requestId
          type
          requestStatus
          paymentId
          participationId
          isDeleted
          createdAt
          updatedAt
        }
      }
      participationConflict {
        id
        eventId
        requestId
        type
        requestStatus
        isDeleted
        createdAt
        updatedAt
        conflictResult {
          nextToken
        }
        participationConflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const updateParticipationConflictRequest = /* GraphQL */ `
  mutation UpdateParticipationConflictRequest(
    $input: UpdateParticipationConflictRequestInput!
    $condition: ModelParticipationConflictRequestConditionInput
  ) {
    updateParticipationConflictRequest(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      participationSubject {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      participationRequest {
        id
        eventId
        familyName
        givenName
        email
        status
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        cluster
        isDeleted
        createdAt
        updatedAt
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        participationSubject {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        modificationRequest {
          id
          eventId
          requestId
          type
          requestStatus
          paymentId
          participationId
          isDeleted
          createdAt
          updatedAt
        }
      }
      participationConflict {
        id
        eventId
        requestId
        type
        requestStatus
        isDeleted
        createdAt
        updatedAt
        conflictResult {
          nextToken
        }
        participationConflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const deleteParticipationConflictRequest = /* GraphQL */ `
  mutation DeleteParticipationConflictRequest(
    $input: DeleteParticipationConflictRequestInput!
    $condition: ModelParticipationConflictRequestConditionInput
  ) {
    deleteParticipationConflictRequest(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      participationSubject {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      participationRequest {
        id
        eventId
        familyName
        givenName
        email
        status
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        cluster
        isDeleted
        createdAt
        updatedAt
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        participationSubject {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        modificationRequest {
          id
          eventId
          requestId
          type
          requestStatus
          paymentId
          participationId
          isDeleted
          createdAt
          updatedAt
        }
      }
      participationConflict {
        id
        eventId
        requestId
        type
        requestStatus
        isDeleted
        createdAt
        updatedAt
        conflictResult {
          nextToken
        }
        participationConflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const createParticipationConflict = /* GraphQL */ `
  mutation CreateParticipationConflict(
    $input: CreateParticipationConflictInput!
    $condition: ModelParticipationConflictConditionInput
  ) {
    createParticipationConflict(input: $input, condition: $condition) {
      id
      eventId
      requestId
      type
      requestStatus
      isDeleted
      createdAt
      updatedAt
      conflictResult {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      participationConflictRequest {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateParticipationConflict = /* GraphQL */ `
  mutation UpdateParticipationConflict(
    $input: UpdateParticipationConflictInput!
    $condition: ModelParticipationConflictConditionInput
  ) {
    updateParticipationConflict(input: $input, condition: $condition) {
      id
      eventId
      requestId
      type
      requestStatus
      isDeleted
      createdAt
      updatedAt
      conflictResult {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      participationConflictRequest {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteParticipationConflict = /* GraphQL */ `
  mutation DeleteParticipationConflict(
    $input: DeleteParticipationConflictInput!
    $condition: ModelParticipationConflictConditionInput
  ) {
    deleteParticipationConflict(input: $input, condition: $condition) {
      id
      eventId
      requestId
      type
      requestStatus
      isDeleted
      createdAt
      updatedAt
      conflictResult {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      participationConflictRequest {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
