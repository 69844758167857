import { aws } from '@aim/common';
const apiName = 'aimConflictHandler';

export const callLambdaConflictsOnCreate = async (eventId) => {
  const input = { body: { eventId } };
  const path = '/aimConflictHandler';
  return await aws.standardAPI.post(apiName, path, input);
};

export const callLambdaConflictsOnUpdate = async (participationId) => {
  const input = { body: { participationId } };
  const path = '/aimConflictHandlerForUpdatedParticipation';
  return await aws.standardAPI.post(apiName, path, input);
};
