export const init = (API, { queries, mutations }) => {
  const mutate = async (mutationName, input, tag) => {
    const { data } = await API.graphql({
      query: mutations[mutationName],
      variables: { input },
    }).catch((e) => console.log(`ERROR> [mutate|event|${tag}]`, e));

    return data[mutationName];
  };

  const query = async (queryName, id, tag) => {
    const { data } = await API.graphql({
      query: queries[queryName],
      variables: { id },
    }).catch((e) => console.log(`ERROR> [query|event|${tag}]`, e));

    return data[queryName];
  };

  const list = async (queryName, filter, tag) => {
    const { data } = await API.graphql({
      query: queries[queryName],
      variables: { filter },
    }).catch((e) => console.log(`ERROR> [event|event|${tag}]`, e));

    return data[queryName];
  };

  return {
    get: async (id) => {
      const res = await query('getEvent', id, 'get');
      return res;
    },
  };
};
