import appState from './appState';
import aws from './aws';
import axios from 'axios';

const languagesCodes = { english: 'en', italian: 'it' };
const getLanguage = () => {
  const eventInfo = appState.eventInfo.getValue();
  const language = eventInfo.language?.name || eventInfo.language;
  const lang = Object.values(languagesCodes).includes(language)
    ? language
    : languagesCodes[language] || appState.locale.getValue() || 'en';
  console.log('lang ', lang);
  return lang;
};

const getGeoDbCountriesByInput = async (input) => {
  const lang = getLanguage();

  const params = new URLSearchParams({
    namePrefix: input,
    languageCode: lang,
    limit: 1000,
    sort: 'name',
  }).toString();

  const res = await aws.standardAPI.get(
    'aimlambdaproxy',
    `/geodb/v1/geo/countries?${params}`
  );
  console.log('res ', res);

  return res?.data?.map((c) => ({
    ...c,
    label: c.name,
    value: c.name,
    id: c.wikiDataId,
  }));
};

const listCountries = async () => {
  const lang = getLanguage();

  const params = new URLSearchParams({
    languageCode: lang,
    limit: 1000,
    sort: 'name',
  }).toString();

  const res = await aws.standardAPI.get(
    'aimlambdaproxy',
    `/geodb/v1/geo/countries?${params}`
  );

  return res?.data?.map((c) => ({
    ...c,
    label: c.name,
    value: c.name,
    id: c.wikiDataId,
  }));
};

const getGeoDbCitiesByInput = async (input) => {
  const lang = getLanguage();
  const params = new URLSearchParams({
    types: 'CITY',
    namePrefix: input,
    languageCode: lang,
    limit: 100,
    sort: '-population',
  }).toString();
  const res = await aws.standardAPI.get(
    'aimlambdaproxy',
    `/geodb/v1/geo/places?${params}`
  );
  const nextResults = [
    ...res.data.filter(
      (c) => c.name.trim().toLowerCase() === input.trim().toLowerCase()
    ),
    ...res.data.filter(
      (c) => c.name.trim().toLowerCase() !== input?.trim()?.toLowerCase()
    ),
  ];
  console.log('nextResults ', nextResults);

  return nextResults?.map((c) => ({
    // label: `${c.name} - ${c.region}`,
    ...c,
    label: c.name,
    value: c.name,
    id: c.wikiDataId,
  }));
};

const getGeoDbRegionsByInput = async (countryId, input) => {
  const lang = getLanguage();
  const res = await axios.get(
    `https://geodb-free-service.wirefreethought.com/v1/geo/countries/${countryId}/regions?namePrefix=${input}&languageCode=${lang}`
  );
  console.log('res ', res);

  await aws.standardAPI.get(
    'aimlambdaproxy',
    `/geodb/v1/geo/countries/${countryId}/regions?namePrefix=${input}&languageCode=${lang}`
  );
  console.log('res ', res);

  return res?.data?.map((r) => ({
    ...r,
    label: r.name,
    value: r.name,
    id: r.wikiDataId,
  }));
};

export default {
  getGeoDbCountriesByInput,
  getGeoDbCitiesByInput,
  getGeoDbRegionsByInput,
  listCountries,
};
