import {
  Amplify,
  Auth,
  API,
  Hub,
  Storage,
  // graphqlOperation,
} from 'aws-amplify';
import { navigateToUrl } from 'single-spa';

const AWS = require('aws-sdk');
import { toBase64 } from '@aws-sdk/util-base64-browser';

import {
  KmsKeyringBrowser,
  KMS,
  getClient,
  buildClient,
  CommitmentPolicy,
} from '@aws-crypto/client-browser';

import { upgradeApi } from './limitless-appsync';

import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';

import awsmobile from './aws-exports';

import * as User from './user/User';
import * as Participation from './event/Participation';
import * as Event from './event/Event';
import * as ConflictHelper from './event/ConflictHelper';

import * as customQueries from './customQueries';

import appState from './appState';
import amplifyMetaJson from '../amplify/backend/amplify-meta.json';

Amplify.configure(awsmobile);
Auth.configure(awsmobile);
API.configure(awsmobile);
Storage.configure(awsmobile);

const region = awsmobile.aws_appsync_region;
const accessKeyId = process.env.ACCESS_KEY_ID;
const secretAccessKey = process.env.SECRET_ACCESS_KEY;
const apiVersion = { s3: '2006-03-01' };
const { encrypt } = buildClient(
  CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT
);

AWS.config.update({
  region,
  accessKeyId,
  secretAccessKey,
});

// var s3 = new AWS.S3();

// const putObjectWrapper = (params) => {
//   return new Promise((resolve, reject) => {
//     s3.putObject(params, function (err, result) {
//       if (err) {
//         reject(err);
//       }
//       if (result) {
//         resolve(result);
//       }
//     });
//   });
// };

// const putObject = async ({ key, file, isPublic = false }) => {
//   var params = {
//     // ACL,
//     Tagging: isPublic ? 'publicFile=yes' : '',
//     Body: file,
//     Bucket: awsmobile.aws_user_files_s3_bucket,
//     Key: `public/${key}`,
//   };

//   const r = await putObjectWrapper(params);
//   return r;
// };

const getS3ObjectUrl = (key) => {
  const innerKey = `public/${key}`;
  return `https://${awsmobile.aws_user_files_s3_bucket}.s3-${awsmobile.aws_project_region}.amazonaws.com/${innerKey}`;
};

const getKeyFromS3ObjectUrl = (url) => {
  const splitted = url.split('public/');
  return splitted[1];
};

const signOutFromWebsite = async (eventId) => {
  await Auth.signOut();
  appState.user.next(null);
  navigateToUrl(`/events/${eventId}/login`);
};

export const getCognitoUser = () =>
  new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(resolve)
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });

export const getAwsUser = () =>
  new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(async (user) => {
        const UserPoolId = awsmobile.aws_user_pools_id;
        const cognito = new AWS.CognitoIdentityServiceProvider();
        const result = await cognito
          .adminListGroupsForUser({
            UserPoolId,
            Username: user.username,
            Limit: 10,
          })
          .promise();

        resolve({ ...user, groups: result.Groups.map((g) => g.GroupName) });
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });

export const getUserByMail = async (email) => {
  const res = await upgradeApi(API).graphql({
    query: customQueries.getUsersByMail,
    variables: { email: email },
  });
  if (res.data.listUsers.items.length > 0) return res.data.listUsers.items[0];
  else return undefined;
};

export const getParticipationsByMail = async (email) => {
  const res = await upgradeApi(API).graphql({
    query: customQueries.getParticipationsByMail,
    variables: { email: email },
  });
  if (res.data.listParticipations.items.length > 0)
    return res.data.listParticipations.items[0];
  else return undefined;
};

export const getUserByCognitoUserId = async (cognitoUserId) => {
  try {
    if (!cognitoUserId) {
      return null;
    } else {
      const res = await upgradeApi(API).graphql({
        query: customQueries.getUsersByCognitoUserId,
        variables: { cognitoUserId },
      });

      return res.data.usersByCognitoId.items.length
        ? res.data.usersByCognitoId.items[0]
        : null;
    }
  } catch (error) {
    console.log('getUserByCognitoUserId - error query : ', error);
  }
};

export const getDbUser = (appName) =>
  new Promise((resolve, reject) => {
    getAwsUser().then((loggedUser) => {
      if (!loggedUser?.attributes?.sub) {
        reject('no logged user');
      }
      getUserByCognitoUserId(loggedUser?.attributes?.sub).then((user) => {
        const storageUserString = localStorage.getItem(
          `impersonate-user-${appName}`
        );
        if (storageUserString) {
          appState.user.next(JSON.parse(storageUserString));
        } else {
          appState.user.next({
            ...user,
            awsUser: loggedUser,
          });
        }
        resolve({
          ...user,
          awsUser: loggedUser,
        });
      });
    });
  });

export const signOut = async () => {
  try {
    await Auth.signOut();
    localStorage.removeItem('impersonate-user');
    appState.user.next(null);
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

const createCognitoUser = async ({ username, email, userType, eventId }) =>
  await user.createNewUserInCognito({
    username,
    email,
    userType,
    eventId,
  });

const resetUserTemporaryPassword = async ({ username }) =>
  await user.resendTemporaryPassword({
    username,
  });

export const createNewUser = async (input, temporaryPassword) => {
  const cognitoUser = await createCognitoUser({
    username: input.username,
    email: input.email,
    temporaryPassword,
    userType: input.cognitoGrupName,
  });
  const sub = cognitoUser.Attributes.find((x) => x.Name === 'sub');

  await createNewUserInDb({
    cognitoUserId: sub.Value,
    email: input.email,
    givenName: input.givenName,
    familyName: input.familyName,
    type: input.type,
  });
};

export const adminUpdateCognitoUserAttributes = async (
  username,
  UserAttributes
) => {
  const UserPoolId = awsmobile.aws_user_pools_id;

  const cognito = new AWS.CognitoIdentityServiceProvider();
  const result = await cognito
    .adminUpdateUserAttributes({
      UserPoolId,
      Username: username,
      UserAttributes,
    })
    .promise();

  //va a scrivere anche su dynamo db

  return result;
};

export const createNewUserInDb = async (input) => {
  const { data } = await upgradeApi(API).graphql({
    query: mutations.createUser,
    variables: { input },
  });
  return data.createUser;
};

export const getCognitoUserByUsername = (username) =>
  new Promise((resolve, reject) => {
    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.adminGetUser(
      {
        Username: username,
        UserPoolId: awsmobile.aws_user_pools_id,
      },
      (err, data) => {
        if (err) {
          reject('user not found');
          return;
        }
        resolve(data);
      }
    );
  });

export const updateUser = async (user) => {
  const { data } = await upgradeApi(API).graphql({
    query: mutations.updateUser,
    variables: { input: user },
  });
  return data.updateUser;
};

export const adminDeleteCognitoUser = async (username) => {
  const region = awsmobile.aws_appsync_region;
  const UserPoolId = awsmobile.aws_user_pools_id;

  // AWS.config.update({
  //   region,
  // });

  const cognito = new AWS.CognitoIdentityServiceProvider();
  const result = await cognito
    .adminDeleteUser({
      UserPoolId,
      Username: username,
    })
    .promise();

  return result;
};

const encryptPassword = async (password) => {
  const generatorKeyId =
    amplifyMetaJson.custom.aimPasswordKmsKey.output.AliasName;
  const keyIds = [amplifyMetaJson.custom.aimPasswordKmsKey.output.Arn];
  const accessKeyId = process.env.ACCESS_KEY_ID;
  const secretAccessKey = process.env.SECRET_ACCESS_KEY;

  const clientProvider = getClient(KMS, {
    credentials: {
      accessKeyId,
      secretAccessKey,
    },
  });

  const keyring = new KmsKeyringBrowser({
    clientProvider,
    generatorKeyId,
    keyIds,
  });

  // const context = {
  //   stage: 'demo',
  //   purpose: 'simple demonstration app',
  //   origin: 'us-west-2',
  // };cd te

  const { result } = await encrypt(keyring, new TextEncoder().encode(password));
  const resultBase64 = toBase64(result);
  return resultBase64;
};

const sendMail = async (params) => {
  const apiName = 'apiSendMail';
  const path = '/sendMail';

  return await API.post(apiName, path, params);
};

const user = User.init(AWS, API, awsmobile.aws_user_pools_id);
const participation = Participation.init(upgradeApi(API));
const event = Event.init(API, { queries, mutations });

export default {
  API: upgradeApi(API),
  standardAPI: API,
  Auth,
  Hub,
  Storage,
  s3: {
    // putObject,
    getS3ObjectUrl,
    getKeyFromS3ObjectUrl,
  },
  participation,
  event,
  ConflictHelper,
  sendMail,
  getUserByMail,
  getParticipationsByMail,
  signOutFromWebsite,
  getCognitoUser,
  getAwsUser,
  getUserByCognitoUserId,
  getDbUser,
  signOut,
  createCognitoUser,
  createNewUser,
  resetUserTemporaryPassword,
  getCognitoUserByUsername,
  adminUpdateCognitoUserAttributes,
  updateUser,
  adminDeleteCognitoUser,
  encryptPassword,
};
