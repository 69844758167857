import gql from 'graphql-tag';
import appState from './appState';
const graphql = require('graphql');
const { print } = graphql;

const createGenericString = /* GraphQL */ `
  mutation CreateGenericString(
    $input: CreateGenericStringInput!
    $condition: ModelGenericStringConditionInput
  ) {
    createGenericString(input: $input, condition: $condition) {
      id
    }
  }
`;

const limitArgument = {
  kind: 'Argument',
  name: {
    kind: 'Name',
    value: 'limit',
  },
  value: {
    kind: 'IntValue',
    value: '10000',
  },
};

const nextTokenField = {
  kind: 'Field',
  name: {
    kind: 'Name',
    value: 'nextToken',
  },
  arguments: [],
  directives: [],
};

const makeNextToken = (value) => ({
  kind: 'Argument',
  name: {
    kind: 'Name',
    value: 'nextToken',
  },
  value: {
    kind: 'StringValue',
    value,
    block: false,
  },
});

const graphqlWrapperAndLogIfIsMutation = async ({
  API,
  queryName,
  isMutation,
  ...rest
}) => {
  const res = await API.graphql(rest);
  if (isMutation) {
    try {
      console.log('mutation', rest);
      const user = appState.user.getValue();

      console.log('user', user);
      await API.graphql({
        query: createGenericString,
        variables: {
          input: {
            userId:
              user?.awsUser?.username ||
              user?.userAndParticipation?.participation?.username,
            userEmail:
              user?.email || user?.userAndParticipation?.participation?.email,
            userGivenName:
              user?.givenName ||
              user?.userAndParticipation?.participation?.givenName,
            userFamilyName:
              user?.familyName ||
              user?.userAndParticipation?.participation?.familyName,
            queryName: queryName,
            query: rest.query,
            variables: JSON.stringify(rest.variables),
            response: JSON.stringify(res),
            pageUrl: window.location.href,
            //PER ORA NON MESSO CHE POTREMMO NON AVERE I DATI A FRONT
            //eventId: user?.userAndParticipation?.participation?.eventId,
          },
        },
      });
    } catch (e) {
      console.log('error', e);
    }
  }
  return res;
};

const isLimitArgumentAlreadyPresent = (args) =>
  args.find((arg) => arg?.name?.value === 'limit');

const runner = (selection) => {
  if (selection.selectionSet && hasItems(selection.selectionSet.selections)) {
    selection.selectionSet.selections = addNextTokenField(
      selection.selectionSet.selections
    );
    if (!isLimitArgumentAlreadyPresent(selection.arguments)) {
      selection.arguments = [...selection.arguments, limitArgument];
    }
  }

  if (selection.selectionSet) {
    selection.selectionSet.selections = selection.selectionSet.selections.map(
      runner
    );
  }

  return selection;
};

const addNextTokenField = (selections) => {
  const nextToken = selections.find((a) => a.name.value === 'nextToken');
  if (nextToken) return selections;
  return [...selections, nextTokenField];
};

const hasItems = (selections) =>
  selections.find((s) => s.name.value === 'items');

const getCurrentLevelData = (data, kp) => {
  let currentLevelData = data;
  if (kp.length === 1) {
    return data;
  }
  for (let i = 1; i < kp.length - 1; i++) {
    currentLevelData = currentLevelData
      ? currentLevelData[kp[i].key]
      : undefined;
  }
  return currentLevelData;
};

const getNextSelectionData = (q, kp) => {
  let nextSelection;

  for (let i = 0; i < kp.length; i++) {
    if (i === 0) {
      nextSelection = q.definitions[kp[i].pos];
    } else if (kp[i].pos !== -1) {
      nextSelection = nextSelection?.selectionSet?.selections[kp[i].pos];
    }
  }

  return nextSelection;
};

const getAllNextToken = async (q, variables, kp, inputData, API, rest) => {
  let currentLevelData = getCurrentLevelData(inputData, kp);
  if (currentLevelData instanceof Array) {
    Promise.all(
      currentLevelData.map(async (cld, idx) => {
        const customKp = [...kp];
        const last = customKp.pop();
        return await getAllNextToken(
          q,
          [...customKp, { key: idx, pos: -1 }, last],
          inputData,
          API,
          rest
        );
      })
    );
  } else {
    let nextSelection = getNextSelectionData(q, kp);
    if (!nextSelection || !currentLevelData || kp.some((x) => x.pos === -1)) {
      return;
    }
    while (currentLevelData[nextSelection.name.value]?.nextToken) {
      let nextTokenArgument = nextSelection.arguments.find(
        (s) => s.name.value === 'nextToken'
      );
      if (nextTokenArgument) {
        nextTokenArgument.value.value =
          currentLevelData[nextSelection.name.value].nextToken;
      } else {
        nextSelection.arguments.push(
          makeNextToken(currentLevelData[nextSelection.name.value].nextToken)
        );
      }
      let res = {};
      try {
        res = await API.graphql({
          query: print(q),
          variables,
          ...rest,
        });
      } catch (nextRes) {
        if (
          nextRes?.errors?.length === 1 &&
          nextRes?.errors?.[0]?.errorType === 'ResolverExecutionLimitReached'
        ) {
          res = nextRes;
        } else {
          throw nextRes;
        }
      }
      const newCurrentData = getCurrentLevelData(res.data, kp);
      currentLevelData[nextSelection.name.value].items = [
        ...currentLevelData[nextSelection.name.value].items,
        ...newCurrentData[nextSelection.name.value].items,
      ];
      currentLevelData[nextSelection.name.value].nextToken =
        newCurrentData[nextSelection.name.value].nextToken;
    }

    nextSelection.arguments = nextSelection?.arguments?.filter(
      (s) => s.name.value !== 'nextToken'
    );
  }
};

const singleQueryDefinitionExecution = async (
  q,
  variables,
  selection,
  data,
  kp,
  API,
  rest
) => {
  await Promise.all(
    selection.selectionSet?.selections.map(async (currentSelection, idx) => {
      return await singleQueryDefinitionExecution(
        q,
        variables,
        currentSelection,
        data,
        [...kp, { key: currentSelection.name.value, pos: idx }],
        API,
        rest
      );
    }) || []
  );

  await getAllNextToken(q, variables, kp, data, API, rest);
};

/**
 * @desc Recursively fetch all items in a list query using nextToken, this behaviour works only at first list of the tree
 * @param {Object} query The query object from cda-graphql in use.
 * @param {Object} variables The variables to pass to query.
 * @returns {Object} aws query-like result object.
 */
const fetchItemsNextToken = async (
  { query, variables, skipAppSyncError = false, ...rest },
  API
) => {
  // console.log('query', query);
  const q = gql`
    ${query}
  `;

  q.definitions[0].selectionSet.selections = q.definitions[0].selectionSet.selections.map(
    runner
  );
  // se abbiamo queries e mutations insieme non funzionerà mai
  const isMutation = q.definitions[0].operation === 'mutation';
  const queryName = q.definitions[0].name.value;
  let res = {};
  try {
    res = await graphqlWrapperAndLogIfIsMutation({
      query: print(q),
      variables,
      ...rest,
      queryName,
      isMutation,
      API,
    });
  } catch (nextRes) {
    if (
      nextRes?.errors?.length === 1 &&
      nextRes?.errors?.[0]?.errorType === 'ResolverExecutionLimitReached'
    ) {
      res = nextRes;
    } else {
      if (
        nextRes?.errors?.length &&
        // to prevent message for develop errors
        !window.location.href.startsWith('http://localhost') &&
        !skipAppSyncError
      ) {
        const {
          awsUser,
          userAndParticipation,
          ...usr
        } = appState.user.getValue();
        console.log('usr', usr);
        await API.post('aimlambdaproxy', '/admin/appsync-error', {
          body: {
            userInfo: {
              participation: userAndParticipation?.participation,
              user: usr,
              groups: awsUser?.groups,
            },
            pageUrl: window.location.href,
            appsyncResponse: nextRes,
            query,
            variables,
          },
        });
      }
      throw nextRes;
    }
  }

  let skip = false;
  for (const def of q.definitions) {
    if (def.operation !== 'query') {
      skip = true;
      break;
    }
  }

  if (res.data && !skip) {
    //da validare se ho que query insieme me le fa in parallelo??
    await Promise.all(
      q.definitions.map(async (definition, idx) => {
        const kp = [{ key: definition.name.value, pos: idx }];
        return await singleQueryDefinitionExecution(
          q,
          variables,
          definition,
          res.data,
          kp,
          API,
          rest
        );
      }) || []
    );
  }

  return res;
};

export const upgradeApi = (API) => {
  return {
    ...API,
    graphql: async (args, limit = 10000) => {
      args.variables = args.variables
        ? args.variables.limit
          ? args.variables
          : { ...args.variables, limit }
        : { limit };
      return await fetchItemsNextToken(args, API);
    },
  };
};
