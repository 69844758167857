import * as customQueries from '../customQueries';

export const init = (AWS, API, UserPoolId) => ({
  createNewUserInDb: async (input) => {
    const { data } = await API.graphql({
      query: customQueries.createUser,
      variables: { input },
    });
    return data.createUser;
  },
  createNewUserInCognito: async ({
    username,
    email,
    userType = 'customer',
    eventId,
  }) => {
    const cognito = new AWS.CognitoIdentityServiceProvider();
    const result = await cognito
      .adminCreateUser({
        UserPoolId,
        Username: username,
        UserAttributes: [
          {
            Name: 'custom:type',
            Value: userType || 'customer',
          },
          ...(eventId
            ? [
                {
                  Name: 'custom:eventId',
                  Value: eventId,
                },
              ]
            : []),
          {
            Name: 'email',
            Value: email?.trim()?.toLowerCase(),
          },
          {
            Name: 'email_verified',
            Value: 'true',
          },
        ],
      })
      .promise();

    const params = {
      GroupName: userType,
      UserPoolId: UserPoolId,
      Username: username,
    };

    await cognito.adminAddUserToGroup(params).promise();

    return result.User;
  },
  resendTemporaryPassword: async ({
    username,
    // userType = 'customer',
    // loginPath,
  }) => {
    const cognito = new AWS.CognitoIdentityServiceProvider();
    const result = await cognito
      .adminCreateUser({
        UserPoolId,
        Username: username,
        MessageAction: 'RESEND',
        UserAttributes: [
          // // {
          // //   Name: 'custom:type',
          // //   Value: userType || 'customer',
          // // },
          // // {
          // //   Name: 'custom:metadata',
          // //   Value: loginPath ? loginPath : `${window.location.origin}/login`,
          // // },
          // {
          //   Name: 'email',
          //   Value: email?.toLowerCase(),
          // },
          // // {
          // //   Name: 'email_verified',
          // //   Value: 'true',
          // // },
        ],
      })
      .promise();

    return result.User;
  },
  // getUserById: async (id) => {
  //   try {
  //     const { data } = await API.graphql({
  //       query: queries.getUser,
  //       variables: { id },
  //     });

  //     return data.getUser;
  //   } catch (error) {
  //     console.log('getUserById - error query : ', error);
  //   }
  // },
  getUserByMail: async (email) => {
    const res = await API.graphql({
      query: customQueries.getUsersByMail,
      variables: { email },
    });
    // console.log(res.data.listUsers.items);
    if (res.data.listUsers.items.length > 0) return res.data.listUsers.items[0];
    else return undefined;
  },
  updateUser: async (user) => {
    const { data } = await API.graphql({
      query: customQueries.updateUser,
      variables: { input: user },
    });
    return data.updateUser;
  },
});
