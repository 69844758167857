/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccountingTypology = /* GraphQL */ `
  query GetAccountingTypology($id: ID!) {
    getAccountingTypology(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listAccountingTypologys = /* GraphQL */ `
  query ListAccountingTypologys(
    $filter: ModelAccountingTypologyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountingTypologys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLanguage = /* GraphQL */ `
  query GetLanguage($id: ID!) {
    getLanguage(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listLanguages = /* GraphQL */ `
  query ListLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      givenName
      familyName
      role
      email
      createdAt
      updatedAt
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        givenName
        familyName
        role
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAuthRead = /* GraphQL */ `
  query GetAuthRead($id: ID!) {
    getAuthRead(id: $id) {
      id
      event
      participation
      abstractService
      createdAt
      updatedAt
    }
  }
`;
export const listAuthReads = /* GraphQL */ `
  query ListAuthReads(
    $filter: ModelAuthReadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthReads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event
        participation
        abstractService
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAuthWrite = /* GraphQL */ `
  query GetAuthWrite($id: ID!) {
    getAuthWrite(id: $id) {
      id
      event
      participation
      abstractService
      createdAt
      updatedAt
    }
  }
`;
export const listAuthWrites = /* GraphQL */ `
  query ListAuthWrites(
    $filter: ModelAuthWriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthWrites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event
        participation
        abstractService
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventService = /* GraphQL */ `
  query GetEventService($id: ID!) {
    getEventService(id: $id) {
      id
      serviceName
      createdAt
      updatedAt
      customFields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listEventServices = /* GraphQL */ `
  query ListEventServices(
    $filter: ModelEventServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceName
        createdAt
        updatedAt
        customFields {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getWebcastSpeech = /* GraphQL */ `
  query GetWebcastSpeech($id: ID!) {
    getWebcastSpeech(id: $id) {
      id
      speakerType
      createdAt
      updatedAt
      webcast {
        id
        webcastEventId
        title
        subtitle
        description
        image
        tags
        topic
        link
        videoId
        videoProvider
        clusters
        views
        webcastSpeeches {
          nextToken
        }
        isDeleted
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const listWebcastSpeechs = /* GraphQL */ `
  query ListWebcastSpeechs(
    $filter: ModelWebcastSpeechFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebcastSpeechs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        speakerType
        createdAt
        updatedAt
        webcast {
          id
          webcastEventId
          title
          subtitle
          description
          image
          tags
          topic
          link
          videoId
          videoProvider
          clusters
          views
          isDeleted
          createdAt
          updatedAt
        }
        participation {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserSettings = /* GraphQL */ `
  query GetUserSettings($id: ID!) {
    getUserSettings(id: $id) {
      id
      participationId
      isPublic
      showMyAgenda
      showBiography
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const listUserSettingss = /* GraphQL */ `
  query ListUserSettingss(
    $filter: ModelUserSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        participationId
        isPublic
        showMyAgenda
        showBiography
        createdAt
        updatedAt
        owner {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFavouriteAgendaSession = /* GraphQL */ `
  query GetFavouriteAgendaSession($id: ID!) {
    getFavouriteAgendaSession(id: $id) {
      id
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listFavouriteAgendaSessions = /* GraphQL */ `
  query ListFavouriteAgendaSessions(
    $filter: ModelFavouriteAgendaSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavouriteAgendaSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        session {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAbstractDocument = /* GraphQL */ `
  query GetAbstractDocument($id: ID!) {
    getAbstractDocument(id: $id) {
      id
      title
      status
      cod0
      cod1
      cod2
      url
      int
      disc
      preferredExposure
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      proposedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      acceptedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      processingStatus
      tags
      keywords
      reviews {
        items {
          id
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      isReadyToReview
      affiliations {
        items {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
          createdAt
          updatedAt
        }
        nextToken
      }
      authors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      presenter {
        id
        affiliation {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        fieldValues {
          nextToken
        }
      }
      images
      isDeleted
      isOwnerAPresentingAuthor
      appearances {
        items {
          id
          name
          start
          end
          instruction
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      abstractSections {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      attachments {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationFile {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAbstractDocuments = /* GraphQL */ `
  query ListAbstractDocuments(
    $filter: ModelAbstractDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAbstractDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        status
        cod0
        cod1
        cod2
        url
        int
        disc
        preferredExposure
        category {
          id
          name
          createdAt
          updatedAt
        }
        subCategory {
          id
          name
          createdAt
          updatedAt
        }
        proposedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        acceptedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        processingStatus
        tags
        keywords
        reviews {
          nextToken
        }
        isReadyToReview
        affiliations {
          nextToken
        }
        authors {
          nextToken
        }
        presenter {
          id
          createdAt
          updatedAt
        }
        images
        isDeleted
        isOwnerAPresentingAuthor
        appearances {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        abstractSections {
          nextToken
        }
        attachments {
          nextToken
        }
        presentationFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAbstractAuthor = /* GraphQL */ `
  query GetAbstractAuthor($id: ID!) {
    getAbstractAuthor(id: $id) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAbstractAuthors = /* GraphQL */ `
  query ListAbstractAuthors(
    $filter: ModelAbstractAuthorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAbstractAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        affiliation {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        fieldValues {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAbstractPresenter = /* GraphQL */ `
  query GetAbstractPresenter($id: ID!) {
    getAbstractPresenter(id: $id) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAbstractPresenters = /* GraphQL */ `
  query ListAbstractPresenters(
    $filter: ModelAbstractPresenterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAbstractPresenters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        affiliation {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        fieldValues {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAbstractAffiliation = /* GraphQL */ `
  query GetAbstractAffiliation($id: ID!) {
    getAbstractAffiliation(id: $id) {
      id
      affiliationNumber
      institution
      city
      country
      fiscalCode
      createdAt
      updatedAt
    }
  }
`;
export const listAbstractAffiliations = /* GraphQL */ `
  query ListAbstractAffiliations(
    $filter: ModelAbstractAffiliationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAbstractAffiliations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      status
      scores {
        items {
          id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewer {
        id
        favoriteCategory {
          id
          name
          createdAt
          updatedAt
        }
        favoriteSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        reviews {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      abstractDocument {
        id
        title
        status
        cod0
        cod1
        cod2
        url
        int
        disc
        preferredExposure
        category {
          id
          name
          createdAt
          updatedAt
        }
        subCategory {
          id
          name
          createdAt
          updatedAt
        }
        proposedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        acceptedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        processingStatus
        tags
        keywords
        reviews {
          nextToken
        }
        isReadyToReview
        affiliations {
          nextToken
        }
        authors {
          nextToken
        }
        presenter {
          id
          createdAt
          updatedAt
        }
        images
        isDeleted
        isOwnerAPresentingAuthor
        appearances {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        abstractSections {
          nextToken
        }
        attachments {
          nextToken
        }
        presentationFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        scores {
          nextToken
        }
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocument {
          id
          title
          status
          cod0
          cod1
          cod2
          url
          int
          disc
          preferredExposure
          processingStatus
          tags
          keywords
          isReadyToReview
          images
          isDeleted
          isOwnerAPresentingAuthor
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReviewScorePerCriteria = /* GraphQL */ `
  query GetReviewScorePerCriteria($id: ID!) {
    getReviewScorePerCriteria(id: $id) {
      id
      criteria {
        id
        name
        scores {
          nextToken
        }
        createdAt
        updatedAt
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      score {
        id
        label
        value
        createdAt
        updatedAt
      }
      note
      createdAt
      updatedAt
    }
  }
`;
export const listReviewScorePerCriterias = /* GraphQL */ `
  query ListReviewScorePerCriterias(
    $filter: ModelReviewScorePerCriteriaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewScorePerCriterias(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        criteria {
          id
          name
          createdAt
          updatedAt
        }
        score {
          id
          label
          value
          createdAt
          updatedAt
        }
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReviewer = /* GraphQL */ `
  query GetReviewer($id: ID!) {
    getReviewer(id: $id) {
      id
      favoriteCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      favoriteSubCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      reviews {
        items {
          id
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listReviewers = /* GraphQL */ `
  query ListReviewers(
    $filter: ModelReviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        favoriteCategory {
          id
          name
          createdAt
          updatedAt
        }
        favoriteSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        reviews {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      subCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPresentationTypology = /* GraphQL */ `
  query GetPresentationTypology($id: ID!) {
    getPresentationTypology(id: $id) {
      id
      name
      description
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationMode
      presentationRequired
      createdAt
      updatedAt
    }
  }
`;
export const listPresentationTypologys = /* GraphQL */ `
  query ListPresentationTypologys(
    $filter: ModelPresentationTypologyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPresentationTypologys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPresentationTypologyCategory = /* GraphQL */ `
  query GetPresentationTypologyCategory($id: ID!) {
    getPresentationTypologyCategory(id: $id) {
      id
      presentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPresentationTypologyCategorys = /* GraphQL */ `
  query ListPresentationTypologyCategorys(
    $filter: ModelPresentationTypologyCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPresentationTypologyCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        presentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        category {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReviewCriteria = /* GraphQL */ `
  query GetReviewCriteria($id: ID!) {
    getReviewCriteria(id: $id) {
      id
      name
      scores {
        items {
          id
          label
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listReviewCriterias = /* GraphQL */ `
  query ListReviewCriterias(
    $filter: ModelReviewCriteriaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewCriterias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        scores {
          nextToken
        }
        createdAt
        updatedAt
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getScore = /* GraphQL */ `
  query GetScore($id: ID!) {
    getScore(id: $id) {
      id
      label
      value
      createdAt
      updatedAt
    }
  }
`;
export const listScores = /* GraphQL */ `
  query ListScores(
    $filter: ModelScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlgorithm = /* GraphQL */ `
  query GetAlgorithm($id: ID!) {
    getAlgorithm(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listAlgorithms = /* GraphQL */ `
  query ListAlgorithms(
    $filter: ModelAlgorithmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlgorithms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSurveyInvitation = /* GraphQL */ `
  query GetSurveyInvitation($id: ID!) {
    getSurveyInvitation(id: $id) {
      id
      isCompleted
      createdAt
      updatedAt
      participant {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      survey {
        id
        title
        status
        sentAt
        model
        invitations {
          nextToken
        }
        responses {
          nextToken
        }
        surveyEventId
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listSurveyInvitations = /* GraphQL */ `
  query ListSurveyInvitations(
    $filter: ModelSurveyInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isCompleted
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        survey {
          id
          title
          status
          sentAt
          model
          surveyEventId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSurveyResponse = /* GraphQL */ `
  query GetSurveyResponse($id: ID!) {
    getSurveyResponse(id: $id) {
      id
      result
      createdAt
      updatedAt
      survey {
        id
        title
        status
        sentAt
        model
        invitations {
          nextToken
        }
        responses {
          nextToken
        }
        surveyEventId
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listSurveyResponses = /* GraphQL */ `
  query ListSurveyResponses(
    $filter: ModelSurveyResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        result
        createdAt
        updatedAt
        survey {
          id
          title
          status
          sentAt
          model
          surveyEventId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDateRange = /* GraphQL */ `
  query GetDateRange($id: ID!) {
    getDateRange(id: $id) {
      id
      start
      end
      brackets {
        items {
          id
          name
          start
          end
          priceOnAir
          priceOnSite
          discountCode
          discountedPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDateRanges = /* GraphQL */ `
  query ListDateRanges(
    $filter: ModelDateRangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDateRanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        start
        end
        brackets {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBracket = /* GraphQL */ `
  query GetBracket($id: ID!) {
    getBracket(id: $id) {
      id
      name
      start
      end
      priceOnAir
      priceOnSite
      discountCode
      discountedPrice
      createdAt
      updatedAt
    }
  }
`;
export const listBrackets = /* GraphQL */ `
  query ListBrackets(
    $filter: ModelBracketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrackets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        start
        end
        priceOnAir
        priceOnSite
        discountCode
        discountedPrice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIssue = /* GraphQL */ `
  query GetIssue($id: ID!) {
    getIssue(id: $id) {
      id
      issueName
      status
      rows {
        items {
          id
          date
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      operator {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
    }
  }
`;
export const listIssues = /* GraphQL */ `
  query ListIssues(
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIssues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        issueName
        status
        rows {
          nextToken
        }
        createdAt
        updatedAt
        operator {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getIssueRow = /* GraphQL */ `
  query GetIssueRow($id: ID!) {
    getIssueRow(id: $id) {
      id
      date
      description
      createdAt
      updatedAt
      operator {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
    }
  }
`;
export const listIssueRows = /* GraphQL */ `
  query ListIssueRows(
    $filter: ModelIssueRowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIssueRows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        description
        createdAt
        updatedAt
        operator {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFieldContext = /* GraphQL */ `
  query GetFieldContext($id: ID!) {
    getFieldContext(id: $id) {
      id
      position
      contextName
      isRequired
      isHidden
      createdAt
      updatedAt
    }
  }
`;
export const listFieldContexts = /* GraphQL */ `
  query ListFieldContexts(
    $filter: ModelFieldContextFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFieldContexts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        position
        contextName
        isRequired
        isHidden
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSponsorPriceRange = /* GraphQL */ `
  query GetSponsorPriceRange($id: ID!) {
    getSponsorPriceRange(id: $id) {
      id
      name
      description
      price
      start
      freeStaffMembers
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsors {
        items {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSponsorPriceRanges = /* GraphQL */ `
  query ListSponsorPriceRanges(
    $filter: ModelSponsorPriceRangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorPriceRanges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        price
        start
        freeStaffMembers
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsors {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBannerService = /* GraphQL */ `
  query GetBannerService($id: ID!) {
    getBannerService(id: $id) {
      id
      name
      description
      vatRate
      jsonDefinition
      bannerPages {
        items {
          id
          name
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBannerServices = /* GraphQL */ `
  query ListBannerServices(
    $filter: ModelBannerServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBannerServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        vatRate
        jsonDefinition
        bannerPages {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBannerPage = /* GraphQL */ `
  query GetBannerPage($id: ID!) {
    getBannerPage(id: $id) {
      id
      name
      image
      bannerService {
        id
        name
        description
        vatRate
        jsonDefinition
        bannerPages {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      bannerAreas {
        items {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBannerPages = /* GraphQL */ `
  query ListBannerPages(
    $filter: ModelBannerPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBannerPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        bannerAreas {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSymposiumService = /* GraphQL */ `
  query GetSymposiumService($id: ID!) {
    getSymposiumService(id: $id) {
      id
      name
      description
      vatRate
      deadline
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      symposiums {
        items {
          id
          price
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSymposiumServices = /* GraphQL */ `
  query ListSymposiumServices(
    $filter: ModelSymposiumServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSymposiumServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        vatRate
        deadline
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        symposiums {
          nextToken
        }
        documents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAgenda = /* GraphQL */ `
  query GetAgenda($id: ID!) {
    getAgenda(id: $id) {
      id
      tags
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sessions {
        items {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAgendas = /* GraphQL */ `
  query ListAgendas(
    $filter: ModelAgendaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgendas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tags
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAgendaSessionProvocateur = /* GraphQL */ `
  query GetAgendaSessionProvocateur($id: ID!) {
    getAgendaSessionProvocateur(id: $id) {
      id
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listAgendaSessionProvocateurs = /* GraphQL */ `
  query ListAgendaSessionProvocateurs(
    $filter: ModelAgendaSessionProvocateurFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgendaSessionProvocateurs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        participation {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        session {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAgendaSessionModerator = /* GraphQL */ `
  query GetAgendaSessionModerator($id: ID!) {
    getAgendaSessionModerator(id: $id) {
      id
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listAgendaSessionModerators = /* GraphQL */ `
  query ListAgendaSessionModerators(
    $filter: ModelAgendaSessionModeratorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgendaSessionModerators(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        participation {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        session {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getIntervention = /* GraphQL */ `
  query GetIntervention($id: ID!) {
    getIntervention(id: $id) {
      id
      name
      start
      end
      instruction
      abstract {
        id
        title
        status
        cod0
        cod1
        cod2
        url
        int
        disc
        preferredExposure
        category {
          id
          name
          createdAt
          updatedAt
        }
        subCategory {
          id
          name
          createdAt
          updatedAt
        }
        proposedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        acceptedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        processingStatus
        tags
        keywords
        reviews {
          nextToken
        }
        isReadyToReview
        affiliations {
          nextToken
        }
        authors {
          nextToken
        }
        presenter {
          id
          createdAt
          updatedAt
        }
        images
        isDeleted
        isOwnerAPresentingAuthor
        appearances {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        abstractSections {
          nextToken
        }
        attachments {
          nextToken
        }
        presentationFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      speaker {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      session {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listInterventions = /* GraphQL */ `
  query ListInterventions(
    $filter: ModelInterventionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterventions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        start
        end
        instruction
        abstract {
          id
          title
          status
          cod0
          cod1
          cod2
          url
          int
          disc
          preferredExposure
          processingStatus
          tags
          keywords
          isReadyToReview
          images
          isDeleted
          isOwnerAPresentingAuthor
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        speaker {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        session {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      name
      type
      capacity
      delay
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      agendaSessions {
        items {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        capacity
        delay
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        agendaSessions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSponsorWelcomePage = /* GraphQL */ `
  query GetSponsorWelcomePage($id: ID!) {
    getSponsorWelcomePage(id: $id) {
      id
      title
      subtitle
      content
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      cover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      gallery {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      document {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSponsorWelcomePages = /* GraphQL */ `
  query ListSponsorWelcomePages(
    $filter: ModelSponsorWelcomePageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorWelcomePages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        subtitle
        content
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        cover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        gallery {
          nextToken
        }
        document {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSponsorContact = /* GraphQL */ `
  query GetSponsorContact($id: ID!) {
    getSponsorContact(id: $id) {
      id
      name
      surname
      email
      phone
      facebook
      twitter
      linkedin
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
    }
  }
`;
export const listSponsorContacts = /* GraphQL */ `
  query ListSponsorContacts(
    $filter: ModelSponsorContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        email
        phone
        facebook
        twitter
        linkedin
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPublicPageService = /* GraphQL */ `
  query GetPublicPageService($id: ID!) {
    getPublicPageService(id: $id) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      netPrice
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      publicPages {
        items {
          id
          isPublic
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPublicPageServices = /* GraphQL */ `
  query ListPublicPageServices(
    $filter: ModelPublicPageServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicPageServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        netPrice
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        publicPages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAgendaSessionReaction = /* GraphQL */ `
  query GetAgendaSessionReaction($id: ID!) {
    getAgendaSessionReaction(id: $id) {
      id
      participationId
      agendaSessionId
      reaction
      timestamp
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      agendaSession {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listAgendaSessionReactions = /* GraphQL */ `
  query ListAgendaSessionReactions(
    $filter: ModelAgendaSessionReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgendaSessionReactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        participationId
        agendaSessionId
        reaction
        timestamp
        createdAt
        updatedAt
        participation {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        agendaSession {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBreakoutRoomService = /* GraphQL */ `
  query GetBreakoutRoomService($id: ID!) {
    getBreakoutRoomService(id: $id) {
      id
      name
      description
      price
      vatRate
      isSponsorEditable
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      maxAvailableHours
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      breakoutRooms {
        items {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBreakoutRoomServices = /* GraphQL */ `
  query ListBreakoutRoomServices(
    $filter: ModelBreakoutRoomServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreakoutRoomServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        price
        vatRate
        isSponsorEditable
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        maxAvailableHours
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        breakoutRooms {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPhysicalStandService = /* GraphQL */ `
  query GetPhysicalStandService($id: ID!) {
    getPhysicalStandService(id: $id) {
      id
      name
      description
      vatRate
      extraPriceDate
      extraPriceRate
      floors {
        items {
          id
          code
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPhysicalStandServices = /* GraphQL */ `
  query ListPhysicalStandServices(
    $filter: ModelPhysicalStandServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhysicalStandServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        vatRate
        extraPriceDate
        extraPriceRate
        floors {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPhysicalStandFloor = /* GraphQL */ `
  query GetPhysicalStandFloor($id: ID!) {
    getPhysicalStandFloor(id: $id) {
      id
      code
      name
      spaces {
        items {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandService {
        id
        name
        description
        vatRate
        extraPriceDate
        extraPriceRate
        floors {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPhysicalStandFloors = /* GraphQL */ `
  query ListPhysicalStandFloors(
    $filter: ModelPhysicalStandFloorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhysicalStandFloors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        spaces {
          nextToken
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhysicalStandSpace = /* GraphQL */ `
  query GetPhysicalStandSpace($id: ID!) {
    getPhysicalStandSpace(id: $id) {
      id
      netPrice
      name
      code
      noVat
      extraPriceDisabled
      note
      spaceLength
      spaceHeight
      surface
      floor {
        id
        code
        name
        spaces {
          nextToken
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      physicalStandSpacesItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      plantImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listPhysicalStandSpaces = /* GraphQL */ `
  query ListPhysicalStandSpaces(
    $filter: ModelPhysicalStandSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhysicalStandSpaces(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        netPrice
        name
        code
        noVat
        extraPriceDisabled
        note
        spaceLength
        spaceHeight
        surface
        floor {
          id
          code
          name
          createdAt
          updatedAt
        }
        physicalStandSpacesItems {
          nextToken
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        plantImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getOtherStandServicesService = /* GraphQL */ `
  query GetOtherStandServicesService($id: ID!) {
    getOtherStandServicesService(id: $id) {
      id
      name
      description
      itemCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listOtherStandServicesServices = /* GraphQL */ `
  query ListOtherStandServicesServices(
    $filter: ModelOtherStandServicesServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOtherStandServicesServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        itemCategories {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getItemCategory = /* GraphQL */ `
  query GetItemCategory($id: ID!) {
    getItemCategory(id: $id) {
      id
      name
      otherStandServicesService {
        id
        name
        description
        itemCategories {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      itemSubcategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listItemCategorys = /* GraphQL */ `
  query ListItemCategorys(
    $filter: ModelItemCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        itemSubcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItemSubcategory = /* GraphQL */ `
  query GetItemSubcategory($id: ID!) {
    getItemSubcategory(id: $id) {
      id
      name
      itemCategory {
        id
        name
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        itemSubcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      childItems {
        items {
          id
          name
          code
          netPrice
          vatRate
          type
          amountPerQuantityLabel
          note
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listItemSubcategorys = /* GraphQL */ `
  query ListItemSubcategorys(
    $filter: ModelItemSubcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemSubcategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        itemCategory {
          id
          name
          createdAt
          updatedAt
        }
        childItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      name
      code
      netPrice
      vatRate
      type
      amountPerQuantityLabel
      note
      description
      itemSubCategory {
        id
        name
        itemCategory {
          id
          name
          createdAt
          updatedAt
        }
        childItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      physicalStandSpaceItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        netPrice
        vatRate
        type
        amountPerQuantityLabel
        note
        description
        itemSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhysicalStandSpaceItems = /* GraphQL */ `
  query GetPhysicalStandSpaceItems($id: ID!) {
    getPhysicalStandSpaceItems(id: $id) {
      id
      quantity
      amountPerQuantity
      item {
        id
        name
        code
        netPrice
        vatRate
        type
        amountPerQuantityLabel
        note
        description
        itemSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      physicalStandSpace {
        id
        netPrice
        name
        code
        noVat
        extraPriceDisabled
        note
        spaceLength
        spaceHeight
        surface
        floor {
          id
          code
          name
          createdAt
          updatedAt
        }
        physicalStandSpacesItems {
          nextToken
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        plantImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listPhysicalStandSpaceItemss = /* GraphQL */ `
  query ListPhysicalStandSpaceItemss(
    $filter: ModelPhysicalStandSpaceItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhysicalStandSpaceItemss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quantity
        amountPerQuantity
        item {
          id
          name
          code
          netPrice
          vatRate
          type
          amountPerQuantityLabel
          note
          description
          createdAt
          updatedAt
        }
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVirtualStandService = /* GraphQL */ `
  query GetVirtualStandService($id: ID!) {
    getVirtualStandService(id: $id) {
      id
      name
      description
      vatRate
      virtualStands {
        items {
          id
          name
          isEnabled
          ranking
          netPrice
          specificationPost
          createdAt
          updatedAt
        }
        nextToken
      }
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listVirtualStandServices = /* GraphQL */ `
  query ListVirtualStandServices(
    $filter: ModelVirtualStandServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVirtualStandServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        vatRate
        virtualStands {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getVirtualStand = /* GraphQL */ `
  query GetVirtualStand($id: ID!) {
    getVirtualStand(id: $id) {
      id
      name
      virtualStandService {
        id
        name
        description
        vatRate
        virtualStands {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      isEnabled
      sponsorVirtualStand {
        items {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        nextToken
      }
      ranking
      netPrice
      specificationPost
      createdAt
      updatedAt
    }
  }
`;
export const listVirtualStands = /* GraphQL */ `
  query ListVirtualStands(
    $filter: ModelVirtualStandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVirtualStands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        isEnabled
        sponsorVirtualStand {
          nextToken
        }
        ranking
        netPrice
        specificationPost
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSponsorVirtualStand = /* GraphQL */ `
  query GetSponsorVirtualStand($id: ID!) {
    getSponsorVirtualStand(id: $id) {
      id
      virtualRoomLink
      virtualStand {
        id
        name
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        isEnabled
        sponsorVirtualStand {
          nextToken
        }
        ranking
        netPrice
        specificationPost
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listSponsorVirtualStands = /* GraphQL */ `
  query ListSponsorVirtualStands(
    $filter: ModelSponsorVirtualStandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorVirtualStands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        virtualRoomLink
        virtualStand {
          id
          name
          isEnabled
          ranking
          netPrice
          specificationPost
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSponsorStaffService = /* GraphQL */ `
  query GetSponsorStaffService($id: ID!) {
    getSponsorStaffService(id: $id) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsorStaffs {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSponsorStaffServices = /* GraphQL */ `
  query ListSponsorStaffServices(
    $filter: ModelSponsorStaffServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorStaffServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorStaffs {
          nextToken
        }
        documents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSponsorListService = /* GraphQL */ `
  query GetSponsorListService($id: ID!) {
    getSponsorListService(id: $id) {
      id
      name
      description
      vatRate
      isCreditCardEnabled
      isBankTransferEnabled
      isOnline
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      sponsorLists {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSponsorListServices = /* GraphQL */ `
  query ListSponsorListServices(
    $filter: ModelSponsorListServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorListServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorLists {
          nextToken
        }
        documents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getProfileCategory = /* GraphQL */ `
  query GetProfileCategory($id: ID!) {
    getProfileCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      profiles {
        items {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listProfileCategorys = /* GraphQL */ `
  query ListProfileCategorys(
    $filter: ModelProfileCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFee = /* GraphQL */ `
  query GetFee($id: ID!) {
    getFee(id: $id) {
      id
      feeEventId
      vatRate
      currency
      isPublished
      feeBracketModels {
        items {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      feeDateRanges {
        items {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listFees = /* GraphQL */ `
  query ListFees(
    $filter: ModelFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFeeBracket = /* GraphQL */ `
  query GetFeeBracket($id: ID!) {
    getFeeBracket(id: $id) {
      id
      feeBracketModel {
        id
        start
        end
        name
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBracket {
          nextToken
        }
        createdAt
        updatedAt
      }
      profileFeeBrackets {
        items {
          id
          priceOnAir
          priceOnSite
          discountPrice
          discountCode
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listFeeBrackets = /* GraphQL */ `
  query ListFeeBrackets(
    $filter: ModelFeeBracketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeeBrackets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        createdAt
        updatedAt
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFeeBracketModel = /* GraphQL */ `
  query GetFeeBracketModel($id: ID!) {
    getFeeBracketModel(id: $id) {
      id
      start
      end
      name
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      feeBracket {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFeeBracketModels = /* GraphQL */ `
  query ListFeeBracketModels(
    $filter: ModelFeeBracketModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeeBracketModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        start
        end
        name
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBracket {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfileFeeBracket = /* GraphQL */ `
  query GetProfileFeeBracket($id: ID!) {
    getProfileFeeBracket(id: $id) {
      id
      priceOnAir
      priceOnSite
      discountPrice
      discountCode
      isActive
      feeBracket {
        id
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        createdAt
        updatedAt
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
    }
  }
`;
export const listProfileFeeBrackets = /* GraphQL */ `
  query ListProfileFeeBrackets(
    $filter: ModelProfileFeeBracketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileFeeBrackets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priceOnAir
        priceOnSite
        discountPrice
        discountCode
        isActive
        feeBracket {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDeadline = /* GraphQL */ `
  query GetDeadline($type: String!, $externalId: ID!) {
    getDeadline(type: $type, externalId: $externalId) {
      type
      externalId
      value
      createdAt
      updatedAt
    }
  }
`;
export const listDeadlines = /* GraphQL */ `
  query ListDeadlines(
    $type: String
    $externalId: ModelIDKeyConditionInput
    $filter: ModelDeadlineFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDeadlines(
      type: $type
      externalId: $externalId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        type
        externalId
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      operators
      createdBy
      isOwnedByCongress
      name
      code
      organizer
      start
      end
      isOnAir
      venue
      country
      city
      initials
      invoicingInitials
      order
      division {
        id
        name
        createdAt
        updatedAt
      }
      industry {
        id
        name
        createdAt
        updatedAt
      }
      topic {
        id
        name
        createdAt
        updatedAt
      }
      accountingTypology {
        id
        name
        createdAt
        updatedAt
      }
      language {
        id
        name
        createdAt
        updatedAt
      }
      contacts {
        items {
          id
          givenName
          familyName
          role
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      services {
        items {
          id
          serviceName
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorWelcomePage {
        id
        title
        subtitle
        content
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        cover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        gallery {
          nextToken
        }
        document {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      bannerService {
        id
        name
        description
        vatRate
        jsonDefinition
        bannerPages {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      symposiumService {
        id
        name
        description
        vatRate
        deadline
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        symposiums {
          nextToken
        }
        documents {
          nextToken
        }
      }
      physicalStandService {
        id
        name
        description
        vatRate
        extraPriceDate
        extraPriceRate
        floors {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      publicPageService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        netPrice
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        publicPages {
          nextToken
        }
      }
      breakoutRoomService {
        id
        name
        description
        price
        vatRate
        isSponsorEditable
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        maxAvailableHours
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        breakoutRooms {
          nextToken
        }
      }
      otherStandServicesService {
        id
        name
        description
        itemCategories {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      virtualStandService {
        id
        name
        description
        vatRate
        virtualStands {
          nextToken
        }
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
      }
      sponsorStaffService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorStaffs {
          nextToken
        }
        documents {
          nextToken
        }
      }
      sponsorListService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorLists {
          nextToken
        }
        documents {
          nextToken
        }
      }
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      sponsorPriceRanges {
        items {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        nextToken
      }
      rooms {
        items {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        nextToken
      }
      agenda {
        id
        tags
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      sponsorServiceBookingDuration
      IBAN
      profileCategories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      mailUpListId
      createdAt
      updatedAt
      delegations {
        items {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      webcasts {
        items {
          id
          webcastEventId
          title
          subtitle
          description
          image
          tags
          topic
          link
          videoId
          videoProvider
          clusters
          views
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      abstractService {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      surveys {
        items {
          id
          title
          status
          sentAt
          model
          surveyEventId
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          quoteEventId
          profileName
          cluster
          currency
          createdAt
          updatedAt
        }
        nextToken
      }
      standardFields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      fields {
        items {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorLists {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorStaffs {
        items {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsors {
        items {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        nextToken
      }
      agencies {
        items {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      agencyService {
        id
        minParticipations
        instructions
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        cover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        gallery {
          nextToken
        }
        policies {
          nextToken
        }
      }
      privacyFile {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      profiles {
        items {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
      participationTypologies {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDelegation = /* GraphQL */ `
  query GetDelegation($id: ID!) {
    getDelegation(id: $id) {
      id
      name
      customer
      description
      closingDate
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      modificationRequests {
        items {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listDelegations = /* GraphQL */ `
  query ListDelegations(
    $filter: ModelDelegationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDelegations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        customer
        description
        closingDate
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        modificationRequests {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getWebcast = /* GraphQL */ `
  query GetWebcast($id: ID!) {
    getWebcast(id: $id) {
      id
      webcastEventId
      title
      subtitle
      description
      image
      tags
      topic
      link
      videoId
      videoProvider
      clusters
      views
      webcastSpeeches {
        items {
          id
          speakerType
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const listWebcasts = /* GraphQL */ `
  query ListWebcasts(
    $filter: ModelWebcastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebcasts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        webcastEventId
        title
        subtitle
        description
        image
        tags
        topic
        link
        videoId
        videoProvider
        clusters
        views
        webcastSpeeches {
          nextToken
        }
        isDeleted
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getParticipation = /* GraphQL */ `
  query GetParticipation($id: ID!) {
    getParticipation(id: $id) {
      id
      cognitoUserId
      isDeleted
      email
      givenName
      familyName
      businessName
      owner
      cluster
      isParticipant
      isSpeaker
      isReviewer
      isScientificCommittee
      paymentStatus
      registrationChannel
      isInvited
      status
      feeBracketModel {
        id
        start
        end
        name
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBracket {
          nextToken
        }
        createdAt
        updatedAt
      }
      participationEventId
      reviewer {
        id
        favoriteCategory {
          id
          name
          createdAt
          updatedAt
        }
        favoriteSubCategory {
          id
          name
          createdAt
          updatedAt
        }
        reviews {
          nextToken
        }
        createdAt
        updatedAt
        participant {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
      }
      abstractDocuments {
        items {
          id
          title
          status
          cod0
          cod1
          cod2
          url
          int
          disc
          preferredExposure
          processingStatus
          tags
          keywords
          isReadyToReview
          images
          isDeleted
          isOwnerAPresentingAuthor
          createdAt
          updatedAt
        }
        nextToken
      }
      webcastSpeeches {
        items {
          id
          speakerType
          createdAt
          updatedAt
        }
        nextToken
      }
      surveyInvitations {
        items {
          id
          isCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      issues {
        items {
          id
          issueName
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      registrationFee
      myAgenda {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      provocateurRole {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      moderatorRole {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      userSettings {
        id
        participationId
        isPublic
        showMyAgenda
        showBiography
        createdAt
        updatedAt
        owner {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      delegation {
        id
        name
        customer
        description
        closingDate
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        modificationRequests {
          nextToken
        }
      }
      userShowcase {
        id
        participationId
        businessPhone
        companyName
        workJob
        description
        birthDate
        phone
        contactEmail
        nationality
        address
        linkedin
        facebook
        twitter
        instagram
        createdAt
        updatedAt
        owner {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      user {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      publicFieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      sponsorStaff {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      agencyGroup {
        id
        isDeleted
        name
        isPrepaid
        client
        closingDate
        createdAt
        updatedAt
        participations {
          nextToken
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
      }
      agency {
        id
        isDeleted
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        agencyGroups {
          nextToken
        }
      }
      myBreakoutRoom {
        items {
          id
          state
          note
          isSelfProposed
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      typology {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
      participationHistorical {
        items {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      conflictRequest {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listParticipations = /* GraphQL */ `
  query ListParticipations(
    $filter: ModelParticipationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const participantByCognitoIdEventId = /* GraphQL */ `
  query ParticipantByCognitoIdEventId(
    $cognitoUserId: ID
    $participationEventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    participantByCognitoIdEventId(
      cognitoUserId: $cognitoUserId
      participationEventId: $participationEventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const participantByClusterEventId = /* GraphQL */ `
  query ParticipantByClusterEventId(
    $cluster: String
    $participationEventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    participantByClusterEventId(
      cluster: $cluster
      participationEventId: $participationEventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserShowcase = /* GraphQL */ `
  query GetUserShowcase($id: ID!) {
    getUserShowcase(id: $id) {
      id
      participationId
      businessPhone
      companyName
      workJob
      description
      birthDate
      phone
      contactEmail
      nationality
      address
      linkedin
      facebook
      twitter
      instagram
      createdAt
      updatedAt
      owner {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      profileImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      coverImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const listUserShowcases = /* GraphQL */ `
  query ListUserShowcases(
    $filter: ModelUserShowcaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserShowcases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        participationId
        businessPhone
        companyName
        workJob
        description
        birthDate
        phone
        contactEmail
        nationality
        address
        linkedin
        facebook
        twitter
        instagram
        createdAt
        updatedAt
        owner {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoUserId
      email
      givenName
      familyName
      businessName
      isAdmin
      type
      status
      nationality
      language
      birthDate
      fiscalCode
      vatNumber
      zendeskUserId
      createdAt
      updatedAt
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByCognitoId = /* GraphQL */ `
  query UsersByCognitoId(
    $cognitoUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCognitoId(
      cognitoUserId: $cognitoUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAbstractService = /* GraphQL */ `
  query GetAbstractService($id: ID!) {
    getAbstractService(id: $id) {
      id
      categories {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      presentationTypologies {
        items {
          id
          name
          description
          presentationMode
          presentationRequired
          createdAt
          updatedAt
        }
        nextToken
      }
      abstractServiceEventId
      abstractDocuments {
        items {
          id
          title
          status
          cod0
          cod1
          cod2
          url
          int
          disc
          preferredExposure
          processingStatus
          tags
          keywords
          isReadyToReview
          images
          isDeleted
          isOwnerAPresentingAuthor
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewCriteria {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewCriteriaStr
      instructions
      algorithm {
        id
        name
        createdAt
        updatedAt
      }
      tags
      abstractTerm
      firstReminder
      secondReminder
      reviewTerm
      draftReminder
      maxAbstractsPerAuthor
      isCountryConflictDisabled
      isInstitutionConflictDisabled
      reviewersQuantity
      agreement
      reviewers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const listAbstractServices = /* GraphQL */ `
  query ListAbstractServices(
    $filter: ModelAbstractServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAbstractServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categories {
          nextToken
        }
        presentationTypologies {
          nextToken
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteria {
          nextToken
        }
        reviewCriteriaStr
        instructions
        algorithm {
          id
          name
          createdAt
          updatedAt
        }
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        isInstitutionConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      title
      status
      sentAt
      model
      invitations {
        items {
          id
          isCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      responses {
        items {
          id
          result
          createdAt
          updatedAt
        }
        nextToken
      }
      surveyEventId
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        sentAt
        model
        invitations {
          nextToken
        }
        responses {
          nextToken
        }
        surveyEventId
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getQuote = /* GraphQL */ `
  query GetQuote($id: ID!) {
    getQuote(id: $id) {
      id
      quoteEventId
      profileName
      cluster
      dateRanges {
        items {
          id
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      currency
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const listQuotes = /* GraphQL */ `
  query ListQuotes(
    $filter: ModelQuoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quoteEventId
        profileName
        cluster
        dateRanges {
          nextToken
        }
        currency
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFieldDefinition = /* GraphQL */ `
  query GetFieldDefinition($id: ID!) {
    getFieldDefinition(id: $id) {
      id
      controlType
      label
      placeholder
      options
      isDeleted
      contextsOfUse {
        items {
          id
          position
          contextName
          isRequired
          isHidden
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFieldDefinitions = /* GraphQL */ `
  query ListFieldDefinitions(
    $filter: ModelFieldDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFieldDefinitions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        controlType
        label
        placeholder
        options
        isDeleted
        contextsOfUse {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFieldValue = /* GraphQL */ `
  query GetFieldValue($id: ID!) {
    getFieldValue(id: $id) {
      id
      value
      fieldValueFieldDefinitionId
      createdAt
      updatedAt
      fieldDefinition {
        id
        controlType
        label
        placeholder
        options
        isDeleted
        contextsOfUse {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listFieldValues = /* GraphQL */ `
  query ListFieldValues(
    $filter: ModelFieldValueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFieldValues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        fieldValueFieldDefinitionId
        createdAt
        updatedAt
        fieldDefinition {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const fieldValueByfieldDefinitionId = /* GraphQL */ `
  query FieldValueByfieldDefinitionId(
    $fieldValueFieldDefinitionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFieldValueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fieldValueByfieldDefinitionId(
      fieldValueFieldDefinitionId: $fieldValueFieldDefinitionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        fieldValueFieldDefinitionId
        createdAt
        updatedAt
        fieldDefinition {
          id
          controlType
          label
          placeholder
          options
          isDeleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSponsorList = /* GraphQL */ `
  query GetSponsorList($id: ID!) {
    getSponsorList(id: $id) {
      id
      isDeleted
      name
      description
      closingDate
      sponsorListService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorLists {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      modificationRequests {
        items {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSponsorLists = /* GraphQL */ `
  query ListSponsorLists(
    $filter: ModelSponsorListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSponsorListPurchase = /* GraphQL */ `
  query GetSponsorListPurchase($id: ID!) {
    getSponsorListPurchase(id: $id) {
      id
      isDeleted
      purchasedSubscriptions
      netPrice
      quantity
      createdAt
      updatedAt
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSponsorListPurchases = /* GraphQL */ `
  query ListSponsorListPurchases(
    $filter: ModelSponsorListPurchaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorListPurchases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isDeleted
        purchasedSubscriptions
        netPrice
        quantity
        createdAt
        updatedAt
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSponsorStaff = /* GraphQL */ `
  query GetSponsorStaff($id: ID!) {
    getSponsorStaff(id: $id) {
      id
      isDeleted
      name
      description
      closingDate
      sponsorStaffService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsorStaffs {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
    }
  }
`;
export const listSponsorStaffs = /* GraphQL */ `
  query ListSponsorStaffs(
    $filter: ModelSponsorStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorStaffs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSponsorStaffPurchase = /* GraphQL */ `
  query GetSponsorStaffPurchase($id: ID!) {
    getSponsorStaffPurchase(id: $id) {
      id
      isDeleted
      purchasedSubscriptions
      netPrice
      quantity
      createdAt
      updatedAt
      sponsorStaff {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSponsorStaffPurchases = /* GraphQL */ `
  query ListSponsorStaffPurchases(
    $filter: ModelSponsorStaffPurchaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorStaffPurchases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isDeleted
        purchasedSubscriptions
        netPrice
        quantity
        createdAt
        updatedAt
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSponsor = /* GraphQL */ `
  query GetSponsor($id: ID!) {
    getSponsor(id: $id) {
      id
      isDeleted
      name
      description
      businessName
      therapeuticArea
      priceRange {
        id
        name
        description
        price
        start
        freeStaffMembers
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sponsors {
          nextToken
        }
      }
      contacts {
        items {
          id
          name
          surname
          email
          phone
          facebook
          twitter
          linkedin
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandSpaces {
        items {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        nextToken
      }
      physicalStandSpaceItems {
        items {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorVirtualStand {
        items {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      admins {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      sponsorStaff {
        id
        isDeleted
        name
        description
        closingDate
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
      }
      banners {
        items {
          id
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      symposiums {
        items {
          id
          price
          createdAt
          updatedAt
        }
        nextToken
      }
      profileImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      profileCover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      documents {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
        nextToken
      }
      publicPage {
        id
        isPublic
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      breakoutRooms {
        items {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSponsors = /* GraphQL */ `
  query ListSponsors(
    $filter: ModelSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBannerArea = /* GraphQL */ `
  query GetBannerArea($id: ID!) {
    getBannerArea(id: $id) {
      id
      name
      price
      isExclusive
      bannerPage {
        id
        name
        image
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        bannerAreas {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsorAreas {
        items {
          id
          image
          createdAt
          updatedAt
        }
        nextToken
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBannerAreas = /* GraphQL */ `
  query ListBannerAreas(
    $filter: ModelBannerAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBannerAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        price
        isExclusive
        bannerPage {
          id
          name
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorAreas {
          nextToken
        }
        buyOperations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSponsorArea = /* GraphQL */ `
  query GetSponsorArea($id: ID!) {
    getSponsorArea(id: $id) {
      id
      image
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      bannerArea {
        id
        name
        price
        isExclusive
        bannerPage {
          id
          name
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorAreas {
          nextToken
        }
        buyOperations {
          nextToken
        }
      }
    }
  }
`;
export const listSponsorAreas = /* GraphQL */ `
  query ListSponsorAreas(
    $filter: ModelSponsorAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSymposium = /* GraphQL */ `
  query GetSymposium($id: ID!) {
    getSymposium(id: $id) {
      id
      price
      symposiumService {
        id
        name
        description
        vatRate
        deadline
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        symposiums {
          nextToken
        }
        documents {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      agendaSession {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listSymposiums = /* GraphQL */ `
  query ListSymposiums(
    $filter: ModelSymposiumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSymposiums(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        price
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agendaSession {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAgencyGroup = /* GraphQL */ `
  query GetAgencyGroup($id: ID!) {
    getAgencyGroup(id: $id) {
      id
      isDeleted
      name
      isPrepaid
      client
      closingDate
      createdAt
      updatedAt
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      agency {
        id
        isDeleted
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        agencyGroups {
          nextToken
        }
      }
    }
  }
`;
export const listAgencyGroups = /* GraphQL */ `
  query ListAgencyGroups(
    $filter: ModelAgencyGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgencyGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isDeleted
        name
        isPrepaid
        client
        closingDate
        createdAt
        updatedAt
        participations {
          nextToken
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAgency = /* GraphQL */ `
  query GetAgency($id: ID!) {
    getAgency(id: $id) {
      id
      isDeleted
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      admins {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      agencyGroups {
        items {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAgencys = /* GraphQL */ `
  query ListAgencys(
    $filter: ModelAgencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgencys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isDeleted
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        agencyGroups {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAgencyService = /* GraphQL */ `
  query GetAgencyService($id: ID!) {
    getAgencyService(id: $id) {
      id
      minParticipations
      instructions
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      cover {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      gallery {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
      policies {
        items {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAgencyServices = /* GraphQL */ `
  query ListAgencyServices(
    $filter: ModelAgencyServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgencyServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        minParticipations
        instructions
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        cover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        gallery {
          nextToken
        }
        policies {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAgendaSession = /* GraphQL */ `
  query GetAgendaSession($id: ID!) {
    getAgendaSession(id: $id) {
      id
      name
      date
      start
      end
      tags
      type
      president
      shortDescription
      description
      linkPax
      linkSpeaker
      context
      provocateurs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      moderators {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      interventions {
        items {
          id
          name
          start
          end
          instruction
          createdAt
          updatedAt
        }
        nextToken
      }
      agenda {
        id
        tags
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      followers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      room {
        id
        name
        type
        capacity
        delay
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        agendaSessions {
          nextToken
        }
      }
      createdAt
      updatedAt
      symposium {
        id
        price
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agendaSession {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      coverImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAgendaSessions = /* GraphQL */ `
  query ListAgendaSessions(
    $filter: ModelAgendaSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgendaSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        date
        start
        end
        tags
        type
        president
        shortDescription
        description
        linkPax
        linkSpeaker
        context
        provocateurs {
          nextToken
        }
        moderators {
          nextToken
        }
        interventions {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        followers {
          nextToken
        }
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      originalName
      size
      extension
      createdAt
      updatedAt
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBuyOperation = /* GraphQL */ `
  query GetBuyOperation($id: ID!) {
    getBuyOperation(id: $id) {
      id
      eventId
      bookingId
      date
      expiringDate
      paymentType
      unitaryVatRate
      unitaryPrice
      physicalStandSpace {
        id
        netPrice
        name
        code
        noVat
        extraPriceDisabled
        note
        spaceLength
        spaceHeight
        surface
        floor {
          id
          code
          name
          createdAt
          updatedAt
        }
        physicalStandSpacesItems {
          nextToken
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        plantImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      physicalStandSpaceItems {
        id
        quantity
        amountPerQuantity
        item {
          id
          name
          code
          netPrice
          vatRate
          type
          amountPerQuantityLabel
          note
          description
          createdAt
          updatedAt
        }
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      sponsorVirtualStand {
        id
        virtualRoomLink
        virtualStand {
          id
          name
          isEnabled
          ranking
          netPrice
          specificationPost
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      sponsorListPurchase {
        id
        isDeleted
        purchasedSubscriptions
        netPrice
        quantity
        createdAt
        updatedAt
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      sponsorStaffPurchase {
        id
        isDeleted
        purchasedSubscriptions
        netPrice
        quantity
        createdAt
        updatedAt
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
      }
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      bannerArea {
        id
        name
        price
        isExclusive
        bannerPage {
          id
          name
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorAreas {
          nextToken
        }
        buyOperations {
          nextToken
        }
      }
      symposium {
        id
        price
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agendaSession {
          id
          name
          date
          start
          end
          tags
          type
          president
          shortDescription
          description
          linkPax
          linkSpeaker
          context
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      purchase {
        id
        eventId
        paymentType
        date
        purchaseId
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperations {
          nextToken
        }
        payment {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
      }
      publicPage {
        id
        isPublic
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      breakoutRoom {
        id
        date
        start1
        end1
        start2
        end2
        duration
        maxAttendants
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        purchasedHours
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        slots {
          nextToken
        }
      }
    }
  }
`;
export const listBuyOperations = /* GraphQL */ `
  query ListBuyOperations(
    $filter: ModelBuyOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuyOperations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPurchase = /* GraphQL */ `
  query GetPurchase($id: ID!) {
    getPurchase(id: $id) {
      id
      eventId
      paymentType
      date
      purchaseId
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperations {
        items {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      payment {
        id
        paymentId
        paymentIdMonetaOnLine
        securitytokenMonetaOnLine
        paymentIdIngenico
        shasignIngenico
        paymentIdGpWebpay
        digestGpWebpay
        paymentError
        date
        stepName
        stepNote
        paymentType
        amount
        description
        failCleaned
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listPurchases = /* GraphQL */ `
  query ListPurchases(
    $filter: ModelPurchaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        paymentType
        date
        purchaseId
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperations {
          nextToken
        }
        payment {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      paymentId
      paymentIdMonetaOnLine
      securitytokenMonetaOnLine
      paymentIdIngenico
      shasignIngenico
      paymentIdGpWebpay
      digestGpWebpay
      paymentError
      date
      stepName
      stepNote
      paymentType
      amount
      description
      failCleaned
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      purchase {
        id
        eventId
        paymentType
        date
        purchaseId
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperations {
          nextToken
        }
        payment {
          id
          paymentId
          paymentIdMonetaOnLine
          securitytokenMonetaOnLine
          paymentIdIngenico
          shasignIngenico
          paymentIdGpWebpay
          digestGpWebpay
          paymentError
          date
          stepName
          stepNote
          paymentType
          amount
          description
          failCleaned
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        paymentId
        paymentIdMonetaOnLine
        securitytokenMonetaOnLine
        paymentIdIngenico
        shasignIngenico
        paymentIdGpWebpay
        digestGpWebpay
        paymentError
        date
        stepName
        stepNote
        paymentType
        amount
        description
        failCleaned
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPublicPage = /* GraphQL */ `
  query GetPublicPage($id: ID!) {
    getPublicPage(id: $id) {
      id
      isPublic
      publicPageService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        netPrice
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        publicPages {
          nextToken
        }
      }
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listPublicPages = /* GraphQL */ `
  query ListPublicPages(
    $filter: ModelPublicPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isPublic
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBreakoutRoom = /* GraphQL */ `
  query GetBreakoutRoom($id: ID!) {
    getBreakoutRoom(id: $id) {
      id
      date
      start1
      end1
      start2
      end2
      duration
      maxAttendants
      breakoutRoomService {
        id
        name
        description
        price
        vatRate
        isSponsorEditable
        isCreditCardEnabled
        isBankTransferEnabled
        isOnline
        maxAvailableHours
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        breakoutRooms {
          nextToken
        }
      }
      purchasedHours
      createdAt
      updatedAt
      sponsor {
        id
        isDeleted
        name
        description
        businessName
        therapeuticArea
        priceRange {
          id
          name
          description
          price
          start
          freeStaffMembers
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        physicalStandSpaces {
          nextToken
        }
        physicalStandSpaceItems {
          nextToken
        }
        sponsorVirtualStand {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        banners {
          nextToken
        }
        symposiums {
          nextToken
        }
        profileImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profileCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyOperations {
          nextToken
        }
        purchases {
          nextToken
        }
        payments {
          nextToken
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRooms {
          nextToken
        }
      }
      buyOperation {
        id
        eventId
        bookingId
        date
        expiringDate
        paymentType
        unitaryVatRate
        unitaryPrice
        physicalStandSpace {
          id
          netPrice
          name
          code
          noVat
          extraPriceDisabled
          note
          spaceLength
          spaceHeight
          surface
          createdAt
          updatedAt
        }
        physicalStandSpaceItems {
          id
          quantity
          amountPerQuantity
          createdAt
          updatedAt
        }
        sponsorVirtualStand {
          id
          virtualRoomLink
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        sponsorListPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsorStaffPurchase {
          id
          isDeleted
          purchasedSubscriptions
          netPrice
          quantity
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        bannerArea {
          id
          name
          price
          isExclusive
          createdAt
          updatedAt
        }
        symposium {
          id
          price
          createdAt
          updatedAt
        }
        purchase {
          id
          eventId
          paymentType
          date
          purchaseId
          createdAt
          updatedAt
        }
        publicPage {
          id
          isPublic
          createdAt
          updatedAt
        }
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
      }
      slots {
        items {
          id
          title
          start
          end
          note
          isOneToOne
          vonageSessionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBreakoutRooms = /* GraphQL */ `
  query ListBreakoutRooms(
    $filter: ModelBreakoutRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreakoutRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        start1
        end1
        start2
        end2
        duration
        maxAttendants
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        purchasedHours
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        slots {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBreakoutRoomSlot = /* GraphQL */ `
  query GetBreakoutRoomSlot($id: ID!) {
    getBreakoutRoomSlot(id: $id) {
      id
      title
      start
      end
      note
      isOneToOne
      vonageSessionId
      createdAt
      updatedAt
      breakoutRoom {
        id
        date
        start1
        end1
        start2
        end2
        duration
        maxAttendants
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        purchasedHours
        createdAt
        updatedAt
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        buyOperation {
          id
          eventId
          bookingId
          date
          expiringDate
          paymentType
          unitaryVatRate
          unitaryPrice
          createdAt
          updatedAt
        }
        slots {
          nextToken
        }
      }
      emails {
        items {
          id
          token
          email
          state
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      invitations {
        items {
          id
          state
          note
          isSelfProposed
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBreakoutRoomSlots = /* GraphQL */ `
  query ListBreakoutRoomSlots(
    $filter: ModelBreakoutRoomSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreakoutRoomSlots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        start
        end
        note
        isOneToOne
        vonageSessionId
        createdAt
        updatedAt
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        emails {
          nextToken
        }
        invitations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBreakoutRoomInvitationUnregistered = /* GraphQL */ `
  query GetBreakoutRoomInvitationUnregistered($id: ID!) {
    getBreakoutRoomInvitationUnregistered(id: $id) {
      id
      token
      email
      state
      note
      createdAt
      updatedAt
      slot {
        id
        title
        start
        end
        note
        isOneToOne
        vonageSessionId
        createdAt
        updatedAt
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        emails {
          nextToken
        }
        invitations {
          nextToken
        }
      }
    }
  }
`;
export const listBreakoutRoomInvitationUnregistereds = /* GraphQL */ `
  query ListBreakoutRoomInvitationUnregistereds(
    $filter: ModelBreakoutRoomInvitationUnregisteredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreakoutRoomInvitationUnregistereds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        token
        email
        state
        note
        createdAt
        updatedAt
        slot {
          id
          title
          start
          end
          note
          isOneToOne
          vonageSessionId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBreakoutRoomAttending = /* GraphQL */ `
  query GetBreakoutRoomAttending($id: ID!) {
    getBreakoutRoomAttending(id: $id) {
      id
      state
      note
      isSelfProposed
      createdAt
      updatedAt
      participation {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      slot {
        id
        title
        start
        end
        note
        isOneToOne
        vonageSessionId
        createdAt
        updatedAt
        breakoutRoom {
          id
          date
          start1
          end1
          start2
          end2
          duration
          maxAttendants
          purchasedHours
          createdAt
          updatedAt
        }
        emails {
          nextToken
        }
        invitations {
          nextToken
        }
      }
    }
  }
`;
export const listBreakoutRoomAttendings = /* GraphQL */ `
  query ListBreakoutRoomAttendings(
    $filter: ModelBreakoutRoomAttendingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreakoutRoomAttendings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        state
        note
        isSelfProposed
        createdAt
        updatedAt
        participation {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        slot {
          id
          title
          start
          end
          note
          isOneToOne
          vonageSessionId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      name
      cluster
      isPublic
      category {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
      }
      profileFeeBrackets {
        items {
          id
          priceOnAir
          priceOnSite
          discountPrice
          discountCode
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeleted
      createdBy
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
      participations {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getParticipationTypology = /* GraphQL */ `
  query GetParticipationTypology($id: ID!) {
    getParticipationTypology(id: $id) {
      id
      name
      createdAt
      updatedAt
      event {
        id
        operators
        createdBy
        isOwnedByCongress
        name
        code
        organizer
        start
        end
        isOnAir
        venue
        country
        city
        initials
        invoicingInitials
        order
        division {
          id
          name
          createdAt
          updatedAt
        }
        industry {
          id
          name
          createdAt
          updatedAt
        }
        topic {
          id
          name
          createdAt
          updatedAt
        }
        accountingTypology {
          id
          name
          createdAt
          updatedAt
        }
        language {
          id
          name
          createdAt
          updatedAt
        }
        contacts {
          nextToken
        }
        isDeleted
        services {
          nextToken
        }
        sponsorWelcomePage {
          id
          title
          subtitle
          content
          createdAt
          updatedAt
        }
        bannerService {
          id
          name
          description
          vatRate
          jsonDefinition
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        symposiumService {
          id
          name
          description
          vatRate
          deadline
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        physicalStandService {
          id
          name
          description
          vatRate
          extraPriceDate
          extraPriceRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        publicPageService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          netPrice
          createdAt
          updatedAt
        }
        breakoutRoomService {
          id
          name
          description
          price
          vatRate
          isSponsorEditable
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          maxAvailableHours
          createdAt
          updatedAt
        }
        otherStandServicesService {
          id
          name
          description
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        virtualStandService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorStaffService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        sponsorPriceRanges {
          nextToken
        }
        rooms {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        sponsorServiceBookingDuration
        IBAN
        profileCategories {
          nextToken
        }
        mailUpListId
        createdAt
        updatedAt
        delegations {
          nextToken
        }
        webcasts {
          nextToken
        }
        participations {
          nextToken
        }
        abstractService {
          id
          abstractServiceEventId
          reviewCriteriaStr
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          draftReminder
          maxAbstractsPerAuthor
          isCountryConflictDisabled
          isInstitutionConflictDisabled
          reviewersQuantity
          agreement
          createdAt
          updatedAt
        }
        surveys {
          nextToken
        }
        quotes {
          nextToken
        }
        standardFields {
          nextToken
        }
        fields {
          nextToken
        }
        sponsorLists {
          nextToken
        }
        sponsorStaffs {
          nextToken
        }
        sponsors {
          nextToken
        }
        agencies {
          nextToken
        }
        agencyService {
          id
          minParticipations
          instructions
          createdAt
          updatedAt
        }
        privacyFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        profiles {
          nextToken
        }
        participationTypologies {
          nextToken
        }
      }
    }
  }
`;
export const listParticipationTypologys = /* GraphQL */ `
  query ListParticipationTypologys(
    $filter: ModelParticipationTypologyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipationTypologys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFeeDateRange = /* GraphQL */ `
  query GetFeeDateRange($id: ID!) {
    getFeeDateRange(id: $id) {
      id
      start
      end
      label
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeBracketModels {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        feeDateRanges {
          nextToken
        }
      }
      feeBrackets {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFeeDateRanges = /* GraphQL */ `
  query ListFeeDateRanges(
    $filter: ModelFeeDateRangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeeDateRanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGateway = /* GraphQL */ `
  query GetGateway($id: ID!) {
    getGateway(id: $id) {
      id
      service
      paymentType
      createdAt
      updatedAt
      shop {
        id
        name
        gatewayType
        gatewayId
        gatewayPwd
        iban
        createdAt
        updatedAt
      }
    }
  }
`;
export const listGateways = /* GraphQL */ `
  query ListGateways(
    $filter: ModelGatewayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        service
        paymentType
        createdAt
        updatedAt
        shop {
          id
          name
          gatewayType
          gatewayId
          gatewayPwd
          iban
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      gatewayType
      gatewayId
      gatewayPwd
      iban
      createdAt
      updatedAt
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        gatewayType
        gatewayId
        gatewayPwd
        iban
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getParticipationRequest = /* GraphQL */ `
  query GetParticipationRequest($id: ID!) {
    getParticipationRequest(id: $id) {
      id
      eventId
      familyName
      givenName
      email
      status
      isParticipant
      isSpeaker
      isReviewer
      isScientificCommittee
      cluster
      isDeleted
      createdAt
      updatedAt
      delegation {
        id
        name
        customer
        description
        closingDate
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        modificationRequests {
          nextToken
        }
      }
      participationSubject {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsorList {
        id
        isDeleted
        name
        description
        closingDate
        sponsorListService {
          id
          name
          description
          vatRate
          isCreditCardEnabled
          isBankTransferEnabled
          isOnline
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        purchases {
          nextToken
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        modificationRequests {
          nextToken
        }
      }
      profile {
        id
        name
        cluster
        isPublic
        category {
          id
          name
          createdAt
          updatedAt
        }
        profileFeeBrackets {
          nextToken
        }
        isDeleted
        createdBy
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
      }
      typology {
        id
        name
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      feeDateRange {
        id
        start
        end
        label
        fee {
          id
          feeEventId
          vatRate
          currency
          isPublished
          createdAt
          updatedAt
        }
        feeBrackets {
          nextToken
        }
        createdAt
        updatedAt
      }
      modificationRequest {
        id
        eventId
        requestId
        type
        requestStatus
        paymentId
        participationId
        isDeleted
        createdAt
        updatedAt
        requestedBy {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        participationRequest {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listParticipationRequests = /* GraphQL */ `
  query ListParticipationRequests(
    $filter: ModelParticipationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipationRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        familyName
        givenName
        email
        status
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        cluster
        isDeleted
        createdAt
        updatedAt
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        participationSubject {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        modificationRequest {
          id
          eventId
          requestId
          type
          requestStatus
          paymentId
          participationId
          isDeleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getModificationRequest = /* GraphQL */ `
  query GetModificationRequest($id: ID!) {
    getModificationRequest(id: $id) {
      id
      eventId
      requestId
      type
      requestStatus
      paymentId
      participationId
      isDeleted
      createdAt
      updatedAt
      requestedBy {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        isAdmin
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        zendeskUserId
        createdAt
        updatedAt
        participations {
          nextToken
        }
      }
      participationRequest {
        id
        eventId
        familyName
        givenName
        email
        status
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        cluster
        isDeleted
        createdAt
        updatedAt
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        participationSubject {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        modificationRequest {
          id
          eventId
          requestId
          type
          requestStatus
          paymentId
          participationId
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listModificationRequests = /* GraphQL */ `
  query ListModificationRequests(
    $filter: ModelModificationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModificationRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        requestId
        type
        requestStatus
        paymentId
        participationId
        isDeleted
        createdAt
        updatedAt
        requestedBy {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        participationRequest {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getParticipationConflictRequest = /* GraphQL */ `
  query GetParticipationConflictRequest($id: ID!) {
    getParticipationConflictRequest(id: $id) {
      id
      createdAt
      updatedAt
      participationSubject {
        id
        cognitoUserId
        isDeleted
        email
        givenName
        familyName
        businessName
        owner
        cluster
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        paymentStatus
        registrationChannel
        isInvited
        status
        feeBracketModel {
          id
          start
          end
          name
          createdAt
          updatedAt
        }
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        myAgenda {
          nextToken
        }
        provocateurRole {
          nextToken
        }
        moderatorRole {
          nextToken
        }
        userSettings {
          id
          participationId
          isPublic
          showMyAgenda
          showBiography
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        event {
          id
          operators
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          isOnAir
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        userShowcase {
          id
          participationId
          businessPhone
          companyName
          workJob
          description
          birthDate
          phone
          contactEmail
          nationality
          address
          linkedin
          facebook
          twitter
          instagram
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          email
          givenName
          familyName
          businessName
          isAdmin
          type
          status
          nationality
          language
          birthDate
          fiscalCode
          vatNumber
          zendeskUserId
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        publicFieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsorStaff {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        sponsor {
          id
          isDeleted
          name
          description
          businessName
          therapeuticArea
          createdAt
          updatedAt
        }
        agencyGroup {
          id
          isDeleted
          name
          isPrepaid
          client
          closingDate
          createdAt
          updatedAt
        }
        agency {
          id
          isDeleted
          name
          createdAt
          updatedAt
        }
        myBreakoutRoom {
          nextToken
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        participationHistorical {
          nextToken
        }
        conflictRequest {
          nextToken
        }
      }
      participationRequest {
        id
        eventId
        familyName
        givenName
        email
        status
        isParticipant
        isSpeaker
        isReviewer
        isScientificCommittee
        cluster
        isDeleted
        createdAt
        updatedAt
        delegation {
          id
          name
          customer
          description
          closingDate
          createdAt
          updatedAt
        }
        participationSubject {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        fieldValues {
          nextToken
        }
        sponsorList {
          id
          isDeleted
          name
          description
          closingDate
          createdAt
          updatedAt
        }
        profile {
          id
          name
          cluster
          isPublic
          isDeleted
          createdBy
          createdAt
          updatedAt
        }
        typology {
          id
          name
          createdAt
          updatedAt
        }
        feeDateRange {
          id
          start
          end
          label
          createdAt
          updatedAt
        }
        modificationRequest {
          id
          eventId
          requestId
          type
          requestStatus
          paymentId
          participationId
          isDeleted
          createdAt
          updatedAt
        }
      }
      participationConflict {
        id
        eventId
        requestId
        type
        requestStatus
        isDeleted
        createdAt
        updatedAt
        conflictResult {
          nextToken
        }
        participationConflictRequest {
          nextToken
        }
      }
    }
  }
`;
export const listParticipationConflictRequests = /* GraphQL */ `
  query ListParticipationConflictRequests(
    $filter: ModelParticipationConflictRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipationConflictRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        participationSubject {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        participationRequest {
          id
          eventId
          familyName
          givenName
          email
          status
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          cluster
          isDeleted
          createdAt
          updatedAt
        }
        participationConflict {
          id
          eventId
          requestId
          type
          requestStatus
          isDeleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getParticipationConflict = /* GraphQL */ `
  query GetParticipationConflict($id: ID!) {
    getParticipationConflict(id: $id) {
      id
      eventId
      requestId
      type
      requestStatus
      isDeleted
      createdAt
      updatedAt
      conflictResult {
        items {
          id
          cognitoUserId
          isDeleted
          email
          givenName
          familyName
          businessName
          owner
          cluster
          isParticipant
          isSpeaker
          isReviewer
          isScientificCommittee
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
      participationConflictRequest {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listParticipationConflicts = /* GraphQL */ `
  query ListParticipationConflicts(
    $filter: ModelParticipationConflictFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipationConflicts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        requestId
        type
        requestStatus
        isDeleted
        createdAt
        updatedAt
        conflictResult {
          nextToken
        }
        participationConflictRequest {
          nextToken
        }
      }
      nextToken
    }
  }
`;
