/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://yassmbvwsjb73e7eiqoon7zwnq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-nkscpk7kgneazpwjk63uctqsay",
    "aws_cloud_logic_custom": [
        {
            "name": "aimGpWebPay",
            "endpoint": "https://9d0t8gcnqc.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        },
        {
            "name": "aimGpWebPayNotify",
            "endpoint": "https://oj52qhqtb1.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        },
        {
            "name": "aimIngenico",
            "endpoint": "https://k11mhrji7k.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        },
        {
            "name": "aimIngenicoNotify",
            "endpoint": "https://lp6seeji15.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        },
        {
            "name": "aimMonetaOnLine",
            "endpoint": "https://kqpm3xwt65.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        },
        {
            "name": "aimMonetaOnLineNotify",
            "endpoint": "https://0lo5k3mpbd.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        },
        {
            "name": "aimlambdaproxy",
            "endpoint": "https://ephwn5mo43.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        },
        {
            "name": "apiSendMail",
            "endpoint": "https://3lpbiqpk05.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        },
        {
            "name": "breakoutRoomInvitations",
            "endpoint": "https://lmtt488483.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        },
        {
            "name": "tcinterface",
            "endpoint": "https://iw8buf3151.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        },
        {
            "name": "vonageServer",
            "endpoint": "https://tx1pbolo2a.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:cbbfbdd5-fcff-43a1-8a2e-6a61f99e4c99",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ae0dE6aTT",
    "aws_user_pools_web_client_id": "30n8ivcjvmphk20qcm6oft1hl5",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "aims3bucket143749-test",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
