import { PDFDocument } from 'pdf-lib';
// import fontkit from '@pdf-lib/fontkit'; // For custom fonts only
import constants from './constants';
import fileHelper from './fileHelper';
import appState from './appState';
import { saveAs } from 'file-saver';
import { generateQR, generateBarcode } from './codesGeneratorHelper';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const { getS3Link } = fileHelper;

const A4SIZE = {
  width: 21.0,
  height: 29.7,
};

const getXPoint = ({ page, cm }) => (page.getSize()?.width * cm) / A4SIZE.width;

const getYPoint = ({ page, cm }) => {
  const actualPageHeight = page.getSize()?.height;
  return Math.abs((actualPageHeight * cm) / A4SIZE.height - actualPageHeight);
};

const getFileLink = async ({ eventId, template }) =>
  await getS3Link(`events/${eventId}/papers/badges`, {
    id: `${template.id}${template.extension}`,
    extension: '',
    originalName: ``,
  });

const base64ToIFrame = (base64URL, title = 'Print Badge') => {
  const win = window.open();
  win.document.write(
    '<iframe src="' +
      base64URL +
      '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
  );
  win.document.body.style.margin = '0px';
  win.document.title = title;
};

const extractMappedField = ({
  mappedValueString,
  mappedValue,
  isDottedName,
  user,
}) => {
  if (mappedValueString) {
    return isDottedName
      ? `${user[mappedValueString].slice(0, 1)}.`
      : user[mappedValueString];
  } else {
    const { fieldValues } = user;
    const targetField = fieldValues.items.find(
      (f) => f.fieldValueFieldDefinitionId === mappedValue
    );
    let value = '';
    if (targetField) {
      // console.log('handle', value);
      value = isDottedName
        ? `${JSON.parse(targetField.value).slice(0, 1)}.`
        : JSON.parse(targetField.value);
    }

    // console.log('value', value);
    return value;
  }
};

const getTextAlignmentOffset = ({
  selectedFont,
  valueToDraw,
  fontSize,
  paragraph,
}) => {
  const textWidth = selectedFont.widthOfTextAtSize(valueToDraw, fontSize);
  let textAlignmentOffset = 0;
  if (paragraph === constants.BadgesParagraphs.CENTER_ALIGN.id)
    textAlignmentOffset = textWidth / 2;
  else if (paragraph === constants.BadgesParagraphs.RIGHT_ALIGN.id)
    textAlignmentOffset = textWidth;
  return textAlignmentOffset;
};

const handleField = async ({ pdfDoc, field, user, area, currentPage }) => {
  const { xCoordinate: areaX, yCoordinate: areaY } = area;
  const {
    fontSize: rawFontSize,
    xCoordinate: fieldX,
    yCoordinate: fieldY,
    mappedValue,
    isDottedName,
    mappedValueString,
    fontStyle,
    paragraph = constants.BadgesParagraphs.CENTER_ALIGN.id,
    codeRotation = constants.BadgesBarcodeRotation.NORMAL,
    codeScale: rawCodeScale,
  } = field;

  // Calculate field coordinates
  const xPoint = getXPoint({
    page: currentPage,
    cm: parseFloat(areaX) + parseFloat(fieldX),
  });

  const yPoint = getYPoint({
    page: currentPage,
    cm: parseFloat(areaY) + parseFloat(fieldY),
  });

  // console.log('Points: X --> ', xPoint, ' - Y --> ', yPoint);

  const isQR = field.name === constants.BadgesFieldTypes.QRCODE.id;
  const isBarcode = field.name === constants.BadgesFieldTypes.BARCODE.id;
  const isCode = isQR || isBarcode;

  // Draw codes or Text
  if (isCode) {
    // Generate QR or Barcode
    const userCodeBytes = isQR
      ? await generateQR(user.id)
      : generateBarcode(user.id, codeRotation);

    // Embed generated image buffer into our PDF Document
    const userCodeImage = await pdfDoc.embedPng(userCodeBytes);
    // Scale code image and get its size
    const codeScale = parseFloat(rawCodeScale);
    const userCodeSize = userCodeImage.scale(codeScale);

    // Write Image to PDF
    currentPage.drawImage(userCodeImage, {
      x: xPoint,
      y: yPoint,
      width: userCodeSize.width,
      height: userCodeSize.height,
    });
  } else {
    const valueToDraw = extractMappedField({
      mappedValueString,
      mappedValue,
      isDottedName,
      user,
    });
    // Choose desired font
    const fontSize = parseInt(rawFontSize);
    const selectedFont = await pdfDoc.embedFont(fontStyle);
    // console.log('selectedFont', selectedFont);
    // console.log('valueToDraw', valueToDraw);
    // console.log('fontSize', fontSize);
    // console.log('paragraph', paragraph);

    // Calculate draw positions
    const textAlignmentOffset = getTextAlignmentOffset({
      selectedFont,
      valueToDraw,
      fontSize,
      paragraph,
    });

    // console.log('currentPage', currentPage);

    // Write value to PDF
    currentPage.drawText(valueToDraw, {
      x: xPoint - textAlignmentOffset,
      y: yPoint,
      size: fontSize,
      font: selectedFont,
    });
  }
};

const handleCoupon = async ({ pdfDoc, user, couponArea, currentPage }) => {
  if (
    !couponArea.additionalServiceId ||
    !user.additionalServices.includes(couponArea.additionalServiceId)
  ) {
    return;
  }
  const { xCoordinate: areaX, yCoordinate: areaY } = couponArea;

  // Calculate field coordinates
  const xPoint = getXPoint({
    page: currentPage,
    cm: parseFloat(areaX),
  });

  const yPoint = getYPoint({
    page: currentPage,
    cm: parseFloat(areaY),
  });

  const maxWidth = getXPoint({
    page: currentPage,
    cm: parseFloat(9.5),
  });

  // console.log('Points: X --> ', xPoint, ' - Y --> ', yPoint);

  const valueToDraw = couponArea.additionalServiceTitle;
  // Choose desired font
  const fontSize = parseInt(14);
  const selectedFont = await pdfDoc.embedFont('Times-Roman');

  // Write value to PDF
  currentPage.drawText(valueToDraw, {
    x: xPoint,
    y: yPoint,
    size: fontSize,
    font: selectedFont,
    maxWidth,
  });
};

const handleParticipation = ({
  fields,
  pdfDoc,
  currentArea,
  couponAreas,
  currentPage,
  user,
}) => {
  // Loop through areas and draw fields
  // console.log('fields', fields);
  fields?.map((field) =>
    handleField({ pdfDoc, field, user, area: currentArea, currentPage })
  );
  couponAreas.map((couponArea) =>
    handleCoupon({ pdfDoc, user, couponArea, currentPage })
  );
};

const savePDF = async ({ pdfDoc, template }) => {
  // Save PDF and open in an external iFrame (in new tab)
  const bytes = await pdfDoc.saveAsBase64({ dataUri: true });
  saveAs(bytes, 'Badges');
  // const base64DataUri = await pdfDoc.saveAsBase64({ dataUri: true });
  // base64ToIFrame(base64DataUri, `${template.id}.${template.extension}`);
};

const savePDFWithoutTemplate = async (pdfDoc) => {
  // Save PDF and open in an external iFrame (in new tab)
  const base64DataUri = await pdfDoc.saveAsBase64({ dataUri: true });
  base64ToIFrame(base64DataUri);
};

const getPDFDoc = async ({ eventId, template, includeBackground }) => {
  // console.log('Template? ', template);
  if (includeBackground && template) {
    // Fetch remote PDF Template
    const templateURL = await getFileLink({ eventId, template });
    const templateBufferRes = await fetch(templateURL);
    const templateBuffer = await templateBufferRes.arrayBuffer();
    // Load PDF
    const pdfDoc = await PDFDocument.load(templateBuffer);
    return pdfDoc;
  } else {
    const pdfDoc = await PDFDocument.create();
    pdfDoc.addPage();
    return pdfDoc;
  }
};

const printBadges = async ({
  users,
  eventId,
  badge,
  includeBackground = true,
}) => {
  // console.log('badge', badge);
  // Extract data
  const { template } = badge;
  const areas = badge.areas.items;
  const couponAreas = badge.couponAreas.items;
  const fields = badge.fields.items;
  const areasPerPage = areas.length;

  // Get PDF Doc
  const pdfDoc = await getPDFDoc({ eventId, template, includeBackground });

  // Add needed "blank" pages
  const pagesToAddCount = Math.floor(users.length / areasPerPage);
  for (let i = 0; i < pagesToAddCount; i++) {
    const [templatePage] = await pdfDoc.copyPages(pdfDoc, [0]);
    pdfDoc.addPage(templatePage);
  }
  // console.log('users', users);
  // Loop through participants
  users.map(async (user, index) => {
    const paxIterationCount = index + 1;
    // Select target page
    const currentPage = pdfDoc.getPage(Math.floor(index / areasPerPage));
    // Select current area data
    const currentArea = areas.sort((a, b) => a.orderNumber - b.orderNumber)[
      paxIterationCount % areasPerPage === 0
        ? areasPerPage - 1
        : (paxIterationCount % areasPerPage) - 1
    ];
    console.log('currentPage', currentPage);
    handleParticipation({
      pdfDoc,
      fields,
      user,
      currentArea,
      //all areas for each pax
      couponAreas,
      currentPage,
    });
  });

  // Save PDF and open in an external iFrame (in new tab)
  savePDF({ pdfDoc, template });
};

const printJointBadges = async ({
  filteredParticipants,
  eventId,
  badges,
  includeBackground = true,
}) => {
  showLoader();
  // Create the general file to print
  let templatePageIndex = 0;
  const pdfDoc = await PDFDocument.create();

  // used 'for' to be synchronous
  for (let b = 0; b < badges.length; b++) {
    // select the badge and the template
    const badge = badges[b];
    const { template } = badge;
    // creating a new file to contain all users of that badge
    // in the meantime, we create a new PDF, copy the template
    // page and assign to the general file
    const newPdfDoc = await getPDFDoc({
      eventId,
      template,
      includeBackground,
    });
    const copiedPages = await pdfDoc.copyPages(newPdfDoc, [0]);
    copiedPages.forEach((page) => pdfDoc.addPage(page));

    // Extract data
    const badgeTypology = badge.typology ? badge.typology.name : null;
    const users = filteredParticipants.filter(
      (p) => badgeTypology === p.typology
    );
    const areas = badge.areas.items;
    const fields = badge.fields.items;
    const areasPerPage = areas.length;

    // Add needed "blank" pages
    const pagesToAddCount = Math.floor(users.length / areasPerPage);
    // console.log('Pages to add: ', pagesToAddCount, users, areasPerPage);
    for (let i = 0; i < pagesToAddCount; i++) {
      // console.log('Template pages', templatePage, templatePageIndex);
      const [templatePage] = await pdfDoc.copyPages(pdfDoc, [
        templatePageIndex,
      ]);
      pdfDoc.addPage(templatePage);
    }
    // Loop through participants
    users.map(async (user, index) => {
      const paxIterationCount = index + 1;
      // Select target page
      const currentPage = pdfDoc.getPage(
        Math.floor(index / areasPerPage) + templatePageIndex
      );
      // Select current area data
      const currentArea = areas.sort((a, b) => a.orderNumber - b.orderNumber)[
        paxIterationCount % areasPerPage === 0
          ? areasPerPage - 1
          : (paxIterationCount % areasPerPage) - 1
      ];
      // insert values in fields
      handleParticipation({
        pdfDoc,
        fields,
        user,
        badge,
        currentArea,
        currentPage,
      });
    });
    // the index of the template's page
    templatePageIndex += pagesToAddCount + 1;
  }

  // console.log('Parsed pdf', pdfDoc);

  // Save PDF and open in an external iFrame (in new tab)
  savePDFWithoutTemplate(pdfDoc);
  hideLoader();
};

const printBadgesPreview = async ({ file, eventId, user, areas }) => {
  // Extract data
  const { fields } = user;
  // Load temp PDF buffer or fetch remote pdf data

  let pdfDoc;
  if (eventId && file.id)
    pdfDoc = await getPDFDoc({
      eventId,
      template: file,
      includeBackground: true,
    });
  else pdfDoc = await PDFDocument.load(file);

  areas.map((area) =>
    fields?.map((field) =>
      handleField({ pdfDoc, field, user, area, currentPage: pdfDoc.getPage(0) })
    )
  );

  const template = {
    id: 'badges-preview',
    extension: 'pdf',
  };

  savePDF({ pdfDoc, template });
};

const getBadgePreviewUrl = async ({
  file,
  eventId,
  user,
  areas,
  couponAreas,
}) => {
  // Extract data
  const { fields } = user;
  // Load temp PDF buffer or fetch remote pdf data

  let pdfDoc;
  if (eventId && file.id)
    pdfDoc = await getPDFDoc({
      eventId,
      template: file,
      includeBackground: true,
    });
  else pdfDoc = await PDFDocument.load(file);

  areas.map((area) =>
    fields?.map((field) =>
      handleField({ pdfDoc, field, user, area, currentPage: pdfDoc.getPage(0) })
    )
  );
  couponAreas.map((couponArea) =>
    handleCoupon({ pdfDoc, user, couponArea, currentPage: pdfDoc.getPage(0) })
  );
  const base64DataUri = await pdfDoc.saveAsBase64({ dataUri: true });
  return base64DataUri;
};

export default {
  printBadges,
  printJointBadges,
  printBadgesPreview,
  getBadgePreviewUrl,
};
