import appState from './appState';
import aws from './aws';

const apiName = 'aimlambdaproxy';
const apiPath = '/opensearch/';

const makeQueryStringCondition = (key, value = '', withWildcards = false) => ({
  query_string: {
    default_field: key,
    query: withWildcards ? `*${value.trim()}*` : `"${value.trim()}"`,
  },
});

const makeInput = (bool) => ({
  body: {
    index: 'participationdigest',
    filterBody: {
      size: 10000,
      query: {
        bool,
      },
      _source: ['id'],
    },
  },
});

const sendQuery = async () => {
  const queryBoolObj = appState.advancedFilterState.get('participations');

  const queryAifaBoolObj = appState.advancedFilterState.get(
    'aifaAccreditationMaterial'
  );
  const queryASBoolObj = appState.advancedFilterState.get(
    'asAdditionalServices'
  );

  const queryabstractBoolObj = appState.advancedFilterState.get('abstract');

  const queryTSBoolObj = appState.advancedFilterState.get('tsTravelTransfer');
  const input = makeInput({
    filter: [
      ...queryBoolObj.filter,
      ...queryAifaBoolObj.filter,
      ...queryASBoolObj.filter,
      ...queryTSBoolObj.filter,
      ...queryabstractBoolObj.filter,
    ],
    must_not: [
      ...queryBoolObj.must_not,
      ...queryAifaBoolObj.must_not,
      ...queryASBoolObj.must_not,
      ...queryTSBoolObj.must_not,
      ...queryabstractBoolObj.must_not,
    ],
  });

  const response = await aws.standardAPI.post(apiName, apiPath, input);
  console.log({ response });
  return response;
};
const sendAdvancedFiltersGenericQuery = async (eventId) => {
  const queryBoolObj = appState.advancedFilterState.get('generic');
  const input = makeInput({
    ...queryBoolObj,
    filter: [
      ...queryBoolObj.filter,
      makeQueryStringCondition('eventId', eventId),
    ],
  });
  const response = await aws.standardAPI.post(apiName, apiPath, input);
  console.log({ response });
  return response;
};

const sendStandardQuery = async (input) => {
  const response = await aws.standardAPI.post(apiName, apiPath, {
    body: input,
  });
  return response;
};

export default {
  sendQuery,
  sendAdvancedFiltersGenericQuery,
  sendStandardQuery,
};
