import { format } from 'date-fns';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import MarkdownIt from 'markdown-it';
import {
  getAbstract,
  getBookingInfo,
  getParticipation,
  getProduct,
  getPurchases,
  getSponsor,
} from './aimSendMail/emailTemplatesGqlHelper';
import constants from './constants';

import aws from './aws';

const { ProductType, PaymentTypes } = constants;

const md = new MarkdownIt({
  breaks: true,
  html: true,
  linkify: true,
  typographer: true,
});

const renderMarkdownToHtml = (text = '') => {
  //CODE TO INJECT STYLES
  return `<style type="text/css"> p {line-height: 1.3 !important;} </style>${md.render(
    text
      //code to change newline char
      .replaceAll(/(\r\n\r\n|\r\n|\n\n)/g, '\n<br><br>')
      // code needed for handlebars to remove spaces on variables in template
      .replace(
        new RegExp('{{(.*?)}}', 'g'),
        (match, string) => `{{${string.replaceAll(' ', '_')}}}`
      )
  )}`;
};

// handlebars templates
const hbTemplates = {
  invoiceDetails: Handlebars.compile(
    renderMarkdownToHtml(
      `**Company / Institution:** {{name}} {{surname}}
  **Address:** {{address}}
**Postal Code/Zip:** {{postalCode}}
**City:** {{city}}
**State:** {{region}}
**Country:** {{country}}
**VAT Code:** {{vatCode}}
**Tax Identification Number (or fiscal code):** {{taxCode}}`
    )
  ),
  paymentInfo: Handlebars.compile(
    renderMarkdownToHtml(`{{paymentType}}
{{#if isBankTransfer}}
{{addressedTo}}
**IBAN:** {{iban}}
**Casual:** {{ibanCausal}}
{{/if}}`)
  ),
  bookingSponsor: ({ siteDomain, idEvent, sponsorId }) =>
    Handlebars.compile(
      renderMarkdownToHtml(
        `**<a href="${`${siteDomain}/events/${idEvent}/${sponsorId}/my-purchases`}" target="_blanck">View my order</a>**`
      )
    ),
  bookings: ({ idEvent, idUser, idProduct, siteDomain }) => ({
    [ProductType.registration]: Handlebars.compile(
      renderMarkdownToHtml(
        `**<a href="${`${siteDomain}/events/${idEvent}/user/${idUser}/my-ticket/${idProduct}`}" target="_blanck">View my order</a>**

      **Registration:** {{label}}
      **From:** {{start}}
      **To:** {{end}}
      **Profile:** {{profileName}}`
      )
    ),
    [ProductType.scientificEvents]: Handlebars.compile(
      renderMarkdownToHtml(
        `**<a href="${`${siteDomain}/events/${idEvent}/user/${idUser}/additional-services/scientific/${idProduct}`}" target="_blanck">View my order</a>**

      **Service:** {{title}}
      **Description:** {{description}}
      **Date:** {{date}}
      **From:** {{startTime}}
      **To:** {{endTime}}
      **Location:** {{locationName}}
      **Address:** {{locationAddress}}`
      )
    ),
    [ProductType.socialEvents]: Handlebars.compile(
      renderMarkdownToHtml(
        `**<a href="${`${siteDomain}/events/${idEvent}/user/${idUser}/additional-services/social/${idProduct}`}" target="_blanck">View my order</a>**

      **Service:** {{title}}
      **Description:** {{description}}
      **Date:** {{date}}
      **From:** {{startTime}}
      **To:** {{endTime}}
      **Location:** {{locationName}}
      **Address:** {{locationAddress}}`
      )
    ),
    [ProductType.allotment]: Handlebars.compile(
      renderMarkdownToHtml(
        `________________________________________________

      {{#each hotelRoomReservation}}
        **Hotel:** {{name}}
        **Address:** {{address}}
        **Phone:** {{phone}}
        **email:** {{email}}
        **Room:** {{roomName}}
        **Booking Code:** {{bookingCode}}
        **Check-in:** {{checkIn}}
        **Check-out:** {{checkOut}}
        **Notes:** {{notes}}
      {{/each}}
      ________________________________________________`
      )
    ),
  }),
  abstractReviews: Handlebars.compile(
    renderMarkdownToHtml(`
{{#each reviews}}
**{{reviewerName}}:** {{note}}
{{/each}}`)
  ),
};

export const getTemplate = async (listID, templateID, eventId) => {
  try {
    const response = await aws.standardAPI.post('aimlambdaproxy', '/mailup', {
      body: {
        method: 'get',
        eventId: eventId,
        url: `https://services.mailup.com/API/v1.1/Rest/ConsoleService.svc/Console/List/${listID}/Email/${templateID}`,
      },
    });

    if (response) {
      return response.Content;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const findValue = ({ obj, valueObj, preTitles = [] }) => {
  console.log('obj,valueObj', obj, valueObj);
  let reducedPrefixObj = obj;
  if (valueObj.keyPrefix) {
    reducedPrefixObj = extractSubPropByKeyWithDots(obj, valueObj.keyPrefix);
  }
  console.log('reducedPrefixObj', reducedPrefixObj);
  if (valueObj.type === 'required') {
    const value = extractSubPropByKeyWithDots(reducedPrefixObj, valueObj.key);
    if (valueObj.key === 'title') {
      const preTitle = preTitles.find((pt) => pt.key === value)?.preTitle || '';
      const opt = preTitles.find((p) => p.key === reducedPrefixObj.title);
      return preTitle ? `${preTitle} ${opt?.title || ''}` : opt?.title || '';
    } else {
      return value;
    }
  }
  // for custom or standard fields
  const item = reducedPrefixObj.fieldValues.items.find(
    (i) => i.fieldValueFieldDefinitionId === valueObj.value
  );

  const value = item ? JSON.parse(item.value) : '';

  return value?.value || value;
};

const extractSubPropByKeyWithDots = (object, dottedKey) =>
  dottedKey.split('.').reduce((a, b) => a?.[b], object);

// md.render(text.replace(/(?:\r\n|\r|\n)/g, '<br>'));

const getBodyAndGreetingsFnFromTemplate = ({ emailTemplate }) => {
  const bodyTemplate = Handlebars.compile(
    renderMarkdownToHtml(emailTemplate.body)
  );
  const greetingsTemplate = Handlebars.compile(
    renderMarkdownToHtml(emailTemplate.greetings)
  );

  return { bodyTemplate, greetingsTemplate };
};

const linkService = (eventConfiguration, myareaLink, intl) =>
  `<a style="border-radius: 3px;
          border: 2px solid ${eventConfiguration.secondaryColor};
          display: inline-block;
          padding: 5px;
          margin: 1em 0em 1em 0em;
          text-transform: uppercase;
          cursor: pointer;
          text-decoration: unset;
          color: black;"
      href="${myareaLink}"
      target="_blank"
    >
    ${intl.formatMessage({
      description: 'mail services recap go to my services button text',
      defaultMessage: 'go to my services',
    })}
  </a>
   <table style="margin-top: 15px;padding-top: 15px;border-top: 1px solid #EAEAEC;" role="presentation">
      <tr>
        <td>
          <p style="font-family: Arial, sans-serif; font-size: 13px;">${intl.formatMessage(
            {
              description: 'mail services recap go to my services button text',
              defaultMessage:
                'If the page does not display correctly when you click the GO TO MY SERVICES button, please copy and paste the following link into your browser:',
            }
          )}</p>
          <p style="font-family: Arial, sans-serif; font-size: 13px; color: grey; padding-left: 10px; padding-right: 10px;">${myareaLink}</p>
        </td>
      </tr>
    </table>
    `;

const linkWelcome = (eventConfiguration, welcomeLink, intl) =>
  `<a style="border-radius: 3px;
          border: 2px solid ${eventConfiguration.secondaryColor};
          display: inline-block;
          padding: 5px;
          margin: 1em 0em 1em 0em;
          text-transform: uppercase;
          cursor: pointer;
          text-decoration: unset;
          color: black;"
      href="${welcomeLink}"
      target="_blank"
    >
    ${intl.formatMessage({
      description: 'mail template go to welcome button text',
      defaultMessage: 'welcome letter',
    })}
  </a>
   <table style="margin-top: 15px;padding-top: 15px;border-top: 1px solid #EAEAEC;" role="presentation">
      <tr>
        <td>
          <p style="font-family: Arial, sans-serif; font-size: 13px;">${intl.formatMessage(
            {
              description: 'mail services recap go to my services button text',
              defaultMessage:
                'If the page does not display correctly when you click the WELCOME LETTER button, please copy and paste the following link into your browser:',
            }
          )}</p>
          <p style="font-family: Arial, sans-serif; font-size: 13px; color: grey; padding-left: 10px; padding-right: 10px">${welcomeLink}</p>
        </td>
      </tr>
    </table>
    `;

const facultySummary = (participation) => {
  if (!participation) {
    return '';
  }
  let htmlSummary = `
  <ul style='
    list-style-type: none;
    margin: 0;
    padding: 0;
  '>`;
  // const icons = await getIcons();
  const iconCompleted = `https://aims3bucketcommon-dev.s3.eu-west-1.amazonaws.com/public/email-template/iconCheckbox.png`;
  const imgCompleted = `<img style='vertical-align: bottom' width='20px' src=${iconCompleted} alt='img completed' />`;

  const iconNotCompleted = `https://aims3bucketcommon-dev.s3.eu-west-1.amazonaws.com/public/email-template/iconRemove.png`;
  const imgNotCompleted = `<img style='vertical-align: bottom' width='20px' src=${iconNotCompleted} alt='img not completed' />`;

  if (participation?.participationServices?.participationFacultyResponse) {
    htmlSummary += `**Participation:** completed ${imgCompleted}\n`;
  } else {
    htmlSummary += `**Participation:** not completed ${imgNotCompleted}\n`;
  }
  // }

  if (
    participation?.participationServices?.isTravelIncluded ||
    participation?.participationServices?.isTransferAIncluded ||
    participation?.participationServices?.isTransferRIncluded
  ) {
    if (participation?.participationServices?.travelFacultyResponse) {
      htmlSummary += `**Travel:** completed ${imgCompleted}\n`;
    } else {
      htmlSummary += `**Travel:** not completed ${imgNotCompleted}\n`;
    }
  }

  if (participation?.participationServices?.allotmentIncluded) {
    if (participation?.participationServices?.allotmentFacultyResponse) {
      htmlSummary += `**Allotment:** completed ${imgCompleted}\n`;
    } else {
      htmlSummary += `**Allotment:** not completed ${imgNotCompleted}\n`;
    }
  }

  if (participation?.participationServices?.additionalServicesIncluded) {
    if (
      participation?.participationServices?.additionalServicesFacultyResponse
    ) {
      htmlSummary += `**Additional services:** completed ${imgCompleted}\n`;
    } else {
      htmlSummary += `**Additional services:** not completed ${imgNotCompleted}\n`;
    }
  }

  if (participation?.participationServices?.accreditationMaterialIncluded) {
    if (
      participation?.participationServices?.accreditationMaterialFacultyResponse
    ) {
      htmlSummary += `**Accreditation Material:** completed ${imgCompleted}\n`;
    } else {
      htmlSummary += `**Accreditation Material:** not completed ${imgNotCompleted}\n`;
    }
  }

  if (participation?.participationServices?.aifaDataRequiredIncluded) {
    if (participation?.participationServices?.aifaDataFacultyResponse) {
      htmlSummary += `**AIFA:** completed ${imgCompleted}\n`;
    } else {
      htmlSummary += `**AIFA:** not completed ${imgNotCompleted}\n`;
    }
  }
  return renderMarkdownToHtml(`${htmlSummary}`);
};

const getParticipationData = async ({
  emailTemplate,
  participations,
  preTitles,
}) => {
  const participationsData = await getParticipationAndReplaceCustomFields({
    emailTemplate,
    participations,
    preTitles,
  });

  const promises = participationsData.map(
    ({ participation, subject, body, greetings }) => {
      return {
        subject,
        body,
        greetings,
        participation,
        contact: {
          id: participation.id,
          email: participation.email,
          givenName: participation.givenName,
          familyName: participation.familyName,
        },
      };
    }
  );

  return Promise.all(promises);
};

const prepareDataForAbstractsMails = async ({
  emailTemplate,
  abstracts,
  preTitles,
}) => {
  const participations = abstracts.map((abstract) => {
    return {
      ...abstract?.participant,
      singleAbstract: abstract,
    };
  });

  const participationsData = await getParticipationAndReplaceCustomFields({
    emailTemplate,
    participations,
    preTitles,
  });

  const promises = participationsData.map(
    ({ participation, subject, body, greetings }) => {
      const reviews = participation?.singleAbstract?.reviews?.items?.map(
        (item) => {
          return {
            reviewerName: `${item?.reviewer?.participant?.givenName} ${item?.reviewer?.participant?.familyName}`,
            note: JSON.parse(item?.reviewResponse || {})?.note || '-',
          };
        }
      );

      const newObj = {
        subject,
        body,
        greetings,
        //me
        participation: {
          ...participation,
          reviewsString: hbTemplates.abstractReviews({ reviews }),
        },
        contact: {
          id: participation.id,
          email: participation.email,
          givenName: participation.givenName,
          familyName: participation.familyName,
        },
      };

      return newObj;
    }
  );

  return Promise.all(promises);
};

const getParticipationAndReplaceCustomFields = async ({
  emailTemplate,
  participations,
  preTitles,
}) => {
  const templateFieldMapping = JSON.parse(emailTemplate.fieldMapping);

  const participationsObjs = await getParticipation({
    participations,
    emailTemplate,
  });

  const {
    bodyTemplate,
    greetingsTemplate,
  } = getBodyAndGreetingsFnFromTemplate({ emailTemplate });

  return Object.values(participationsObjs).map((participation) => {
    const { body = {}, greetings = {} } = templateFieldMapping || {};

    const bodyValues = Object.entries(body).reduce(
      (res, [key, valueObj]) => ({
        ...res,
        [key.replaceAll(' ', '_')]: findValue({
          obj: participation,
          valueObj,
          preTitles,
        }),
      }),
      {}
    );

    const greetingsValues = Object.entries(greetings).reduce(
      (res, [key, valueObj]) => ({
        ...res,
        [key.replaceAll(' ', '_')]: findValue({
          obj: participation,
          valueObj,
          preTitles,
        }),
      }),
      {}
    );

    const resultBody = bodyTemplate(bodyValues);
    const resultGreetings = greetingsTemplate(greetingsValues);

    return {
      subject: emailTemplate.subject,
      body: resultBody,
      greetings: resultGreetings,
      participation,
      contact: {
        id: participation.id,
        email: participation.email,
        givenName: participation.givenName,
        familyName: participation.familyName,
      },
    };
  });
};

const prepareEmailsHtml = ({
  emailData,
  template,
  eventName,
  link,
  eventId,
  eventConfiguration,
  siteDomain,
  intl,
}) => {
  // secrets
  return emailData.map(
    ({
      product: {
        invoiceDetailsString,
        paymentInfoString,
        bookingsString,
        payment: { paymentId } = {},
      } = {},
      abstract: { reviewsString } = {},
      subject,
      body,
      greetings,
      allTicketsFiles,
      isTemplateTicketsOn,
      participation, //valorized on if emailData is type of pax, non for sponsors
      sponsor: { sponsorBookingsString } = {},
      abstractTerm,
    }) => {
      let myareaLink = '';
      let welcomeLink = '';
      if (participation) {
        let params;
        if (
          participation?.participationServices?.publicServicesPageAccessCode
        ) {
          params = new URLSearchParams({
            code:
              participation.participationServices.publicServicesPageAccessCode,
          }).toString();
        }
        myareaLink = `${siteDomain}/events/${eventId}/my-services/${
          participation.participationServices?.id
        }${params ? `?${params}` : ''}`;
        welcomeLink = `${siteDomain}/events/${eventId}/my-services/${
          participation?.participationServices?.id
        }/welcome${params ? `?${params}` : ''}`;
      }

      const emailMessage = template
        .replace(/##MAIL_SUBJECT##/g, subject)
        .replace(/##MAIL_CONTENT##/g, body)
        .replace(/##MAIL_GREETINGS##/g, greetings)
        // .replace(/{URL}/g, link)
        // // needed if the url param is encoded inside href (due to markdown)
        // .replace(/%7BURL%7D/g, link)
        .replace(/{EVENT_NAME}/g, eventName)
        .replace(
          /{LINKSERVICE}/g,
          linkService(eventConfiguration, myareaLink, intl)
        )
        .replace(
          /{LINKWELCOME}/g,
          linkWelcome(eventConfiguration, welcomeLink, intl)
        )
        .replace(
          /{INVOICE_DETAILS}/g,
          renderMarkdownToHtml(invoiceDetailsString)
        )
        .replace(/{PAYMENT_INFO}/g, paymentInfoString)
        .replace(/{BOOKINGS}/g, bookingsString)
        .replace(/{PAYMENT_ID}/g, paymentId)
        .replace(/{FACULTYSUMMARY}/g, facultySummary(participation))
        .replace(/{ABSTRACTREVIEWS}/g, reviewsString)
        .replace(/{SPONSORBOOKINGS}/g, sponsorBookingsString)
        .replace(/{DEADLINE_DATE}/g, abstractTerm);
      return emailMessage;
    }
  );
};

const getAbstractsData = async ({
  emailTemplate,
  abstracts,
  eventData,
  intl,
}) => {
  const preTitles = eventData?.preTitles?.items || [];

  const abstractsData = await getAbstractInfos({
    abstracts,
  });

  const abstractsItems = Object.values(abstractsData);
  const templateFieldMapping = JSON.parse(emailTemplate.fieldMapping);

  let participationsObjs = await getParticipation({
    participations: [
      // we use a set to prevent duplicated participation id queries
      ...new Set(
        abstractsItems.map(({ participant }) => ({ id: participant?.id }))
      ),
    ],
    emailTemplate,
  });
  const participationItems = Object.values(participationsObjs);

  const { bodyTemplate, greetingsTemplate } = getBodyAndGreetingsFnFromTemplate(
    {
      emailTemplate,
    }
  );

  return abstractsItems.map((abstract) => {
    const participation = participationItems.find(
      (p) => p.id === abstract.participant?.id
    );
    const { body = {}, greetings = {} } = templateFieldMapping || {};

    const nextAbstractObject = {
      ...abstract,
      status: Object.values(constants.AbstractStatus)
        .find((as) => as.id === abstract.status)
        ?.label(intl),
      participation,
    };

    const bodyValues = Object.entries(body).reduce(
      (res, [key, valueObj]) => ({
        ...res,
        [key.replaceAll(' ', '_')]: findValue({
          obj: nextAbstractObject,
          valueObj,
          preTitles,
        }),
      }),
      {}
    );

    const greetingsValues = Object.entries(greetings).reduce(
      (res, [key, valueObj]) => ({
        ...res,
        [key.replaceAll(' ', '_')]: findValue({
          obj: nextAbstractObject,
          valueObj,
          preTitles,
        }),
      }),
      {}
    );

    const resultBody = bodyTemplate(bodyValues);
    const resultGreetings = greetingsTemplate(greetingsValues);

    return {
      subject: emailTemplate.subject,
      body: resultBody,
      greetings: resultGreetings,
      abstract: nextAbstractObject,
      contact: {
        id: participation.id,
        email: participation.email,
        givenName: participation.givenName,
        familyName: participation.familyName,
      },
    };
  });
};

const getAbstractInfos = async ({ abstracts }) => {
  const abstractsData = await getAbstract({
    abstracts,
  });

  const nextProducts = buildAbstractReviewsHandleBarObj({
    abstractsData: abstractsData,
  });

  return nextProducts;
};

const getSponsorsData = async ({
  token,
  sponsors,
  emailTemplate,
  graphqlLimitless,
}) => {
  const sponsorObjs = await getSponsor({
    token,
    sponsors,
    graphqlLimitless,
  });

  const { bodyTemplate, greetingsTemplate } = getBodyAndGreetingsFnFromTemplate(
    {
      emailTemplate,
    }
  );

  // N.B.: custom fields replacements should be added here if needed (see replaceParticipationsCustomFields)

  return Object.values(sponsorObjs).map(
    ({
      name,
      address,
      city,
      postalCode,
      country,
      contactEmail,
      contactName,
      contactSurname,
      taxIdNumber,
      admins,
    }) => {
      const templateFieldMapping = JSON.parse(emailTemplate.fieldMapping);
      const { body = {}, greetings = {} } = templateFieldMapping || {};

      const firstAdmin = admins?.items?.[0];
      const nextSponsorObject = {
        name,
        address,
        city,
        postalCode,
        country,
        contactEmail: firstAdmin?.email || contactEmail,
        contactName: firstAdmin?.givenName || contactName,
        contactSurname: firstAdmin?.familyName || contactSurname,
        taxIdNumber,
      };

      const bodyValues = Object.entries(body).reduce(
        (prev, [key, valueObj]) => ({
          ...prev,
          [key.replaceAll(' ', '_')]: findValue({
            obj: nextSponsorObject,
            valueObj,
          }),
        }),
        {}
      );

      const greetingsValues = Object.entries(greetings).reduce(
        (prev, [key, valueObj]) => ({
          ...prev,
          [key.replaceAll(' ', '_')]: findValue({
            obj: nextSponsorObject,
            valueObj,
          }),
        }),
        {}
      );

      console.log('bodyValues, GreetingsValues', bodyValues, greetingsValues);
      return {
        subject: emailTemplate.subject,
        body: bodyTemplate(bodyValues),
        greetings: greetingsTemplate(greetingsValues),
        contact: {
          email: nextSponsorObject.contactEmail,
          givenName: nextSponsorObject.contactName,
          familyName: nextSponsorObject.contactSurname,
        },
      };
    }
  );
};
const getPurchasesData = async ({
  token,
  purchases,
  emailTemplate,
  graphqlLimitless,
}) => {
  const purchasesObj = await getPurchases({
    token,
    purchases,
    graphqlLimitless,
  });
  const purchasesItems = Object.values(purchasesObj);
  const sponsorObjs = await getSponsor({
    token,
    sponsors: [
      // we use a set to prevent duplicated participation id queries
      ...new Set(
        purchasesItems.map(({ purchaseSponsorId }) => ({
          id: purchaseSponsorId,
        }))
      ),
    ],
    graphqlLimitless,
  });
  const sponsorItems = Object.values(sponsorObjs);

  const { bodyTemplate, greetingsTemplate } = getBodyAndGreetingsFnFromTemplate(
    {
      emailTemplate,
    }
  );

  // N.B.: custom fields replacements should be added here if needed (see replaceParticipationsCustomFields)

  return Object.values(purchasesObj).map((purchase) => {
    const {
      name,
      address,
      city,
      postalCode,
      country,
      contactEmail,
      contactName,
      contactSurname,
      taxIdNumber,
      admins,
      billingInformation,
    } = sponsorItems.find((s) => s?.id === purchase?.purchaseSponsorId) || {};

    const templateFieldMapping = JSON.parse(emailTemplate.fieldMapping);
    const { body = {}, greetings = {} } = templateFieldMapping || {};

    const firstAdmin = admins?.items?.[0];
    const nextSponsorObject = {
      name,
      address,
      city,
      postalCode,
      country,
      contactEmail: firstAdmin?.email || contactEmail,
      contactName: firstAdmin?.givenName || contactName,
      contactSurname: firstAdmin?.familyName || contactSurname,
      taxIdNumber,
      purchase: {
        ...purchase,
        payment: {
          ...purchase?.payment,
          BillingInformation:
            purchase?.payment?.BillingInformation || billingInformation,
        },
      },
    };

    const bodyValues = Object.entries(body).reduce(
      (prev, [key, valueObj]) => ({
        ...prev,
        [key.replaceAll(' ', '_')]: findValue({
          obj: nextSponsorObject,
          valueObj,
        }),
      }),
      {}
    );

    const greetingsValues = Object.entries(greetings).reduce(
      (prev, [key, valueObj]) => ({
        ...prev,
        [key.replaceAll(' ', '_')]: findValue({
          obj: nextSponsorObject,
          valueObj,
        }),
      }),
      {}
    );

    return {
      subject: emailTemplate.subject,
      body: bodyTemplate(bodyValues),
      greetings: greetingsTemplate(greetingsValues),
      contact: {
        email: nextSponsorObject.contactEmail,
        givenName: nextSponsorObject.contactName,
        familyName: nextSponsorObject.contactSurname,
      },
    };
  });
};

const getProductInfos = async ({ products, eventData }) => {
  const {
    gateways: { items: gatewaysList },
  } = eventData;

  const productsData = await getProduct({
    products,
  });

  const bookingInfo = await getBookingInfo({
    productsData,
  });

  Object.entries(productsData).forEach(([key, product]) => {
    productsData[key] = { ...product, bookingInfo: bookingInfo[key] };
  });

  const nextProducts = buildProductOrderHandleBarObj({
    productsData,
    gatewaysList,
  });

  return nextProducts;
};

const buildProductOrderHandleBarObj = ({ productsData, gatewaysList }) =>
  Object.fromEntries(
    Object.entries(productsData).map(([key, value]) => {
      const newValue = {
        ...value,
        invoiceDetailsString: hbTemplates.invoiceDetails(
          value?.payment?.BillingInformation || {}
        ),
        paymentInfoString: hbTemplates.paymentInfo({
          ...(gatewaysList.find((i) => i.service === value.serviceType) || {}),
          paymentType: PaymentTypes[value.payment?.paymentType],
          isBankTransfer: value.payment?.paymentType === 'bankTransfer',
        }),
        bookingsString: bookingsBuilder(value),
      };
      return [key, newValue];
    })
  );

const bookingsBuilder = ({ bookingInfo, serviceType }) => {
  try {
    const bookingInfoHbObj = paymentInfoFnMap[serviceType](bookingInfo);
    return hbTemplates.bookings[serviceType](bookingInfoHbObj);
  } catch (e) {
    console.error('bookingsBuilder error', e);
    return '';
  }
};

const paymentInfoFnMap = {
  [ProductType.registration]: (bookingInfo) => ({
    label: bookingInfo?.feeBracket?.feeDateRange?.label,
    profileName: bookingInfo?.profile?.name,
    start: format(
      new Date(bookingInfo?.feeBracket?.feeDateRange?.start),
      'PPpp'
    ),
    end: format(new Date(bookingInfo?.feeBracket?.feeDateRange?.end), 'PPpp'),
  }),
  [ProductType.scientificEvents]: (bookingInfo) => ({
    title: bookingInfo?.title,
    description: bookingInfo?.description,
    date: format(new Date(bookingInfo?.dateService), 'dd/MM/yyyy'),
    startTime: bookingInfo?.startTime,
    endTime: bookingInfo?.endTime,
    locationName: bookingInfo?.locationName,
    locationAddress: bookingInfo?.locationAddress,
  }),
  [ProductType.socialEvents]: (bookingInfo) => ({
    title: bookingInfo?.title,
    description: bookingInfo?.description,
    date: format(new Date(bookingInfo?.dateService), 'dd/MM/yyyy'),
    startTime: bookingInfo?.startTime,
    endTime: bookingInfo?.endTime,
    locationName: bookingInfo?.locationName,
    locationAddress: bookingInfo?.locationAddress,
  }),
  [ProductType.allotment]: (bookingInfo) => ({
    hotelRoomReservation: bookingInfo?.hotelRoomReservation?.items.map(
      (reservation) => ({
        ...reservation,
        ...reservation?.hotelRoom?.hotel,
        checkIn: format(new Date(reservation?.startDate), 'dd/MM/yyyy'),
        checkOut: format(new Date(reservation?.endDate), 'dd/MM/yyyy'),
        address:
          `${reservation?.hotelRoom?.hotel?.address} ${
            reservation?.hotelRoom?.hotel?.streetNumber || ''
          }` || '-',
        roomName:
          reservation?.hotelRoom?.frontofficeName ||
          reservation?.hotelRoom?.name,
      })
    ),
  }),
};

const buildAbstractReviewsHandleBarObj = ({ abstractsData }) =>
  Object.fromEntries(
    Object.entries(abstractsData).map(([key, value]) => {
      const reviews = value?.reviews?.items?.map((item) => {
        return {
          reviewerName: `${item?.reviewer?.participant?.givenName} ${item?.reviewer?.participant?.familyName}`,
          note:
            (item?.reviewResponse && JSON.parse(item?.reviewResponse)?.note) ||
            '-',
        };
      });
      const newValue = {
        ...value,
        reviewsString: hbTemplates.abstractReviews({ reviews }),
      };
      return [key, newValue];
    })
  );

const getProductsData = async ({ emailTemplate, products, eventData }) => {
  const preTitles = eventData?.preTitles?.items || [];
  //ordine
  const productsData = await getProductInfos({
    products,
    eventData,
  });

  const productItems = Object.values(productsData);
  const templateFieldMapping = JSON.parse(emailTemplate.fieldMapping);

  let participationsObjs = await getParticipation({
    participations: [
      // we use a set to prevent duplicated participation id queries
      ...new Set(productItems.map(({ clientId }) => ({ id: clientId }))),
    ],
    emailTemplate,
  });
  const participationItems = Object.values(participationsObjs);

  const { bodyTemplate, greetingsTemplate } = getBodyAndGreetingsFnFromTemplate(
    {
      emailTemplate,
    }
  );

  return productItems.map((product) => {
    const participation = participationItems.find(
      (p) => p.id === product.clientId
    );
    const { body = {}, greetings = {} } = templateFieldMapping || {};

    const nextProductObject = {
      ...product,
      payment: {
        ...product?.payment,
        BillingInformation:
          product?.payment?.BillingInformation ||
          participation?.billingInformation,
      },
      participation,
    };

    const bodyValues = Object.entries(body).reduce(
      (res, [key, valueObj]) => ({
        ...res,
        [key.replaceAll(' ', '_')]: findValue({
          obj: nextProductObject,
          valueObj,
          preTitles,
        }),
      }),
      {}
    );

    const greetingsValues = Object.entries(greetings).reduce(
      (res, [key, valueObj]) => ({
        ...res,
        [key.replaceAll(' ', '_')]: findValue({
          obj: nextProductObject,
          valueObj,
          preTitles,
        }),
      }),
      {}
    );

    const resultBody = bodyTemplate(bodyValues);
    const resultGreetings = greetingsTemplate(greetingsValues);

    return {
      subject: emailTemplate.subject,
      body: resultBody,
      greetings: resultGreetings,
      product: nextProductObject,
      contact: {
        id: participation.id,
        email: participation.email,
        givenName: participation.givenName,
        familyName: participation.familyName,
      },
    };
  });
};

export default {
  getTemplate,
  renderMarkdownToHtml,
  getParticipationData,
  prepareEmailsHtml,
  getSponsorsData,
  getPurchasesData,
  prepareDataForAbstractsMails,
  getAbstractInfos,
  getProductsData,
  getAbstractsData,
  findValue,
};
