import { format, parseISO } from 'date-fns';
import jsPDF from 'jspdf';
// eslint-disable-next-line unused-imports/no-unused-imports
import autoTable from 'jspdf-autotable'; //needed to use doc.autoTable
import logo_base64 from './pdfHelper/header_logo.js';
import utilities from './utilities.js';
// eslint-disable-next-line unused-imports/no-unused-imports
// import html2canvas from 'html2canvas';

const PdfElementTypes = {
  IMAGE: 'image',
  TEXT: 'text',
  LINE: 'line',
};

const TOTAL_PAGE_EXPR = '{total_pages_count_string}';

const createImageElement = (
  data,
  { value, imageType, x, y, width, height }
) => {
  const { doc } = data;
  if (value) {
    doc.addImage(value, imageType, x, y, width, height);
  }
};

const createTextElement = (data, { value, fontSize, color, x, y }) => {
  const { doc } = data;
  if (value) {
    doc.setFontSize(fontSize);
    doc.setTextColor(color);
    doc.text(value, x, y);
  }
};

const createLineElement = (data, { x, y, width }) => {
  const { doc } = data;
  doc.line(x, y, x + width, y);
};

const drawHeader = (data, items = []) => {
  for (const pdfItem of items) {
    if (pdfItem.type === PdfElementTypes.IMAGE) {
      createElementObjTransformer(data, pdfItem, createImageElement);
    } else if (pdfItem.type === PdfElementTypes.TEXT) {
      createElementObjTransformer(data, pdfItem, createTextElement);
    } else if (pdfItem.type === PdfElementTypes.LINE) {
      createElementObjTransformer(data, pdfItem, createLineElement);
    }
  }
};

const createElementObjTransformer = (data, element, createElementFn) => {
  const nextElement = { ...element };
  for (const property in element) {
    if (typeof element[property] === 'function') {
      nextElement[property] = element[property](data);
    }
  }
  createElementFn(data, nextElement);
};

const drawFooter = (data, items = []) => {
  for (const pdfItem of items) {
    if (pdfItem.type === PdfElementTypes.IMAGE) {
      createElementObjTransformer(data, pdfItem, createImageElement);
    } else if (pdfItem.type === PdfElementTypes.TEXT) {
      createElementObjTransformer(data, pdfItem, createTextElement);
    } else if (pdfItem.type === PdfElementTypes.LINE) {
      createElementObjTransformer(data, pdfItem, createLineElement);
    }
  }
  // Footer
  // const pageSize = doc.internal.pageSize;
  // const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight(); //da togliere???
  // var str = 'Page ' + doc.internal.getNumberOfPages();
  // // Total page number plugin
  // if (typeof doc.putTotalPages === 'function') {
  //   str = str + ' of ' + TOTAL_PAGE_EXPR;
  // }
};

const customDrawPage = (data, pageHeader, pageFooter) => {
  drawHeader(data, pageHeader);
  drawFooter(data, pageFooter);
};

const exportTablePdf = ({
  tableName,
  tables,
  pageHeader,
  pageFooter,
  columnStyles: defaultColumnStyles,
  ...rest
}) => {
  let isFirstPage = true;
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'px',
  });
  //config data
  doc.setFont('Helvetica');

  for (const {
    body,
    head,
    margin,
    columnStyles,
    innerPageHeader,
    lineIndexes = [],
    shouldDrawLine = () => true,
    shouldDrawLineBefore = () => false,
    ...tablesRest
  } of tables) {
    if (!isFirstPage) {
      doc.addPage();
    } else {
      isFirstPage = false;
    }
    doc.autoTable({
      body,
      head: head?.map((headRow) =>
        headRow?.map?.((headCol) => utilities.truncateString(headCol, 30))
      ),
      margin,
      columnStyles: defaultColumnStyles ? defaultColumnStyles : columnStyles,
      tableWidth: 'wrap',
      horizontalPageBreak: true,
      horizontalPageBreakRepeat: 0,
      didDrawPage: (data) =>
        customDrawPage(data, innerPageHeader || pageHeader, pageFooter),
      didDrawCell: (data) => {
        shouldDrawLineBefore(data, lineIndexes) &&
          data.doc.line(
            data.cursor.x,
            data.cursor.y + data.row.height,
            data.cursor.x + data.cell.width,
            data.cursor.y + data.row.height
          );
        shouldDrawLine(data, lineIndexes) &&
          data.doc.line(
            data.cursor.x,
            data.cursor.y + data.row.height,
            data.cursor.x + data.cell.width,
            data.cursor.y + data.row.height
          );
      },
      theme: 'plain',
      ...rest,
      ...tablesRest,
    });
  }

  if (typeof doc.putTotalPages === 'function' && tables.length) {
    doc.putTotalPages(TOTAL_PAGE_EXPR);
  }
  doc.save(`${tableName.tableTitle}.pdf`);
};

const exportHtmlPdf = (elements, title) => {
  const doc = new jsPDF();

  for (const pdfItem of elements) {
    if (pdfItem.type === PdfElementTypes.TEXT) {
      createElementObjTransformer({ doc }, pdfItem, createTextElement);
    }
  }
  doc.save(`${title}.pdf`);
};

const exportTablePdfWithStandardTemplate = ({
  tables,
  eventData,
  tableName,
  ...rest
}) => {
  let { name, city, start, end, eventOrder } = eventData;
  const orderCode =
    eventOrder?.items?.find((item) => item.codeName === 'event')?.code || '';
  const pageHeader = [
    {
      type: PdfElementTypes.TEXT,
      fontSize: 13,
      color: 40,
      // value: `${name}${(city && ` - ${city}`) || ''}, ${format(
      //   parseISO(start),
      //   'dd/MM'
      // )}`,
      value: `${name}${orderCode ? ` - ${orderCode}` : ''}`,
      x: (data) => data.settings.margin.left,
      y: 22,
    },
    {
      type: PdfElementTypes.TEXT,
      fontSize: 13,
      color: 40,
      value: `${(city && `${city.toUpperCase()} `) || ''}${format(
        parseISO(start),
        'dd/MM/yyyy'
      )} - ${format(parseISO(end), 'dd/MM/yyyy')}`,
      x: (data) => data.settings.margin.left,
      y: 42,
    },
    // {
    //   type: PdfElementTypes.TEXT,
    //   fontSize: 13,
    //   color: 40,
    //   value: `${format(parseISO(start), 'dd/MM/yyyy')} - ${format(
    //     parseISO(end),
    //     'dd/MM/yyyy'
    //   )}`,
    //   x: (data) => data.settings.margin.left + 70,
    //   y: 42,
    // },
    ...(tableName.tableTitle
      ? tableName.tableTitle.split('\r\n')?.map((item, index) => ({
          type: PdfElementTypes.TEXT,
          fontSize: 18,
          color: 40,
          value: item,
          x: (data) => data.settings.margin.left, // data.settings.margin.left + 250,
          y: () => 64 + index * 20, // 44,
        }))
      : []),
    ...(tableName.subTitle
      ? tableName.subTitle.split('\r\n')?.map((item, index) => ({
          type: PdfElementTypes.TEXT,
          fontSize: 14,
          color: 40,
          value: item,
          x: (data) => data.settings.margin.left, // data.settings.margin.left + 250,
          y: () => 84 + index * 20, // 44,
        }))
      : []),
    {
      type: PdfElementTypes.IMAGE,
      imageType: 'PNG',
      value: logo_base64,
      x: (data) =>
        data.doc.internal.pageSize.width - data.settings.margin.right - 130,
      y: 10,
      width: 130,
      height: 30,
    },
  ];

  const pageFooter = [
    {
      type: PdfElementTypes.TEXT,
      fontSize: 10,
      color: 40,
      value: format(new Date(), 'dd/MM/yyyy hh:mm:ss'),
      x: (data) => data.settings.margin.left,
      y: ({ doc }) => doc.internal.pageSize.height - 10,
    },
    {
      type: PdfElementTypes.TEXT,
      fontSize: 10,
      color: 40,
      value: 'AIM Group® - AIM Group International SA® - AIM S.p.A.®',
      x: (data) => data.settings.margin.left + 200,
      y: ({ doc }) => doc.internal.pageSize.height - 10,
    },
    {
      type: PdfElementTypes.LINE,
      x: (data) => data.settings.margin.left,
      y: ({ doc }) => doc.internal.pageSize.height - 25,
      width: (data) =>
        data.doc.internal.pageSize.width -
        data.settings.margin.left -
        data.settings.margin.right,
    },
    {
      type: PdfElementTypes.TEXT,
      fontSize: 10,
      color: 40,
      value: ({ doc }) => {
        var str = 'Page ' + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + TOTAL_PAGE_EXPR;
        }
        return str;
      },
      x: (data) => data.settings.margin.left + 510,
      y: ({ doc }) => doc.internal.pageSize.height - 10,
    },
  ];

  const tableMargin =
    // typeof tableName !== 'object'
    //   ? 80
    60 +
    tableName.tableTitle?.split('\r\n').length * 20 +
    (tableName.subTitle?.split('\r\n').length * 20 || 0);

  const nextTables = tables.map((t) => {
    let { name, city, start, end } = eventData;
    const innerPageHeader = t.title
      ? [
          {
            type: PdfElementTypes.TEXT,
            fontSize: 13,
            color: 40,
            // value: `${name}${(city && ` - ${city}`) || ''}, ${format(
            //   parseISO(start),
            //   'dd/MM'
            // )}`,
            value: `${name}${orderCode ? ` - ${orderCode}` : ''}`,
            x: (data) => data.settings.margin.left,
            y: 22,
          },
          {
            type: PdfElementTypes.TEXT,
            fontSize: 13,
            color: 40,
            value: `${(city && `${city.toUpperCase()} `) || ''}${format(
              parseISO(start),
              'dd/MM/yyyy'
            )} - ${format(parseISO(end), 'dd/MM/yyyy')}`,
            x: (data) => data.settings.margin.left,
            y: 42,
          },
          ...t.title.split('\r\n')?.map((item, index) => ({
            type: PdfElementTypes.TEXT,
            fontSize: 18,
            color: 40,
            value: item,
            x: (data) => data.settings.margin.left, // data.settings.margin.left + 250,
            y: () => 64 + index * 20, // 44,
          })),
          {
            type: PdfElementTypes.IMAGE,
            imageType: 'PNG',
            value: logo_base64,
            x: (data) =>
              data.doc.internal.pageSize.width -
              data.settings.margin.right -
              130,
            y: 10,
            width: 130,
            height: 30,
          },
        ]
      : null;

    return {
      ...t,
      innerPageHeader,
      margin: { top: tableMargin },
    };
  });
  return exportTablePdf({
    tableName,
    tables: nextTables,
    pageHeader,
    pageFooter,
    ...rest,
  });
};

export default {
  exportTablePdf,
  exportTablePdfWithStandardTemplate,
  PdfElementTypes,
  exportHtmlPdf,
};
