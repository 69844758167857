import constants from '../constants';
import aws from '../aws';
const { ProductType } = constants;
const emailTemplateQuery = /* GraphQL */ `
  query EmailTemplateByEventIdAndTrigger(
    $trigger: String
    $emailTemplateEventId: ID!
  ) {
    emailTemplateByEventIdAndTrigger(
      emailTemplateEventId: $emailTemplateEventId
      trigger: { eq: $trigger }
    ) {
      items {
        id
        subject
        trigger
        greetings
        body
        fieldMapping
        isActive
        isRegistrationAttachmentIncluded
        isAllotmentAttachmentIncluded
        isAdditionalServicesAttachmentIncluded
        isTravelAttachmentIncluded
        event {
          id
          name
          preTitles {
            items {
              id
              key
              preTitle
              title
            }
          }
          mailupConfig {
            sender
            senderName
            siteDomain
            listId
            templateId
            smtpPassword
            smtpUser
          }
          configuration {
            id
            font
            primaryColor
            secondaryColor
            primaryButtonVariant
            primaryButtonBorderRadius
            secondaryButtonVariant
            secondaryButtonBorderRadius
            background
          }
          gateways {
            items {
              paymentType
              service
              iban
              ibanCausal
              addressedTo
              shop {
                name
                gatewayType
              }
            }
          }
        }
      }
    }
  }
`;

export const getEmailTemplate = async ({ eventId, trigger }) => {
  const { data } = await aws.API.graphql({
    query: emailTemplateQuery,
    variables: { emailTemplateEventId: eventId, trigger },
  });

  if (data.emailTemplateByEventIdAndTrigger.items.length === 0) {
    throw new Error(
      `No email template found for event ${eventId} and trigger ${trigger}`
    );
  }

  const firstItem = data.emailTemplateByEventIdAndTrigger.items?.[0];
  const result = {
    templateId: firstItem.id,
    eventData: firstItem.event,
    emailTemplate: firstItem,
    mailupConfig: firstItem.event.mailupConfig,
  };

  return result;
};

export const getParticipation = async ({ participations, emailTemplate }) => {
  const createSingleQuery = participations.map(
    ({ id }) => `
    alias_${id.replace(/-/g, '_')}: getParticipation(id: "${id}") {
      id
      title
      givenName
      familyName
      email
      username
      typology {
        id
        name
      }
      profile {
        id
        name
        cluster
      }
      billingInformation {
        id
        invoiceTo
        name
        surname
        address
        city
        postalCode
        region
        province
        country
        countryIsoCode
        vatCode
        taxCode
        reference
        email
        pec
        phone
        invoiceToPublicAuthority
        uniqueCode
        ipaCode
        isSplitPayment
        isVatEvent
      }
      participationServices {
        id
        publicServicesPageAccessCode
        participationFacultyResponse
        aifaAccreditationMaterialFacultyResponse
        aifaDataFacultyResponse
        allotmentFacultyResponse
        travelFacultyResponse
        additionalServicesFacultyResponse
        allotmentIncluded
        additionalServicesIncluded
        aifaAccreditationMaterialIncluded
        participationIncluded
        isTravelIncluded
        isTransferAIncluded
        isTransferRIncluded
      }
      phone
      cognitoUserId
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
        }
      }
      ${
        emailTemplate.isRegistrationAttachmentIncluded
          ? `registrationAttachments {
        items {
          attachmentFile {
            id
            originalName
            size
            extension
          }
        }
      }`
          : ''
      }
      ${
        emailTemplate.isAdditionalServicesAttachmentIncluded
          ? `additionalServicesAttachments {
        items {
          attachmentFile {
            id
            originalName
            size
            extension
          }
        }
      }`
          : ''
      }
  ${
    emailTemplate.isAllotmentAttachmentIncluded
      ? `
      allotmentAttachments {
        items {
          attachmentFile {
            id
            originalName
            size
            extension
          }
        }
      }`
      : ''
  }
  ${
    emailTemplate.isTravelAttachmentIncluded
      ? `
      travelAttachments {
        items {
          attachmentFile {
            id
            originalName
            size
            extension
          }
        }
      }
    `
      : ''
  }  
  }`
  );

  const queries = createSingleQuery.join('');

  if (!queries) return {};

  const query = `query GetAllParticipations { ${queries} }`;

  const { data } = await aws.API.graphql({ query });
  return data;
};

export const getProduct = async ({ products }) => {
  const createSingleQuery = products.map(({ id }) => {
    if (!id) return '';

    return `
  alias_${id.replace(/-/g, '_')}: getProduct(id: "${id}") {
    id
    productId
    serviceId
    serviceType
    clientId
    clientType
    payment {
      id
      amount
      paymentStatus
      paymentId
      paymentType
      createdAt
      BillingInformation {
        id
        invoiceTo
        name
        surname
        address
        postalCode
        city
        region
        country
        vatCode
        taxCode
      }
    }
  }
    `;
  });

  const queries = createSingleQuery.join('');

  if (!queries) return {};

  const query = `query GetAllProducts { ${queries} }`;

  const { data } = await aws.API.graphql({ query });
  return data;
};
export const getAbstract = async ({ abstracts }) => {
  const createSingleQuery = abstracts.map(({ id }) => {
    if (!id) return '';

    return `
  alias_${id.replace(/-/g, '_')}: getAbstractDocument(id: "${id}") {
    id
    code
    title
    status
    participant {
      id
    }
    reviews {
      items {
        id
        status
        createdAt
        updatedAt
        scores
        reviewResponse
        reviewer {
          id
          participant {
            id
            email
            familyName
            givenName
            isDeleted
          }
        }
      }
    }
  }
    `;
  });

  const queries = createSingleQuery.join('');

  if (!queries) return {};

  const query = `query GetAllAbstracts { ${queries} }`;

  const { data } = await aws.API.graphql({ query });
  return data;
};

const getRegistrationQuery = (alias, id) => `
  ${alias}: getProfileFeeBracket(id: "${id}") {
    id
    priceOnAir
    priceOnSite
    profile {
      name
    }
    feeBracket {
      id
      feeDateRange {
        start
        end
        label
        fee {
          currency
        }
      }
    }
  }
`;

const getAdditionalServicesQuery = (alias, id) => `
  ${alias}: getAdditionalServices(id: "${id}") {
    id
    title
    description
    dateService
    startTime
    endTime
    dateService
    locationName
    locationAddress
  }
  `;

const getAllotmentQuery = (alias, id) => `
  ${alias}: getHotelReservation(id: "${id}") {
      id
      status
      event {
        name
      }
      isDeleted
      hotelRoomReservation {
        items {
          id
          bookingCode
          depositAmount
          balanceAmount
          startDate
          endDate
          isDeleted
          notes
          guests {
            items {
              id
              givenName
              familyName
              phone
              country
            }
          }
          hotelRoom {
            id
            name
            frontofficeName
            hotel {
              id
              address
              city
              cityTax
              country
              latitude
              longitude
              name
              region
              province
              postalCode
              phone
              email
              stars
              route
              streetNumber
            }
          }
          notes
          bookingCode
          hotelEventVenture {
            hotel {
              name
              address
              city
            }
          }
        }
      }
    }
`;

const serviceTypeQueryMap = {
  [ProductType.registration]: getRegistrationQuery,
  [ProductType.allotment]: getAllotmentQuery,
  [ProductType.socialEvents]: getAdditionalServicesQuery,
  [ProductType.scientificEvents]: getAdditionalServicesQuery,
};

export const getBookingInfo = async ({ productsData }) => {
  const queries = Object.entries(productsData)
    .map(([key, { productId, serviceType }]) => {
      try {
        return serviceTypeQueryMap[serviceType](key, productId); // key is already an alias
      } catch (e) {
        console.error('addPaymentSpecs error', e);
        return '';
      }
    })
    .join('');

  if (!queries) return {};

  const query = `query GetTuttCoz { ${queries} }`;

  const { data } = await aws.API.graphql({ query });
  return data;
};

export const getSponsor = async ({ sponsors }) => {
  const createSingleQuery = sponsors.map(
    ({ id }) => `
  alias_${id.replace(/-/g, '_')}: getSponsor(id: "${id}") {
    id
    name
    address
    city
    postalCode
    country
    contactEmail
    contactName
    contactSurname
    taxIdNumber
    admins(filter: { isDeleted: { ne: true } }) {
      items {
        email
        givenName
        familyName
      }
    }
    billingInformation {
      id
      invoiceTo
      name
      surname
      address
      city
      postalCode
      region
      province
      country
      countryIsoCode
      vatCode
      taxCode
      reference
      email
      pec
      phone
      invoiceToPublicAuthority
      uniqueCode
      ipaCode
      isSplitPayment
      isVatEvent
    }
  }
  `
  );

  const queries = createSingleQuery.join('');

  if (!queries) return {};

  const query = `query GetAllSponsors { ${queries} }`;

  const { data } = await aws.API.graphql({ query });
  return data;
};

export const getPurchases = async ({ purchases }) => {
  const createSingleQuery = purchases.map(({ id }) => {
    if (!id) return '';

    return `
  alias_${id.replace(/-/g, '_')}: getPurchase(id: "${id}") {
    id
    purchaseId
    orderCode
    purchaseSponsorId
    date
    payment {
      id
      amount
      paymentStatus
      paymentId
      paymentType
      createdAt
      BillingInformation {
        id
        invoiceTo
        name
        surname
        address
        city
        postalCode
        region
        province
        country
        countryIsoCode
        vatCode
        taxCode
        reference
        email
        pec
        phone
        invoiceToPublicAuthority
        uniqueCode
        ipaCode
        isSplitPayment
        isVatEvent
      }
    }
  }
    `;
  });

  const queries = createSingleQuery.join('');

  if (!queries) return {};

  const query = `query GetAllPurchases { ${queries} }`;

  const { data } = await aws.API.graphql({ query });
  return data;
};
