export const getUsersByMail = /* GraphQL */ `
  query GetUsersByMail($email: String) {
    listUsers(filter: { email: { eq: $email } }) {
      items {
        birthDate
        businessName
        familyName
        fiscalCode
        email
        givenName
        vatNumber
        language
        id
      }
    }
  }
`;

export const getParticipationsByMail = /* GraphQL */ `
  query GetParticipationsByMail($email: String!) {
    listParticipations(
      filter: { and: { isDeleted: { ne: true }, email: { eq: $email } } }
    ) {
      items {
        id
        cluster
        email
        cognitoUserId
        businessName
        familyName
        email
        givenName
      }
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

export const getUsersByCognitoUserId = /* GraphQL */ `
  query GetUsersByCognitoUserId($cognitoUserId: ID!) {
    usersByCognitoId(cognitoUserId: $cognitoUserId) {
      nextToken
      items {
        id
        cognitoUserId
        email
        givenName
        familyName
        businessName
        type
        status
        nationality
        language
        birthDate
      }
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoUserId
      email
      givenName
      familyName
      businessName
      type
      status
      nationality
      language
      birthDate
      fiscalCode
      vatNumber
      logs {
        items {
          id
          createdAt
          ip
          event
          data
          userId
          updatedAt
        }
        nextToken
      }
      zendeskUserId
      createdAt
      updatedAt
      participations {
        items {
          id
          owner
          cluster
          paymentStatus
          registrationChannel
          isInvited
          status
          participationEventId
          registrationFee
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getGroupInvites = `query GetGroupInvites($id: ID!) {
  getGroup(id: $id) {
    invitations {
      items {
        id
        user {
          familyName
          givenName
          email
        }
        cluster
        createdAt
      }
    }
  }
}`;

export const getInvite = /* GraphQL */ `
  query GetInvite($id: ID!) {
    getInvite(id: $id) {
      id
      cluster
      givenName
      familyName
      name
      businessName
      email
      isMailSent
      createdAt
      updatedAt
      user {
        id
        email
        givenName
        familyName
        businessName
        type
        status
        nationality
        language
        birthDate
        fiscalCode
        vatNumber
        logs {
          nextToken
        }
        participations {
          nextToken
        }
        invitations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
    }
  }
`;

export const getEventInvitesByCluster = `query GetEventInvites($id: ID!, $cluster: String) {
    getEvent(id: $id) {
      invitations(filter: {cluster: {eq: $cluster}}) {
        items {
          cluster
          createdAt
          id
          group {
            createdAt
            id
          }
          isMailSent
          name
          updatedAt
          user {
            birthDate
            businessName
            familyName
            fiscalCode
            email
            givenName
            vatNumber
            language
            id
            address{
              items {
                city
                civicNumber
                country
                postalCode
                province
                region
                street
              }
            }
          }
        }
      }
    }
}`;

export const listAllEventParticipations = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      participations(filter: { isDeleted: { ne: true } }) {
        items {
          id
          givenName
          familyName
          businessName
          conflictRequest {
            items {
              participationConflict {
                requestStatus
                id
              }
            }
          }
          userShowcase {
            id
            address
            birthDate
            contactEmail
            coverImage {
              id
              extension
              originalName
              size
            }
            profileImage {
              id
              extension
              originalName
              size
            }
            nationality
            phone
            workJob
            companyName
            businessPhone
            description
            instagram
            facebook
            twitter
            linkedin
          }
        }
      }
    }
  }
`;

export const listEventParticipations = /* GraphQL */ `
  query GetEvent($id: ID!, $cluster: String!) {
    getEvent(id: $id) {
      participations(filter: { cluster: { eq: $cluster } }) {
        items {
          id
          user {
            id
            businessName
            email
            familyName
            givenName
          }
        }
      }
    }
  }
`;

export const listEvents = /* GraphQL */ `
  query ListEvents($filter: ModelEventFilterInput) {
    listEvents(filter: $filter) {
      items {
        id
        name
        organizer
        start
        end
        venue
        country
        city
        initials
        invoicingInitials
        order
        language {
          id
          name
          createdAt
          updatedAt
        }
        participations {
          nextToken
        }
        isDeleted
        abstractService {
          id
          instructions
          tags
          abstractTerm
          firstReminder
          secondReminder
          reviewTerm
          createdAt
          updatedAt
        }
        webcasts {
          nextToken
        }
        surveys {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getParticipation = /* GraphQL */ `
  query GetParticipation($id: ID!) {
    getParticipation(id: $id) {
      id
      user {
        id
        email
        givenName
        familyName
        businessName
        birthDate
      }
    }
  }
`;
