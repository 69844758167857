import appState from './appState';
import aws from './aws';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

export const init = (API, { queries, mutations }, loader) => {
  const logError = (tag, noLoader) => (e) => {
    console.log(`ERROR> [${tag}]`, e);
    noLoader || loader(false);
  };

  const mutate = async (mutationName, input, tag, noLoader) => {
    noLoader || loader(true);
    const { data } = await API.graphql({
      query: mutations[mutationName],
      variables: { input },
    }).catch(logError(tag, noLoader));

    noLoader || loader(false);
    return data[mutationName];
  };

  const query = async (queryName, id, tag, noLoader) => {
    noLoader || loader(true);
    const { data } = await API.graphql({
      query: queries[queryName],
      variables: { id },
    }).catch(logError(tag, noLoader));

    loader(false);
    return data[queryName];
  };

  const list = async (queryName, filter, tag, noLoader) => {
    noLoader || loader(true);
    const { data } = await API.graphql({
      query: queries[queryName],
      variables: { filter },
    }).catch(logError(tag, noLoader));

    noLoader || loader(false);
    return data[queryName];
  };

  return {
    mutate,
    query,
    list,
  };
};

const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      code
      eventOrder {
        items {
          id
          codeName
          code
        }
      }
      start
      end
      name
      city
      type
      timezone
      availableServices
      configuration {
        id
        font
        primaryColor
        secondaryColor
        primaryButtonVariant
        primaryButtonBorderRadius
        secondaryButtonVariant
        secondaryButtonBorderRadius
        background
      }
      language {
        id
        name
      }
      abstractService {
        id
      }
      isOwnedByCongress
      abstractService {
        id
      }
      ownersIds
      ownersIdsReadOnly
      mailupConfig {
        listId
        templateId
        siteDomain
      }
    }
  }
`;

const createEventSequencesMutation = /* GraphQL */ `
  mutation CreateEventSequences(
    $input: CreateEventSequencesInput!
    $condition: ModelEventSequencesConditionInput
  ) {
    createEventSequences(input: $input, condition: $condition) {
      id
      participationSequence
      abstractSequence
      billingCustomerSequence
    }
  }
`;

const incrementEventSequencesMutation = (field) => /* GraphQL */ `
  mutation IncrementEventSequences($input: IncrementEventSequencesInput!) {
    incrementEventSequences(input: $input) {
      id
      ${field}
    }
  }
`;

const getEventSequencesQuery = /* GraphQL */ `
  query GetEventSequences($id: ID!) {
    getEventSequences(id: $id) {
      id
    }
  }
`;

export const getEventInfo = (id) =>
  new Promise((resolve, reject) => {
    showLoader();
    const { API } = aws;
    API.graphql({ query: getEvent, variables: { id } })
      .then(async (response) => {
        const event = response?.data?.getEvent;
        if (response?.data?.getEvent?.language?.name === 'English') {
          event.language = 'en';
        } else if (response?.data?.getEvent?.language?.name === 'Italian') {
          event.language = 'it';
        }
        resolve(event);
      })
      .catch((e) => {
        console.error('get-event-info', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getOrCreateEventSequences = async (code) => {
  try {
    const sequenceRes = await aws.API.graphql({
      query: getEventSequencesQuery,
      variables: {
        id: code,
      }, // FOR THE FUTURE add here new sequences
      authMode: 'API_KEY',
    });
    if (!sequenceRes?.data?.getEventSequences) {
      let { data } = await aws.API.graphql({
        query: createEventSequencesMutation,
        variables: {
          input: {
            //id MUST be equal of event CODE
            id: code,
            participationSequence: 0,
            abstractSequence: 0,
            billingCustomerSequence: 0,
          },
        }, // FOR THE FUTURE add here new sequences
        authMode: 'API_KEY',
      });
      return data.createEventSequences;
    } else {
      return sequenceRes.data.getEventSequences;
    }
  } catch (e) {
    console.error('create-event-sequences', e);
  }
};
export const createEventSequences = (id) =>
  new Promise((resolve, reject) => {
    const { API } = aws;
    API.graphql({
      query: createEventSequencesMutation,
      variables: {
        input: {
          //id MUST be equal of event id
          id,
          participationSequence: 0,
          abstractSequence: 0,
          billingCustomerSequence: 0,
        },
      }, // FOR THE FUTURE add here new sequences
      authMode: 'API_KEY',
    })
      .then(async ({ data }) => {
        resolve(data.createEventSequences);
      })
      .catch((e) => {
        console.error('create-event-sequences', e);
        reject();
      });
  });

export const incrementEventSequences = (input, sequenceName) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: incrementEventSequencesMutation(sequenceName),
      variables: { input },
      authMode: 'API_KEY',
      skipAppSyncError: true,
    })
      .then(async (response) => {
        resolve(response.data.incrementEventSequences);
      })
      .catch(async (e) => {
        if (
          e?.errors?.length &&
          e.errors[0].errorType === 'DynamoDB:ConditionalCheckFailedException'
        ) {
          await createEventSequences(input.id);
          const res = await aws.API.graphql({
            query: incrementEventSequencesMutation(sequenceName),
            variables: { input },
            authMode: 'API_KEY',
          });
          resolve(res.data.incrementEventSequences);
        } else {
          console.error('increment-event-sequences', e);
          reject();
        }
      })
      .finally(hideLoader);
  });
